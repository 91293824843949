import React, { memo } from 'react';
import { createProductLinkCallback } from '../../../../utility/ml-utils';
function ProductDescription({
  pdpUrl,
  searchResult,
  catgroupId,
  details,
  name,
  shouldLink = true,
  storeIdentifierName,
  color = '',
}) {
  let createLinkCallback;
  let link;
  if (shouldLink) {
    createLinkCallback = createProductLinkCallback(
      pdpUrl,
      searchResult,
      catgroupId,
      details,
      storeIdentifierName,
    );
    link = pdpUrl;
  }

  const description = color ? `${name} color ${color}` : name;

  return (
    <a
      className="rs_product_description d-block"
      onClick={createLinkCallback}
      href={link}
      tabIndex={0}
      title={description}
      aria-label={description}>
      {name}
    </a>
  );
}

export default memo(ProductDescription);
