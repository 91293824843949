import axios from 'axios';
import { batch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import { updateRequestAndHeadersWithAudienceDetails } from '../../../utility/audience-utils';
import { envData } from '../../../utility/environments';
import { boplEnabledFlag } from '../../../utility/feature-flags';
import { updateSearchTerm } from '../../../utility/history';
import { getPrefix, SEARCH_URL } from '../../../utility/url-utils';
import {
  getParameterByName,
  getStoreIdentifierNameFromVh,
  primitiveDeepCopy,
} from '../../../utility/utils';
import { resultsViewUpdate } from '../../plp-srlp-analytics';
import {
  setItemAvailability,
  updateCommon,
  setAPIResponseSuccess,
  setErrorResponse,
} from '../../slices/common';
import { updateInput } from '../../slices/input';
import { updateProductApiData } from '../../slices/productApiData';

let SEARCH_API = '';

export function callAPI(
  bodyString,
  dispatch,
  stateData,
  callAnalytics = false,
  refinementEventData = false,
) {
  const productApiHeaders = {};
  productApiHeaders['Content-Type'] = 'application/json';
  productApiHeaders.preview = stateData.input.preview;

  const storeIdentifier = getStoreIdentifierNameFromVh(stateData.common.dsgVh);

  if (stateData && stateData.common.dsgVh && storeIdentifier === 'calia') {
    productApiHeaders.channel = 'calia';
  } else if (stateData && stateData.common.dsgVh && storeIdentifier === 'vrst') {
    productApiHeaders.channel = 'vrst';
  } else if (stateData && stateData.common.dsgVh && storeIdentifier === 'g3') {
    productApiHeaders.channel = 'g3';
  }

  SEARCH_API = getPrefix(storeIdentifier, SEARCH_HOST) + SEARCH_URL;

  const channelVal = getParameterByName('channel');
  const newBodyString = bodyString;

  if (channelVal) {
    newBodyString.channel = channelVal;
  }

  const request = primitiveDeepCopy(newBodyString);

  // Validate that storeNumber is truthy and is a number
  if (request.selectedStore && !/^\d+$/.test(request.selectedStore)) {
    if (request.selectedStore && request.selectedStore.location) {
      request.selectedStore = request.selectedStore.location;
    } else {
      request.selectedStore = '0';
    }
  }

  updateRequestAndHeadersWithAudienceDetails(
    request,
    productApiHeaders,
    stateData.input.snbAudience,
    stateData.common.audiencePools,
    storeIdentifier,
  );

  dispatch(updateInput({ categoryData: { searchVO: request } }));

  return axios(SEARCH_API + '?searchVO=' + encodeURIComponent(JSON.stringify(request)), {
    method: 'GET',
    headers: productApiHeaders,
  })
    .then((response) => {
      const responseData = response.data;

      responseData.searchVO.selectedStore = responseData.searchVO.selectedStore
        ? responseData.searchVO.selectedStore
        : '0';
      // dispatch(setErrorResponse(false));
      if (callAnalytics) {
        responseData.setSearchAnalytics = true;
      }

      if (responseData.didYouMeanVO) {
        // Got a machine learning didYouMeanVO response update input searchTerm to suggested value
        if (responseData.didYouMeanVO.suggestions && responseData.didYouMeanVO.suggestions[0]) {
          // Suggested value is available update searchVO searchTerm to suggested value for subsequent API calls
          responseData.searchVO.searchTerm = responseData.didYouMeanVO.suggestions[0];
          updateSearchTerm(responseData.searchVO.searchTerm);
        }
      }

      if (refinementEventData) {
        // If refinementEventData is passed send the refinementEvent with that data and add ProductCount to the object
        resultsViewUpdate({
          ...refinementEventData,
          UpdateValue: {
            ...refinementEventData.UpdateValue,
            ProductCountTotal: responseData.searchVO.totalCount,
          },
        });
      }

      const mlSearchUUID = uuidv4();

      batch(() => {
        dispatch(setAPIResponseSuccess({ responseData: responseData, mlSearchUUID }));
        dispatch(updateProductApiData(responseData));
        dispatch(updateCommon({ responseData: responseData }));
        dispatch(updateInput({ categoryData: responseData }));
      });

      if (typeof MLDataCollection !== 'undefined') {
        MLDataCollection.recordSearchResponse(
          storeIdentifier.toUpperCase(),
          responseData,
          responseData.took,
          stateData.common.catgroupId,
          mlSearchUUID,
        );
      }
    })
    .catch((error) => {
      console.log(`request failed ${bodyString} with error...${error.message}`);
      dispatch(setErrorResponse(true));
    });
}

export function callSearchAPI(bodyString, dispatch, stateData) {
  callAPI(bodyString, dispatch, stateData, true);
}

export function setDefaultFilters(input) {
  return input.selectedFilters;
}

export function getItemAvailabilityData(dispatch, getState, myStoreAddress) {
  // Being used in audience-utils to handle the A/B test of item availability. audience-utils creates the thunk action and wraps this function
  const { productApiData, input, common } = getState();
  const { storeIdentifierName } = common;
  const bopl = storeIdentifierName === 'dsg' && boplEnabledFlag;

  // Get all ecodes without duplicates
  const productEcodes = [
    ...new Set(
      productApiData.productVOs.map((item) => {
        return item.parentPartnumber;
      }),
    ),
  ];

  // Dynamically generate request URL to Product API /v1/inventory/messages endpoint
  if (productEcodes && productEcodes.length > 0) {
    const inventoryRequest = `${getPrefix(storeIdentifierName, SEARCH_HOST)}v1/inventory/messages`;
    let params = {
      ecodeList: productEcodes.join(),
      ...(input.selectedStore && { selectedStore: input.selectedStore }),
      ...(myStoreAddress && { zipCode: myStoreAddress }),
      ...(bopl && { bopl: true }),
    };

    return axios
      .get(inventoryRequest, { params, timeout: 5000 })
      .then((response) => {
        if (response && response.data && response.data.ecodeMessagingMap) {
          dispatch(setItemAvailability(response.data.ecodeMessagingMap));
        } else {
          dispatch(setItemAvailability(null));
        }
      })
      .catch((e) => {
        console.error(`Item Availability request failure: ${e.message}`, e);
        dispatch(setItemAvailability(null));
        return false;
      });
  }
  dispatch(setItemAvailability(null));
  return false;
}
