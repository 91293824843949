import React, { memo } from 'react';

const pricingTextByStore = {
  all: {
    wasPriceBefore: 'WAS: ',
    wasPriceAfter: '*',
    unlistedPrice: 'See Price In Cart',
  },
  g3: {
    wasPriceBefore: 'Comparable Value ',
    wasPriceAfter: '',
    unlistedPrice: 'SEE OUR PRICE IN CART',
  },
};

const ProductPrice = ({ productDetails, catentryId, storeIdentifierName }) => {
  const pricingText = storeIdentifierName !== 'g3' ? pricingTextByStore.all : pricingTextByStore.g3;

  const renderListPrice = (minListPrice, maxListPrice) => {
    if (!minListPrice || !maxListPrice) return null;
    return (
      <p className="was-price hmf-body-s">
        {storeIdentifierName === 'dsg' ? '' : pricingText.wasPriceBefore}
        {`$${minListPrice}`}
        {maxListPrice !== minListPrice
          ? ` - $${maxListPrice}${pricingText.wasPriceAfter}`
          : pricingText.wasPriceAfter}
      </p>
    );
  };

  const renderOfferPrice = (minOfferPrice, maxOfferPrice, isClearance) => {
    if (!minOfferPrice || !maxOfferPrice) return null;
    return (
      <p className={isClearance ? 'offer-price' : 'final-price'} tabIndex={0}>
        {`$${minOfferPrice}`}
        {maxOfferPrice !== minOfferPrice ? ` - $${maxOfferPrice}` : ''}
      </p>
    );
  };

  const renderSeeInCart = (minListPrice, maxListPrice) => {
    return (
      <>
        <p className="unlisted-price">{pricingText.unlistedPrice}</p>
        {renderListPrice(minListPrice, maxListPrice)}
      </>
    );
  };

  const renderPrice = (minOfferPrice, minListPrice, maxOfferPrice, maxListPrice, isClearance) => {
    return (
      <>
        {renderOfferPrice(minOfferPrice, maxOfferPrice, isClearance)}
        {isClearance && renderListPrice(minListPrice, maxListPrice)}
      </>
    );
  };

  // map price indicator is 1 "see price in cart"
  // price indicator is 0 was price
  // offer price (min and max)
  // list price (was price) or not on sale price (min and max)
  if (!productDetails) {
    return null;
  }
  const { prices, mapPriceIndicator, priceIndicator } = productDetails;
  if (!prices) {
    return null;
  }
  const { maxlistprice, minlistprice, maxofferprice, minofferprice } = prices;

  const seePriceInCart = mapPriceIndicator === 1;
  const isClearance = priceIndicator === 0;

  return (
    <div
      className="rs_product_price"
      key={`PrdPrice_${catentryId}`}
      id={`product_price_${catentryId}`}>
      {seePriceInCart
        ? renderSeeInCart(minlistprice, maxlistprice)
        : renderPrice(minofferprice, minlistprice, maxofferprice, maxlistprice, isClearance)}
    </div>
  );
};

export default memo(ProductPrice);
