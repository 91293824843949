import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../types';

export const priceFilterZeroSelector = (state: ReduxState) =>
  state?.productApiData?.priceFilterZero ? state.productApiData.priceFilterZero : false;
export const myStoreZeroSelector = (state: ReduxState) =>
  state.productApiData.myStoreZero ? state.productApiData.myStoreZero : false;
export const facetVOsSelector = (state: ReduxState) =>
  state.productApiData.facetVOs ? state.productApiData.facetVOs : [];
export const categoryDetailsSelector = (state: ReduxState) => state.productApiData.categoryDetails;
export const conversationFacetVOSSelector = (state: ReduxState) =>
  state.productApiData.conversationFacetVOS ? state.productApiData.conversationFacetVOS : [];
export const productDetailsSelector = (state: ReduxState) =>
  state.productApiData.productDetails ? state.productApiData.productDetails : {};
export const productVOsSelector = (state: ReduxState) =>
  state.productApiData.productVOs ? state.productApiData.productVOs : [];
export const didYouMeanVOSelector = (state: ReduxState) =>
  state.productApiData.didYouMeanVO ? state.productApiData.didYouMeanVO : {};
export const fuzzyMatchSelector = (state: ReduxState) => state.productApiData.fuzzyMatch;
export const mlSpellingSelector = (state: ReduxState) => state.productApiData.mlSpelling;
export const top10Selector = (state: ReduxState) => state.productApiData.top10;
export const searchResultSelector = (state: ReduxState) =>
  state.productApiData ? state.productApiData : {};
export const mlImpressionEcodesSelector = (state: ReduxState) =>
  state.productApiData.mlImpressionEcodes;
export const mlImpressionProductsByRowSelector = (state: ReduxState) =>
  state.productApiData.mlImpressionProductsByRow;
