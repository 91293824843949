import React from 'react';
import MultiSelectFacet from './Facets/MultiSelectFacet';
import SingleSelectFacet from './Facets/SingleSelectFacet';
import BrandSelectFacet from './Facets/BrandSelectFacet';
import SizeSelectFacet from './Facets/SizeSelectFacet';
import PriceSelectFacet from './Facets/PriceSelectFacet';
import Button from '../../common/buttons/Button';
import SettingsIcon from '../../common/SVGIcons/SettingsIcon';
import CloseIcon from '../../common/SVGIcons/CloseIcon';
import MobileProductCount from '../../common/MobileProductCount/MobileProductCount';
import ToggleSwitchSingleSelectFacet from './Facets/ToggleSwitchSingleSelectFacet';
import LinkSingleSelectFacet from './Facets/LinkSingleSelectFacet';
import { isSizeAvalable } from './utility/facet-section-utils';
import { useDispatch, useSelector } from 'react-redux';
import {
  breakpointSelector,
  categoryAttrVoDataSelector,
  categoryDetailsSelector,
  facetVOsSelector,
  isFamilyPageSelector,
  priceFilterZeroSelector,
  selectedFiltersSelector,
  showMobileFilterFacetModalSelector,
  snbAudienceSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import {
  applyFilter,
  clearAllFilterData,
  handleMobileFilterFacetModalClick,
} from '../../../reduxStore/thunks';
import StoreSelectFacet from '../../FeatureTesting/StoreSelectFacet/StoreSelectFacet';
import FilterHeader from '../../FeatureTesting/FilterHeader/FilterHeader';
import StoreSelect from '../../FeatureTesting/StoreSelect/StoreSelect';
import SelectedFiltersChips from '../../common/SelectedFilterChips/SelectedFilterChips';
// import RatingSelectFacet from './rating-select';

// FacetSection handles looping over and rendering the list of facets by their type

const FacetSection = () => {
  const dispatch = useDispatch();

  const priceFilterZero = useSelector(priceFilterZeroSelector);
  const facetVOs = useSelector(facetVOsSelector);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const categoryDetails = useSelector(categoryDetailsSelector);
  const breakpoint = useSelector(breakpointSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const categoryAttrVoData = useSelector(categoryAttrVoDataSelector);
  const selectedFilters = useSelector(selectedFiltersSelector);
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const snbAudience = useSelector(snbAudienceSelector);

  const isV3Template =
    categoryAttrVoData &&
    categoryAttrVoData.LAYOUT_TYPE &&
    categoryAttrVoData.LAYOUT_TYPE.value === '8';
  const numFacetsExpanded = isV3Template ? 0 : 3; // Sets the number of facets expanded to 1 when it's PLP V3 which should only have categories expanded

  const onFilterData = (identifier: string, values: string[], facetType: string) => {
    dispatch(applyFilter(identifier, values, facetType));
  };

  /** get selected data of particular facet based on identifier if exists */
  const getSelectedFilterData = (facetIdentifier: string) => {
    const selectObj = selectedFilters;
    if (selectObj[facetIdentifier]) {
      return selectObj[facetIdentifier];
    }
    return [];
  };

  const createFacets = () => {
    if (!priceFilterZero) {
      return facetVOs.map((facetVo, idx) => {
        let openFacet = true;
        if (idx > numFacetsExpanded) {
          openFacet = false;
        }

        if (facetVo.attrIdentifier !== 'facetStore') {
          /** facetSelection: 0 -> single select, 1 -> multi select */
          const selectedData = getSelectedFilterData(facetVo.attrIdentifier);
          if (facetVo.facetSelection === 0) {
            if (facetVo.attrName === 'Price') {
              return (
                <PriceSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`price_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  storeName={storeIdentifierName}
                />
              );
            } else if (facetVo.attrName === 'Customer Rating') {
              // Removing  facet customer rating from filter
              // return (
              //   <RatingSelectFacet
              //     facet={facetVo}
              //     selectedData={selectedData}
              //     key={`rating_facet_${facetVo.attrIdentifier}`}
              //     onFilterData={onFilterData}
              //     openFacet={openFacet}
              //   />,
              // );
            } else if (facetVo.attrName === 'Sale') {
              return (
                <ToggleSwitchSingleSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`toggle_switch_single_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  categoryDetails={categoryDetails}
                  storeName={storeIdentifierName}
                />
              );
            } else if (
              facetVo.attrIdentifier === 'facetCatgroup' &&
              (isV3Template || !isFamilyPage)
            ) {
              return (
                <LinkSingleSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`link_single_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  categoryDetails={categoryDetails}
                  storeName={storeIdentifierName}
                  isFamilyPage={isFamilyPage}
                  isV3Template={isV3Template}
                />
              );
            } else if (facetVo.attrIdentifier !== 'facetCatgroup') {
              return (
                <SingleSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`checkbox_single_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  categoryDetails={categoryDetails}
                  breakpoint={breakpoint}
                  storeName={storeIdentifierName}
                />
              );
            }
          } else if (facetVo.facetSelection === 1) {
            if (facetVo.attrIdentifier === 'X_BRAND') {
              /** brand having different layout */
              return (
                <BrandSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`brand_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  breakpoint={breakpoint}
                  storeName={storeIdentifierName}
                />
              );
            } else if (isSizeAvalable(facetVo.attrIdentifier)) {
              // else if (facetVo.attrIdentifier === '299' || facetVo.attrIdentifier === '5434') {
              /** size having different layout */
              return (
                <SizeSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`brand_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  breakpoint={breakpoint}
                  storeName={storeIdentifierName}
                />
              );
            } else if (facetVo.attrIdentifier !== 'facetCatgroup') {
              return (
                <MultiSelectFacet
                  breakpoint={breakpoint}
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`multi_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  storeName={storeIdentifierName}
                />
              );
            } else if (faceVo.attrIdentifier === 'Price') {
              return (
                <PriceSelectFacet
                  facet={facetVo}
                  selectedData={selectedData}
                  key={`price_facet_${facetVo.attrIdentifier}`}
                  onFilterData={onFilterData}
                  openFacet={openFacet}
                  storeName={storeIdentifierName}
                />
              );
            }
          }
        }
      });
    }
  };

  let filterIcon;
  switch (storeIdentifierName) {
    case 'calia':
      filterIcon = <SettingsIcon pathClasses={['facet-heading-filters-icon']} />;
      break;
    case 'pl':
    case 'mj':  
      filterIcon = null;
      break;
    default:
      filterIcon = <SettingsIcon pathClasses={['facet-heading-filters-icon']} />;
      break;
  }

  return (
    <div
      className={`rs-facet-section-container ${
        showMobileFilterFacetModal ? 'showMobileFilterFacetModal' : 'hideMobileFilterFacetModal'
      }`}
      key="key_facet_seaction_container">
      <div className="facet-list-section" role="tablist">
        {(!snbAudience || snbAudience !== 'improvedProductGrid') && (
          <>
            <div className="facet-header">
              {filterIcon}
              <span>Filter</span>
              <button
                type="button"
                className="facet-heading-close-btn"
                onClick={() => {
                  dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
                }}>
                <CloseIcon pathClasses={['facet-heading-close-icon']} />
              </button>
            </div>
            <div className="dsg-flex facet-mobile-selected-filter-chips">
              {breakpoint && (breakpoint === 'mobile' || breakpoint === 'tablet') && (
                <SelectedFiltersChips showClearAllLink={true} />
              )}
            </div>
          </>
        )}

        {snbAudience && snbAudience === 'improvedProductGrid' && (
          <>
            <FilterHeader />
            <StoreSelect
              options={{
                attrIdentifier: 'facetStore',
                attrName: 'Shipping & Pickup',
                valueList: [
                  { count: null, value: 'SHIP' },
                  { count: null, value: 'BOPIS' },
                  { count: null, value: 'ISA' },
                ],
              }}
              selectedData={selectedFilters.facetStore}
              key={`store_select_facet_facetStore`}
              onFilterData={onFilterData}
              breakpoint={breakpoint}
              storeName={storeIdentifierName}
            />
          </>
        )}
        {createFacets()}
      </div>
      <div className="facet-footer-buttons">
        <Button
          label="Clear All"
          variant="secondary"
          classes={['facet-footer-clear-all-btn']}
          onClick={() => {
            dispatch(clearAllFilterData(false));
          }}
        />
        <Button
          label="Apply Filters"
          classes={['facet-footer-view-results-btn']}
          disabled={false}
          onClick={() => {
            dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
          }}
        />
      </div>
      <MobileProductCount />
    </div>
  );
};

export default FacetSection;

FacetSection.defaultProps = {
  facetVOs: [],
  priceFilterZero: false,
};
