import { getCookie, deleteCookie } from '../utility/cookie-utils';
import { mapSortValToText } from '../utility/utils';

let firstPageRender = true;

function getCategoryPageType(categoryAttrVoData) {
  if (categoryAttrVoData) {
    if (categoryAttrVoData.HYPERCATRULE) {
      return 'Hyper-Category';
    }
    if (categoryAttrVoData.LAYOUT_TYPE && categoryAttrVoData.LAYOUT_TYPE.value === '2') {
      return 'SuperFamily';
    }
  }
  return 'Family';
}

function setCategoryAnalyticsDataFromStore(store, tempSiteData) {
  if (store && store.getState()) {
    const storeData = store.getState();
    tempSiteData.Identifier = storeData.common.categoryIdentifier;
    tempSiteData.PageType = getCategoryPageType(storeData.common.categoryAttrVoData);
    tempSiteData.PrimaryL1 = storeData.common.primaryL1;
  }
}

function pagePostRenderEvent() {
  if (firstPageRender && typeof document !== 'undefined') {
    firstPageRender = false;
    const evt = new CustomEvent('reporting:PagePostRender', { detail: window.siteData });
    document.dispatchEvent(evt);
    deleteCookie('originSayT');
    deleteCookie('searchRefinement');
  }
}

const tempSiteData = {
  DYMTerm: '',
  DYMType: '',
  EnteredSearchTerm: '',
  ErrorID: [],
  ErrorMessage: [],
  LoggedIn: undefined,
  LoyaltyID: '',
  ProductCountTotal: 0,
  ProductPerPage: '',
  ProductsInFamily: '',
  ReplacementTerm: '',
  ReplacementType: '',
  SearchResultCount: '',
  SearchType: 'Keyword',
  SearchData: [],
  FuzzyMatch: '',
  SortMethod: 'Most Relevant',
  UserID: '',
  Identifier: '',
  PageType: '',
  PrimaryL1: '',
  ConversationalFilters: [],
  HasConversationalFilters: 'N',
};
let searchVOData;
let didYouMeanVOData;
const handleAnalyticCall = function handleAnalyticCall(type, action, store) {
  switch (type) {
    case 'common/createAnalyticData':
      if (typeof window !== 'undefined' && action.productApiData) {
        tempSiteData.FuzzyMatch = action.productApiData.fuzzyMatch;
        if (action.input) {
          searchVOData = action.input;

          // Accurate total product count shown to the user
          tempSiteData.ProductCountTotal = searchVOData.totalCount;

          if (searchVOData.searchTypes) {
            tempSiteData.SearchData = searchVOData.searchTypes;
          }

          if (searchVOData.totalCount == 0) {
            tempSiteData.SearchResultCount = `${searchVOData.totalCount}`;
            tempSiteData.EnteredSearchTerm = searchVOData.searchTerm ? searchVOData.searchTerm : '';
            tempSiteData.SearchType = '';
            tempSiteData.FuzzyMatch = '';
            tempSiteData.SortMethod = '';
          } else {
            if (!searchVOData.isFamilyPage) {
              tempSiteData.SearchResultCount = searchVOData.totalCount;
              tempSiteData.EnteredSearchTerm = searchVOData.searchTerm
                ? searchVOData.searchTerm
                : '';
            } else {
              setCategoryAnalyticsDataFromStore(store, tempSiteData);
            }
            tempSiteData.ProductPerPage = searchVOData.pageSize;
            tempSiteData.SortMethod = mapSortValToText(`${searchVOData.selectedSort}`);
            tempSiteData.ProductsInFamily = searchVOData.totalCount;

            if (action.productApiData.didYouMeanVO) {
              didYouMeanVOData = action.productApiData.didYouMeanVO;
              tempSiteData.DYMType = 'DYMAuto';
              tempSiteData.DYMTerm = didYouMeanVOData.suggestions[0];
              tempSiteData.EnteredSearchTerm = didYouMeanVOData.originalSearchTerm;
            }
            if (getCookie('dymType')) {
              tempSiteData.DYMType = getCookie('dymType');
              tempSiteData.EnteredSearchTerm = getCookie('dymEnteredSearchTerm');
              tempSiteData.DYMTerm = action.input.searchTerm;
            }
            if (
              getCookie('searchRefinement') &&
              getCookie('searchRefinement').indexOf('Recent') > -1
            ) {
              tempSiteData.SearchType = 'Suggested History';
            } else if (getCookie('searchRefinement') === 'brands') {
              tempSiteData.SearchType = 'Suggested Brand';
            } else if (getCookie('searchRefinement') === 'enterSearchTerm') {
              tempSiteData.SearchType = 'Keyword';
            } else if (getCookie('searchRefinement') === 'searchTerm') {
              tempSiteData.SearchType = 'Suggested Keyword';
            } else if (getCookie('searchRefinement') === 'recentlySearchedTerm') {
              tempSiteData.SearchType = 'Suggested Recently Searched';
            }

            if (searchVOData && searchVOData.isFamilyPage) {
              if (getCookie('searchRefinement') === 'categories') {
                tempSiteData.SearchType = 'Suggested Category';
              } else {
                tempSiteData.SearchType = '';
              }
              tempSiteData.FamilyID = `${action.common.catgroupId}`;
              tempSiteData.MCSalesCatalogDetails = [];
              const { categoryDetails } = action.productApiData;
              if (
                action.common.familyPageName ||
                (categoryDetails &&
                  categoryDetails[`${window.catalogId}_${action.common.catgroupId}`])
              ) {
                tempSiteData.FamilyName = action.common.familyPageName
                  ? action.common.familyPageName
                  : categoryDetails[`${window.catalogId}_${action.common.catgroupId}`].name;
              }

              const parentCodeObject = {};
              if (action.common.categoryIdentifier) {
                parentCodeObject.code = action.common.categoryIdentifier;
              }
              parentCodeObject.ParentCodes = { Parentcode: '', ParentPathUsed: 'N' };
              tempSiteData.MCSalesCatalogDetails.push(parentCodeObject);
              if (getCookie('originSayT')) {
                tempSiteData.EnteredSearchTerm = action.common.familyPageName;
              }
            }
          }
        }
        if (typeof window !== 'undefined') {
          if (typeof window.siteData !== 'undefined') {
            const newSiteData = Object.assign(window.siteData, tempSiteData);
            window.siteData = newSiteData;
          } else {
            window.siteData = tempSiteData;
          }
        }
      }
      break;
    case 'common/initializeSession':
      tempSiteData.FuzzyMatch = action.searchData.fuzzyMatch;
      if (typeof window !== 'undefined' && action.searchData.searchVO) {
        searchVOData = action.searchData.searchVO;

        // Accurate total product count shown to the user
        tempSiteData.ProductCountTotal = searchVOData.totalCount;

        if (searchVOData.searchTypes) {
          tempSiteData.SearchData = searchVOData.searchTypes;
        }

        if (searchVOData.totalCount === 0) {
          tempSiteData.SearchResultCount = `${searchVOData.totalCount}`;
          tempSiteData.EnteredSearchTerm = searchVOData.searchTerm ? searchVOData.searchTerm : '';
          tempSiteData.SearchType = '';
          tempSiteData.FuzzyMatch = '';
          tempSiteData.SortMethod = '';
        } else {
          if (!searchVOData.isFamilyPage) {
            if (searchVOData.totalCount !== 0) {
              tempSiteData.SearchResultCount = searchVOData.totalCount;
              tempSiteData.EnteredSearchTerm = searchVOData.searchTerm
                ? searchVOData.searchTerm
                : '';
            }
          } else {
            setCategoryAnalyticsDataFromStore(store, tempSiteData);
          }
          tempSiteData.ProductPerPage = searchVOData.pageSize;
          tempSiteData.SortMethod = mapSortValToText(`${searchVOData.selectedSort}`);
          tempSiteData.ProductsInFamily = searchVOData.totalCount;

          if (action.didYouMeanVO) {
            didYouMeanVOData = action.searchData.didYouMeanVO;
            tempSiteData.DYMType = 'DYMAuto';
            tempSiteData.DYMTerm = didYouMeanVOData.suggestions[0];
            tempSiteData.EnteredSearchTerm = didYouMeanVOData.originalSearchTerm;
          }
          if (getCookie('dymType')) {
            tempSiteData.DYMType = getCookie('dymType');
            tempSiteData.EnteredSearchTerm = getCookie('dymEnteredSearchTerm');
            tempSiteData.DYMTerm = action.searchData.searchVO.searchTerm;
          }
          if (
            getCookie('searchRefinement') &&
            getCookie('searchRefinement').indexOf('Recent') > -1
          ) {
            tempSiteData.SearchType = 'Suggested History';
          } else if (getCookie('searchRefinement') === 'brands') {
            tempSiteData.SearchType = 'Suggested Brand';
          } else if (getCookie('searchRefinement') === 'enterSearchTerm') {
            tempSiteData.SearchType = 'Keyword';
          } else if (getCookie('searchRefinement') === 'searchTerm') {
            tempSiteData.SearchType = 'Suggested Keyword';
          } else if (getCookie('searchRefinement') === 'recentlySearchedTerm') {
            tempSiteData.SearchType = 'Suggested Recently Searched';
          }

          if (searchVOData && searchVOData.isFamilyPage) {
            if (getCookie('searchRefinement') === 'categories') {
              tempSiteData.SearchType = 'Suggested Category';
            } else {
              tempSiteData.SearchType = '';
            }
            tempSiteData.FamilyID = `${action.catgroupId}`;
            tempSiteData.MCSalesCatalogDetails = [];
            const { categoryDetails } = action.searchData.categoryDetails;
            if (
              action.familyPageName ||
              (categoryDetails && categoryDetails[`${window.catalogId}_${action.catgroupId}`])
            ) {
              tempSiteData.FamilyName =
                familyPageName || categoryDetails[`${window.catalogId}_${action.catgroupId}`].name;
            }

            const parentCodeObject = {};
            if (action.categoryIdentifier) {
              parentCodeObject.code = action.categoryIdentifier;
            }
            parentCodeObject.ParentCodes = {
              Parentcode: '',
              ParentPathUsed: 'N',
            };
            tempSiteData.MCSalesCatalogDetails.push(parentCodeObject);
            if (getCookie('originSayT')) {
              tempSiteData.EnteredSearchTerm = action.familyPageName;
            }
          }
        }
      }
      if (typeof window !== 'undefined') {
        if (getCookie('sTermCookie')) {
          tempSiteData.SearchType = 'Redirect';
          tempSiteData.EnteredSearchTerm = getCookie('sTermCookie');
          document.cookie = 'sTermCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
        if (typeof window.siteData !== 'undefined') {
          const newSiteData = Object.assign(window.siteData, tempSiteData);
          window.siteData = newSiteData;
        } else {
          window.siteData = tempSiteData;
        }
      }

      if (action.searchData && action.searchData.conversationFacetVOS) {
        tempSiteData.ConversationalFilters = action.searchData.conversationFacetVOS;
        tempSiteData.HasConversationalFilters =
          action.searchData.conversationFacetVOS &&
          action.searchData.conversationFacetVOS.length > 0
            ? 'Y'
            : 'N';
      }
      pagePostRenderEvent();
      return;
    case 'common/setAPIResponseSuccess':
      tempSiteData.FuzzyMatch = action.responseData.fuzzyMatch;
      tempSiteData.MLSpelling =
        action.responseData.mlSpelling === true
          ? true
          : action.responseData.mlSpelling === false
          ? false
          : '';
      tempSiteData.MLRewrite =
        action.responseData.mlRewrite === true
          ? true
          : action.responseData.mlRewrite === false
          ? false
          : '';
      tempSiteData.MLRank =
        action.responseData.mlRank === true
          ? true
          : action.responseData.mlRank === false
          ? false
          : '';
      tempSiteData.MLGroup = action.responseData.mlGroup; // string value
      tempSiteData.MLBypass =
        action.responseData.mlBypass === true
          ? true
          : action.responseData.mlBypass === false
          ? false
          : '';
      if (typeof window !== 'undefined' && action.responseData.searchVO) {
        searchVOData = action.responseData.searchVO;

        // Accurate total product count shown to the user
        tempSiteData.ProductCountTotal = searchVOData.totalCount;

        if (searchVOData.searchTypes) {
          tempSiteData.SearchData = searchVOData.searchTypes;
        }

        if (searchVOData.totalCount === 0) {
          tempSiteData.SearchResultCount = `${searchVOData.totalCount}`;
          tempSiteData.EnteredSearchTerm = searchVOData.searchTerm ? searchVOData.searchTerm : '';
          tempSiteData.SearchType = '';
          tempSiteData.FuzzyMatch = '';
          tempSiteData.SortMethod = '';
        } else {
          if (!searchVOData.isFamilyPage) {
            tempSiteData.SearchResultCount = searchVOData.totalCount;
            tempSiteData.EnteredSearchTerm = searchVOData.searchTerm ? searchVOData.searchTerm : '';
          } else {
            setCategoryAnalyticsDataFromStore(store, tempSiteData);
          }
          tempSiteData.ProductPerPage = searchVOData.pageSize;
          tempSiteData.SortMethod = mapSortValToText(`${searchVOData.selectedSort}`);
          tempSiteData.ProductsInFamily = searchVOData.totalCount;

          if (searchVOData.totalCount === 0) {
            tempSiteData.SearchType = '';
            tempSiteData.SortMethod = '';
            tempSiteData.FuzzyMatch = '';
          } else {
            if (action.responseData.didYouMeanVO) {
              didYouMeanVOData = action.responseData.didYouMeanVO;
              tempSiteData.DYMType = 'DYMAuto';
              tempSiteData.DYMTerm = didYouMeanVOData.suggestions[0];
              tempSiteData.EnteredSearchTerm = didYouMeanVOData.originalSearchTerm;
            }
            if (getCookie('dymType')) {
              tempSiteData.DYMType = getCookie('dymType');
              tempSiteData.EnteredSearchTerm = getCookie('dymEnteredSearchTerm');
              tempSiteData.DYMTerm = action.responseData.searchVO.searchTerm;
            }
            if (
              getCookie('searchRefinement') &&
              getCookie('searchRefinement').indexOf('Recent') > -1
            ) {
              tempSiteData.SearchType = 'Suggested History';
            } else if (getCookie('searchRefinement') === 'brands') {
              tempSiteData.SearchType = 'Suggested Brand';
            } else if (getCookie('searchRefinement') === 'enterSearchTerm') {
              tempSiteData.SearchType = 'Keyword';
            } else if (getCookie('searchRefinement') === 'searchTerm') {
              tempSiteData.SearchType = 'Suggested Keyword';
            } else if (getCookie('searchRefinement') === 'recentlySearchedTerm') {
              tempSiteData.SearchType = 'Suggested Recently Searched';
            }
          }
        }
      }

      if (typeof window !== 'undefined') {
        if (
          searchVOData.isFamilyPage &&
          action.responseData.setSearchAnalytics &&
          getCookie('sTermCookie')
        ) {
          tempSiteData.SearchType = 'Redirect';
          tempSiteData.EnteredSearchTerm = getCookie('sTermCookie');
          document.cookie = 'sTermCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
        }
        if (typeof window.siteData !== 'undefined') {
          const newSiteData = Object.assign(window.siteData, tempSiteData);
          window.siteData = newSiteData;
        } else {
          window.siteData = tempSiteData;
        }
      }
      if (!action.responseData.searchVO.landingPageURL && getCookie('sTermCookie')) {
        document.cookie = 'sTermCookie=; Path=/; Expires=Thu, 01 Jan 1970 00:00:01 GMT';
      }
      if (action.responseData && action.responseData.conversationFacetVOS) {
        tempSiteData.ConversationalFilters = action.responseData.conversationFacetVOS;
        tempSiteData.HasConversationalFilters =
          action.responseData.conversationFacetVOS &&
          action.responseData.conversationFacetVOS.length > 0
            ? 'Y'
            : 'N';
      }

      if (
        (action.responseData.mlBypass || action.responseData.setSearchAnalytics) &&
        !action.responseData.searchVO.landingPageURL
      ) {
        pagePostRenderEvent();
      }

      break;
    default:
  }
};
export default handleAnalyticCall;

export function resultsViewUpdate(eventTriggerData) {
  const evt = new CustomEvent('ResultsViewUpdate', { detail: eventTriggerData });
  document.dispatchEvent(evt);
}
