import React from 'react';
import PropTypes from 'prop-types';
import {
  StarFilled0Percent,
  StarFilled25Percent,
  StarFilled50Percent,
  StarFilled75Percent,
  StarFilled100Percent,
} from '@dsg-tech/homefield-react-icons';

const StarsRating = ({ numberOfStars = 0, selectedRatingCount = 0 }) => {
  const createStars = () => {
    const stars = [];
    const starIconsMap = {
      empty: <StarFilled0Percent className="pc-filled-star" />,
      oneQuarter: <StarFilled25Percent className="pc-filled-star" />,
      half: <StarFilled50Percent className="pc-filled-star" />,
      threeQuarter: <StarFilled75Percent className="pc-filled-star" />,
      fill: <StarFilled100Percent className="pc-filled-star" />,
    };

    const starType = (selectedRatingCount, index) => {
      const valueLeft = +(selectedRatingCount - (index - 1)).toFixed(2);

      if (selectedRatingCount / index >= 1) {
        return 'fill';
      } else if (valueLeft >= 0.25 && valueLeft <= 0.49) {
        return 'oneQuarter';
      } else if (valueLeft >= 0.5 && valueLeft <= 0.74) {
        return 'half';
      } else if (valueLeft >= 0.75 && valueLeft <= 0.99) {
        return 'threeQuarter';
      }

      return 'empty';
    };

    if (numberOfStars > 0) {
      for (let i = 1; i < numberOfStars + 1; i++) {
        stars.push(starIconsMap[starType(selectedRatingCount, i)]);
      }
    }
    return stars;
  };

  return <span className="pc-rs-stars-container">{createStars()}</span>;
};

export default StarsRating;

StarsRating.propTypes = {
  numberOfStars: PropTypes.number,
  selectedRatingCount: PropTypes.number,
};

StarsRating.defaultProps = {
  numberOfStars: 5,
  selectedRatingCount: 0,
};
