import React, { Suspense, useEffect, useState } from 'react';
import lazy from 'react-lazy-ssr';

import ProductCardLayout from '../ProductCardTemplates/ProductCardLayout';
import ColorBreakoutProductCardLayout from '../ProductCardTemplates/ColorBreakoutProductCardLayout';
import Pager from '../Pagination/Pager';
const ConversationalFilter = lazy(
  () =>
    import(
      /* webpackChunkName: "ConversationalFilter" */ '../ConversationalFilters/ConversationalFilter'
    ),
  {
    chunkName: 'ConversationalFilter',
    noSsr: true,
  },
);
const ProductPinnedContentSpot = lazy(
  () =>
    import(
      /* webpackChunkName: "ProductPinnedContentSpot" */ '../ProductPinnedContentSpot/ProductPinnedContentSpot'
    ),
  {
    chunkName: 'ProductPinnedContentSpot',
    noSsr: true,
  },
);
const PiqProduct = lazy(
  () => import(/* webpackChunkName: "PiqProduct" */ '../PiqProduct/PiqProduct'),
  {
    chunkName: 'PiqProduct',
    noSsr: true,
  },
);
const GoogleAdContainer = lazy(
  () =>
    import(/* webpackChunkName: "GoogleAdContainer" */ '../GoogleAdContainer/GoogleAdContainer'),
  {
    chunkName: 'GoogleAdContainer',
    noSsr: true,
  },
);

import { audienceSelector, getCatentryId, piqEnabledStore } from './utility/product-card-utils';
import { getCookie } from '../../../utility/cookie-utils';
import {
  breakpointSelector,
  categoryIdentifierSelector,
  catgroupIdSelector,
  categoryAttrVoDataSelector,
  catTypeStringSelector,
  dsgVhSelector,
  isFamilyPageSelector,
  itemAvailabilitySelector,
  pageNumberSelector,
  pinnedContentSelector,
  productDetailsSelector,
  promotionDataSelector,
  selectedFiltersSelector,
  selectedSortSelector,
  snbAudienceSelector,
  storeIdentifierNameSelector,
  getFeatureFlagByNameSelector,
} from '../../../reduxStore/selectors';
import { useDispatch, useSelector } from 'react-redux';
import { shouldRenderProductCardItemAvailability } from '../../../utility/audience-utils';
import { getPinnedContentFromHomR, getPromotionData, getPromoMessageData } from '../../../reduxStore/thunks';
import { getPiqProducts } from '../../../reduxStore/thunks/getPiqProducts';
import { deepCompareWithStringify } from '../../../utility/utils';
import ErrorBoundary from '../../common/ErrorBoundary/ErrorBoundary';
import Sorting from '../../FeatureTesting/Sorting/Sorting';
import { triggerTargetView } from '../../../utility/analytics-events';
import { setMLImpressionProductsByRow } from '../../../reduxStore/slices/productApiData';
import { setPinnedContent } from '../../../reduxStore/slices/common';
import useProductApiData from '../../../hooks/useProductApiData';
import { usePiqProducts } from '../../../hooks/usePiqProducts';

// Component that will display the product card
const ProductGrid = () => {
  const [showQuickview, setShowQuickview] = useState(false);
  const dispatch = useDispatch();
  const retailMediaNetworkEnabled = useSelector(
    getFeatureFlagByNameSelector('retailMediaNetworkEnabled'),
  );
  const isPLPMLIntegrationEnabled = useSelector(
    getFeatureFlagByNameSelector('isPLPMLIntegrationEnabled'),
  );
  const productVOs = useProductApiData();
  const catTypeString = useSelector(catTypeStringSelector);
  const breakpoint = useSelector(breakpointSelector);
  const dsgVh = useSelector(dsgVhSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const pinnedContent = useSelector(pinnedContentSelector, deepCompareWithStringify);
  const { piqProducts } = usePiqProducts()
  const selectedFilters = useSelector(selectedFiltersSelector);
  const promotionData = useSelector(promotionDataSelector, deepCompareWithStringify);
  const itemAvailability = useSelector(itemAvailabilitySelector, deepCompareWithStringify);
  const productDetails = useSelector(productDetailsSelector, deepCompareWithStringify);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const selectedSort = useSelector(selectedSortSelector);
  const pageNumber = useSelector(pageNumberSelector);
  const quickviewCookie = getCookie('quickview');
  const modernCookie = getCookie('modern');
  const snbAudience = useSelector(snbAudienceSelector);
  const categoryAttrVoData = useSelector(categoryAttrVoDataSelector);
  // Get categroyIdentifier from redux store for quickview api call
  const categoryIdentifier = useSelector(categoryIdentifierSelector);
  // Get catgroupId from redux store for quickview api call
  const catgroupId = useSelector(catgroupIdSelector);
  const pinnedContentDisabled = useSelector(getFeatureFlagByNameSelector('pinnedContentDisabled'));
  const medalliaSurveyFlag = useSelector(getFeatureFlagByNameSelector('medalliaSurveyFlag'))
  const productCardRatingEnabled = useSelector(getFeatureFlagByNameSelector('productCardRatingEnabled'));


  let catentryIds: (string | number)[] = [];
  let ecodes: (string)[] = [];

  let justifyClass = 'justify-content-center';
  // on mobile put the space between two cards
  if (breakpoint === 'mobile') {
    justifyClass = 'justify-content-space-between';
    // On desktop when there are less than or equal to 3 products displaying justify the content left
  } else if (productVOs && productVOs.length <= 3) {
    justifyClass = 'left-justify-padding';
  }

  useEffect(() => {
    if (piqEnabledStore(storeIdentifierName)) {
      const sponsoredProductsValue =
        categoryAttrVoData && categoryAttrVoData.SPONSORED_PRODUCTS !== undefined
          ? categoryAttrVoData.SPONSORED_PRODUCTS.value
          : 'Y';
      if (sponsoredProductsValue === 'Y') {
        let audience = audienceSelector();

        let piqCount = Math.ceil((productVOs.length - 4) / 2);
        if (piqCount < 0) piqCount = 0;
        dispatch(getPiqProducts(piqCount <= 11 ? piqCount : 11, audience, catgroupId));
      }
    }
  }, [catgroupId]);

  useEffect(() => {
    if (
      quickviewCookie !== 'false' &&
      (storeIdentifierName === 'dsg' ||
        storeIdentifierName === 'pl' ||
        storeIdentifierName === 'g3' ||
        storeIdentifierName === 'gg' ||
        storeIdentifierName === 'mj')
    ) {
      // Set showQuickview to true on client side only when quickview cookie is not false
      setShowQuickview(true);
    }
  }, []);

  useEffect(() => {
    !pinnedContentDisabled && dispatch(getPinnedContentFromHomR());
    pinnedContentDisabled && dispatch(setPinnedContent([]));
  }, [pinnedContentDisabled])

  useEffect(() => {
    dispatch(shouldRenderProductCardItemAvailability(dsgVh));
    if (modernCookie === 'true') {
      dispatch(getPromoMessageData(ecodes, storeIdentifierName));
    } else if (catentryIds.length > 0 && catTypeString !== 'C') {
      dispatch(getPromotionData(catentryIds));
    }

  }, [productVOs]);

  const canShowPinnedContent = () => {
    if (
      pinnedContent &&
      pageNumber === 0 &&
      (selectedSort === 1 ||
        selectedSort === 0 ||
        selectedSort === 5 ||
        typeof selectedSort === 'undefined')
    ) {
      if (selectedFilters && selectedFilters.facetStore && selectedFilters.facetStore.length > 0) {
        if (Object.keys(selectedFilters).length === 1 && selectedFilters.facetStore.length === 2) {
          const hasShip = selectedFilters.facetStore.indexOf('SHIP') !== -1;
          const hasISA = selectedFilters.facetStore.indexOf('ISA') !== -1;
          return hasShip && hasISA;
        }
        return false;
      }
      return true;
    }
    return false;
  };

  useEffect(() => {
    if (canShowPinnedContent()) {
      triggerTargetView('plp--post-content-load');
    }
  }, [pinnedContent]);

  // Adding a condition to trigger Medallia when the feature flag is enabled by command. When all brands are set to true by default, this code will not be needed.
   useEffect(() => {
    if (medalliaSurveyFlag && typeof window !== 'undefined' &&  window.KAMPYLE_EMBED) {
      window.KAMPYLE_EMBED.kampyleInit();
    }
  }, [medalliaSurveyFlag]);

  const canShowPiqProducts = () => {
    if (
      piqProducts &&
      Object.keys(selectedFilters).length === 0 &&
      pageNumber === 0 &&
      (selectedSort === 1 ||
        selectedSort === 0 ||
        selectedSort === 5 ||
        typeof selectedSort === 'undefined')
    ) {
      if (selectedFilters && selectedFilters.facetStore && selectedFilters.facetStore.length > 0) {
        if (Object.keys(selectedFilters).length === 1 && selectedFilters.facetStore.length === 2) {
          const hasShip = selectedFilters.facetStore.indexOf('SHIP') !== -1;
          const hasISA = selectedFilters.facetStore.indexOf('ISA') !== -1;
          return hasShip && hasISA;
        }
        return false;
      }
      return true;
    }
    return false;
  };

  const renderGridArea = () => {
    const partnumberSet = new Set();

    // Dynamically handle product card height based on what gets rendered
    // Base theme height
    let productCardHeight = breakpoint === 'mobile' ? 330 : 420;
    if (storeIdentifierName === 'pl' || storeIdentifierName === 'mj') {
      productCardHeight = breakpoint === 'mobile' ? 384 : 500;
    } else if (storeIdentifierName === 'g3') {
      productCardHeight = breakpoint === 'mobile' ? 340 : 420;
    }
    // Add promotion text height if there are promotions
    productCardHeight =
      promotionData && typeof promotionData === 'object' && Object.keys(promotionData).length > 0
        ? productCardHeight + 36
        : productCardHeight;
    // Add quickview button height if quickview is enabled
    productCardHeight = showQuickview ? productCardHeight + 40 : productCardHeight;
    // Add item availability height if item availability is enabled
    productCardHeight =
      itemAvailability &&
        typeof itemAvailability === 'object' &&
        Object.keys(itemAvailability).length > 0
        ? productCardHeight + 50
        : productCardHeight;

    // Add star rating height if productCardRatingEnabled is enabled
    productCardHeight = productCardRatingEnabled ? productCardHeight + 40 : productCardHeight;

    let rowLength = 3;
    if (breakpoint === 'desktop' || breakpoint === 'small-desktop') {
      rowLength = 4;
    } else if (breakpoint === 'mobile') {
      rowLength = 2;
    }
    let pinnedContentSpots = new Map();
    if (pinnedContent && Array.isArray(pinnedContent)) {
      pinnedContentSpots = pinnedContent.reduce((map, spot) => {
        const index = spot.pinnedPosition - 1;
        map.set(index, spot);
        return map;
      }, new Map());
    }

    let productCount = 0;
    let currentPiq = 0;
    let totalCount = 0;
    let mlData: { [key in string]: { id: string }[] } = {};
    let cards: JSX.Element[] = [];
    const showPromos = canShowPinnedContent();
    const showPiq = canShowPiqProducts();
    let length = productVOs ? productVOs.length : 0;
    length += showPiq && piqProducts ? piqProducts.length : 0;
    length += showPromos && pinnedContentSpots ? pinnedContentSpots.size : 0;
    for (let i = 0; i < length; i += 1) {
      let content = null;
      const row = Math.floor(i / rowLength) + 1;
      const column = (i % rowLength) + 1;

      let conversationalFilters = null;
      // before the 3rd and 5th row
      if ((row === 3 || row === 5) && column === 1 && storeIdentifierName === 'dsg') {
        conversationalFilters = (
          <ErrorBoundary>
            <Suspense fallback="">
              <ConversationalFilter key={`${i}_pinned`} index={row === 3 ? 0 : 1} />
            </Suspense>
          </ErrorBoundary>
        );
      }

      let googleAdInGrid = null;
      if (
        ((row === 4 && breakpoint !== 'mobile') || (row === 6 && breakpoint === 'mobile')) &&
        column === 1 &&
        retailMediaNetworkEnabled
      ) {
        googleAdInGrid = (
          <ErrorBoundary>
            <Suspense fallback="">
              <GoogleAdContainer id="grid_banner" />
            </Suspense>
          </ErrorBoundary>
        );
      }

      if (showPromos && pinnedContentSpots && pinnedContentSpots.has(i)) {
        content = (
          <React.Fragment key={`${i}_pinned_wrapper`}>
            {conversationalFilters}
            {googleAdInGrid}
            <ErrorBoundary>
              <Suspense fallback="">
                <ProductPinnedContentSpot
                  pinnedContent={pinnedContentSpots.get(i)}
                  storeIdentifierName={storeIdentifierName}
                  row={row}
                  column={column}
                  rowLength={rowLength}
                  quickviewEnabled={showQuickview}
                  catTypeString={catTypeString}
                  productCardHeight={productCardHeight}
                />
              </Suspense>
            </ErrorBoundary>
          </React.Fragment>
        );
        cards = [...cards, content];
      } else {
        if (
          showPiq &&
          piqProducts &&
          piqProducts[currentPiq] &&
          totalCount > 3 &&
          totalCount % 3 === 0
        ) {
          content = (
            <React.Fragment key={`${i}_piq_wrapper`}>
              {conversationalFilters}
              {googleAdInGrid}
              <ErrorBoundary>
                <Suspense fallback="">
                  <PiqProduct
                    piqProduct={piqProducts[currentPiq]}
                    row={row}
                    column={column}
                    rowLength={rowLength}
                    showQuickview={showQuickview}
                    productCardHeight={productCardHeight}
                  />
                </Suspense>
              </ErrorBoundary>
            </React.Fragment>
          );
          if (isPLPMLIntegrationEnabled) {
            mlData = {
              ...mlData,
              [`${row}-${rowLength}`]: [
                ...(mlData[`${row}-${rowLength}`] ? mlData[`${row}-${rowLength}`] : []),
                { id: piqProducts[currentPiq].product_id },
              ],
            };
          }
          currentPiq += 1;
          totalCount += 1;
          cards = [...cards, content];
        } else if (productVOs && productVOs.length > productCount && productVOs[productCount]) {
          const product = productVOs[productCount];
          if (productDetails && !partnumberSet.has(product.partnumber)) {
            const catentryId = getCatentryId(isFamilyPage, product);
            const productDetail = productDetails[catentryId];
            if (
              !productDetail ||
              productDetail.mapPriceIndicator === undefined ||
              productDetail.priceIndicator === undefined ||
              productDetail.prices.maxlistprice === undefined ||
              productDetail.prices.minlistprice === undefined ||
              productDetail.prices.maxofferprice === undefined ||
              productDetail.prices.minofferprice === undefined
            ) {
              console.warn(
                'ProductDetail does not have a required field and will not be displayed. Missing one of: productDetail, mapPriceIndicator, priceIndicator, prices',
                product,
              );
            }

            if (productDetail) {
              content = (
                <React.Fragment key={product.partnumber}>
                  {conversationalFilters}
                  {googleAdInGrid}
                  {catTypeString !== 'C' ? (
                    <ProductCardLayout
                      key={`product-card-layout${product.partnumber}`}
                      catentryId={catentryId}
                      product={product}
                      productAttributes={product.attributes ? JSON.parse(product.attributes) : null}
                      productDetails={productDetail}
                      showQuickview={showQuickview}
                      row={row}
                      column={column}
                      rowLength={rowLength}
                      productCardHeight={productCardHeight}
                    />
                  ) : (
                    <ColorBreakoutProductCardLayout
                      key={`color-breakout-card-${product.partnumber}`}
                      product={product}
                      productDetails={productDetail}
                      row={row}
                      column={column}
                      rowLength={rowLength}
                    />
                  )}
                </React.Fragment>
              );

              if (catentryId) {
                // Collecting catentryIds in an array for the Promotion Descriptions for products
                catentryIds.push(catentryId);
              }

              if (product.parentPartnumber) {
                ecodes.push(product.parentPartnumber)
              }
              partnumberSet.add(product.partnumber);
              cards = [...cards, content];
              if (isPLPMLIntegrationEnabled) {
                mlData = {
                  ...mlData,
                  [`${row}-${rowLength}`]: [
                    ...(mlData[`${row}-${rowLength}`] ? mlData[`${row}-${rowLength}`] : []),
                    { id: product.parentPartnumber },
                  ],
                };
              }
              totalCount += 1;
            }
          }
          productCount += 1;
        }
      }
    }
    if (isPLPMLIntegrationEnabled) dispatch(setMLImpressionProductsByRow(mlData));
    return cards;
  };

  return (
    <>
      <div
        role="region"
        aria-label="Products"
        className="rs_product_card_container dsg-flex flex-wrap">
        <div
          className={`dsg-react-product-cards dsg-flex flex-wrap align-content-flex-start ${justifyClass}`}>
          {renderGridArea()}
        </div>
        {!productVOs ||
          (productVOs.length === 0 && (
            <div className="ProductCard-NoResults">
              No products match your filter criteria, try adjusting your filters.
            </div>
          ))}

        <div className="below-grid-controls">
          {snbAudience && snbAudience === 'improvedProductGrid' && <Sorting />}
          <Pager />
        </div>
      </div>

      { !isFamilyPage && medalliaSurveyFlag &&
        <div className="hmf-position-relative hmf-display-inline-block containerSurvey">
          <div id="satisfaction_emb_form" className="hmf-mt-l hmf-ml-s hmf-mr-s dsg-flex"></div>
        </div>}
    </>
  );
};

export default ProductGrid;
