import React, { memo, useEffect, useState } from 'react';
import { deepCompareWithStringify, handleADA } from '../../../../utility/utils';

import { getImageHost } from '../../../../utility/url-utils';
import { ProductDetail } from '../../../../types';
import defaultImage from './grayImageBox';

type Props = {
  breakpoint: string;
  parentPartNumber: string;
  colorAttribute?: string[];
  url: string;
  parentSwatchChange: () => void;
  onSwatchChange: () => void;
};

const ProductSwatch = ({
  breakpoint,
  parentPartNumber,
  colorAttribute,
  url,
  parentSwatchChange,
  onSwatchChange,
}: Props) => {
  const productColors = colorAttribute;

  const getBreakpointColors = () => {
    if (productColors && Array.isArray(productColors)) {
      // Limit visible swatches by viewport size
      return breakpoint === 'desktop' ? productColors.slice(0, 7) : productColors.slice(0, 3);
    }
    return null;
  };

  const [colors, setColors] = useState(getBreakpointColors);
  const [defaultActive, setDefaultActive] = useState(true);

  useEffect(() => {
    setColors(getBreakpointColors());
  }, [breakpoint, colorAttribute]);

  const updateActiveSwatch = (colorVal, thumbnail, e) => {
    e.preventDefault();
    document
      .getElementsByClassName(`fp_${parentPartNumber} rs-fplp-swatch active`)[0]
      ?.classList?.remove('active');

    e.target.classList.add('active');

    if (defaultActive) {
      setDefaultActive(false);
    }

    parentSwatchChange({ swatchColor: colorVal, thumbnail });
    onSwatchChange({ swatchColor: colorVal, thumbnail });
  };

  const renderSwatches = () => {
    if (!colors || !productColors) return null;

    // Create array of swatch elements limited by the maxViewportSwatches
    let swatchElements = colors.map((color, index) => {
      return (
        <li
          className={`rs-swatch-li ${index === 0 ? 'first first-item' : ''} ${
            colors.length - 1 === index ? 'first last-item' : ''
          }`}
          key={`${parentPartNumber}-swatch-${color.replace(/[^\w]/gi, '_')}-${index}`}>
          <a
            role="radio"
            tabIndex={0}
            className={`fp_${parentPartNumber} rs-fplp-swatch${
              index === 0 && defaultActive ? ' active' : ''
            }`}
            aria-checked="false"
            onMouseOver={(e) => updateActiveSwatch(color, parentPartNumber, e)}
            onFocus={(e) => updateActiveSwatch(color, parentPartNumber, e)}
            onKeyPress={(e) =>
              handleADA(e, 'button', updateActiveSwatch(color, parentPartNumber, e))
            }>
            <img
              className="lazy-content lazyload"
              id={`swatchad_grid_${parentPartNumber}_${color}`}
              alt={color}
              src={defaultImage}
              title={color}
              data-src={`${getImageHost()}/${parentPartNumber}_${color.replace(
                /[^\w]/gi,
                '_',
              )}_swatch/?$DSG_ProductSwatch$&resMode=bilin&qlt=80`}
              style={{ display: 'block' }}
              width="20"
              height="20"
              aria-label={`${color} color`}
            />
          </a>
        </li>
      );
    });

    const remainingSwatches =
      productColors.length > colors.length ? productColors.length - colors.length : 0;

    return remainingSwatches
      ? [
          ...swatchElements,
          <li
            className={`${
              breakpoint === 'desktop' ? 'rs-swatch-more' : 'rs-fplp-margin-swatch-more'
            } dsg-flex last last-item`}
            key={`${parentPartNumber}-swatch-more-li`}>
            <a
              className="rs-fplp-swatch-more "
              title="More"
              id={`swatch-more-${breakpoint === 'desktop' ? 'desktop' : 'mobile'}`}
              tabIndex={0}
              href={url}>
              {remainingSwatches}+
            </a>
          </li>,
        ]
      : swatchElements;
  };

  return (
    <div className="rs-fswatch">
      <ul
        className="dsg-flex flex-grow-none flex-shrink justify-content-center ul_swatch"
        id={`list_${breakpoint === 'desktop' ? '' : 'mbl'}${parentPartNumber}`}>
        {renderSwatches()}
      </ul>
    </div>
  );
};

export default memo(ProductSwatch, deepCompareWithStringify);
