import React from 'react';
import PropTypes from 'prop-types';

const variants = {
  secondary: 'secondary',
  tertiary: 'tertiary',
  primary: 'primary',
  marketing: 'marketing'
};

const Button = (props) => {
  const {
    variant,
    classes,
    labelClasses,
    label,
    onClick,
    disabled,
    active,
    success,
    tabIndex,
    ariaLabel,
  } = props;

  const buttonClasses = ['dsg-react-button'];
  const combinedLabelClasses = ['dsg-react-button-label'];

  // Apply additional class names for secondary and tertiary buttons
  if (variant === variants.secondary) {
    buttonClasses.push(variants.secondary);
  } else if (variant === variants.tertiary) {
    buttonClasses.push(variants.tertiary);
    combinedLabelClasses.push(variants.tertiary);
  } else if (variant === variants.marketing) {
    buttonClasses.push(variants.marketing);
    combinedLabelClasses.push(variants.marketing);
  }

  // If disabled is true, add the disabled class
  if (disabled) {
    buttonClasses.push('dsg-react-button-disabled');
  }

  buttonClasses.push(...classes);
  combinedLabelClasses.push(...labelClasses);

  return (
    <button
      type="button"
      aria-label={ariaLabel || label}
      alt={ariaLabel || label}
      tabIndex={tabIndex}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onClick}
      className={`${buttonClasses.join(' ').trim()} ${active ? 'active' : ''} ${
        success ? 'success' : ''
      }`}
      data-testid="button"
      disabled={disabled}>
      <div className="background dsg-flex flex-grow flex-column align-items-center justify-content-center">
        <span className={combinedLabelClasses.join(' ').trim()}>{label}</span>
      </div>
    </button>
  );
};

Button.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired, // This is text to be displayed on the button
  ariaLabel: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  onClick: PropTypes.func.isRequired, // Function to call when button is pressed
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the button
  labelClasses: PropTypes.arrayOf(PropTypes.string), // Additional class names to append to the class list of the button label
  disabled: PropTypes.bool, // Boolean used to disable the button
  variant: PropTypes.oneOf([variants.primary, variants.secondary, variants.tertiary, variants.marketing]), // The variant of the button, default is "primary"
  tabIndex: PropTypes.number,
  active: PropTypes.bool,
  success: PropTypes.bool,
};

Button.defaultProps = {
  disabled: false,
  classes: [],
  labelClasses: [],
  variant: variants.primary,
  tabIndex: 0,
  active: false,
  success: false,
  ariaLabel: false,
};

export default Button;
