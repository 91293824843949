import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  showMobileFilterFacetModalSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import { handleMobileFilterFacetModalClick } from '../../../reduxStore/thunks';
import { handleADA } from '../../../utility/utils';
import { default as CaliaFilterIcon } from '../../common/SVGIcons/Calia/FilterIcon';
import PLFilterIcon from '../../common/SVGIcons/PublicLands/PLFilterIcon';
import SettingsIcon from '../../common/SVGIcons/SettingsIcon';
import MJFilterIcon from '../../common/SVGIcons/MooseJaw/MJFilterIcon';

const FilterHeading = ({ breakpoint }) => {
  const dispatch = useDispatch();
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      /*
       * Dispatch a scroll event to fix the sticky calculations after the filter facets have been toggled.
       * See initStickyClasses and stickyToggle logic for it's functionality in utility/utils.jsx
       */
      window.dispatchEvent(new CustomEvent('scroll'));
    }
  }, [showMobileFilterFacetModal]);
  
  const createFilterIcon = () => {
    switch (storeIdentifierName) {
      case 'calia':
        return (
          <CaliaFilterIcon
            pathClasses={[
              showMobileFilterFacetModal
                ? 'dsg-react-filter-color-down'
                : 'dsg-react-filter-color-right',
            ]}
          />
        );
      case 'MJ':
        return (
          <MJFilterIcon
            pathClasses={[
              showMobileFilterFacetModal
                ? 'dsg-react-filter-color-down'
                : 'dsg-react-filter-color-right',
            ]}
          />
        );
      case 'pl':  
        return (
          <PLFilterIcon
            pathClasses={[
              showMobileFilterFacetModal
                ? 'dsg-react-filter-color-down'
                : 'dsg-react-filter-color-right',
            ]}
          />
        );
      default:
        return (
          <SettingsIcon
            pathClasses={[
              showMobileFilterFacetModal
                ? 'dsg-react-filter-color-down'
                : 'dsg-react-filter-color-right',
            ]}
          />
        );
    }
  };

  return (
    <div
      tabIndex={0}
      className={`rs-filter-heading focus-border`}
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={() => {
        dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
      }}
      onKeyPress={(e) =>
        handleADA(e, 'button', () =>
          dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal)),
        )
      }>
      <div className="rs-filter-icon-text">
        <div className="rs-filter-svg">{createFilterIcon()}</div>
        <div className="rs-filter-text">Filter</div>
      </div>
    </div>
  );
};

export default FilterHeading;
