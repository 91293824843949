import React from 'react';
import { getShownCarouselItems } from './productAdUtils';
import CarouselArrow from '../../common/CarouselNavigationArrow/CarouselArrow';

export const getSliderSettings = (productCount, breakpoint, storeIdentifierName) => {
  const shownItems = getShownCarouselItems(breakpoint, storeIdentifierName);
  return {
    dots: false,
    infinite: productCount > shownItems,
    speed: 500,
    slidesToShow: shownItems,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: shownItems,
          slidesToScroll: 1,
          infinite: productCount > shownItems,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: shownItems,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: shownItems,
          slidesToScroll: 1,
        },
      },
    ],
  };
};

export const getPromoteIQUrl = (environment) => {
  if (environment === 'prod') {
    return 'https://ad-dickssportinggoods-prod.tagdelivery.com/request';
  }

  return 'https://ad-dsg-sandbox.tagdelivery.com/request';
};
