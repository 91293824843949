import {
  getBreadcrumbContainerTop,
  primitiveDeepCopy,
  setScrollPosition,
} from '../../utility/utils';
import { filterData } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

export function applyFilter(identifier, newValues, facetType, convoFilter = false) {
  const values = getBreadcrumbContainerTop();
  return (dispatch, getState) => {
    const updateVal = {};

    if (values) {
      setScrollPosition(0, values.top);
    }
    const state = getState();
    dispatch(isSearching());

    const facetDetails = state.productApiData.facetVOs;
    const { categoryDetails } = state.common;
    const existingFilters = state.input.selectedFilters
      ? primitiveDeepCopy(state.input.selectedFilters)
      : {};

    if (existingFilters && existingFilters[identifier]) {
      newValues.forEach((v) => {
        const remove = v.charAt(0) === '-';

        if (remove) {
          const value = v.substring(1);
          if (existingFilters[identifier].indexOf(value >= 0)) {
            existingFilters[identifier].splice(existingFilters[identifier].indexOf(value), 1);

            if (existingFilters[identifier].length === 0) {
              delete existingFilters[identifier];
            }
          }
        } else {
          if (facetType === 'single') {
            existingFilters[identifier] = [v];
          } else if (facetType === 'multi') {
            // create new array with no duplicates and new value
            existingFilters[identifier] = Array.from(
              new Set(existingFilters[identifier].concat(v)),
            );
          }

          let refinementAttr = identifier;
          if (facetDetails) {
            facetDetails.forEach((facet) => {
              if (facet.attrIdentifier === identifier) {
                refinementAttr = facet.attrName;
              }
            });
          }
          updateVal.RefinementAttribute = refinementAttr;
          updateVal.RefinementValue = convoFilter ? existingFilters[identifier] : newValues[0];
          updateVal.Conversational = convoFilter ? 'Yes' : 'No';
        }
      });
    } else {
      // filter category doesn't exist yet
      updateVal.RefinementValue = convoFilter ? newValues : newValues[0];
      updateVal.Conversational = convoFilter ? 'Yes' : 'No';
      if (identifier.indexOf('Category') >= 0) {
        updateVal.RefinementAttribute = 'Category';
      } else if (identifier.indexOf('facetCatgroup') >= 0) {
        updateVal.RefinementAttribute = 'Category';
        if (categoryDetails && categoryDetails[newValues[0]]) {
          updateVal.RefinementValue = categoryDetails[newValues[0]].name;
        }
      } else {
        let refinementAttr = identifier;

        facetDetails.forEach((facet) => {
          if (facet.attrIdentifier === identifier) {
            refinementAttr = facet.attrName;
          }
        });

        updateVal.RefinementAttribute = refinementAttr;
      }
      existingFilters[identifier] = newValues;
    }

    const refinementUpdateObj = { UpdateType: 'Refinement', UpdateValue: updateVal };

    dispatch(filterData({ existingFilters: existingFilters, setPageDefault: true }));
    const stateData = getState();
    const { input } = stateData;

    callAPI(input, dispatch, stateData, false, refinementUpdateObj);
  };
}
