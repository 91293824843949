import React from 'react';
import PropTypes from 'prop-types';

const AlertErrorIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99967 1.33301C4.31967 1.33301 1.33301 4.31968 1.33301 7.99968C1.33301 11.6796 4.31967 14.6663 7.99967 14.6663C11.6797 14.6663 14.6664 11.6796 14.6664 7.99968C14.6664 4.31968 11.6797 1.33301 7.99967 1.33301ZM7.33301 11.333V9.99964H8.66634V11.333H7.33301ZM7.33301 4.66635V8.66635H8.66634V4.66635H7.33301Z"
        fill="#B80818"
        className={pathClasses}
      />
    </svg>
  );
};

export default AlertErrorIcon;

AlertErrorIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

AlertErrorIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
