import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AlertMessage from '../../common/AlertMessage/AlertMessage';
import { getCookie } from '../../../utility/cookie-utils';
import {
  pageTitleSelector,
  showMobileFilterFacetModalSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import { handleMobileFilterFacetModalClick } from '../../../reduxStore/thunks';
import SelectedFiltersChips from '../../common/SelectedFilterChips/SelectedFilterChips';

const NoProductResults = () => {
  const dispatch = useDispatch();
  const pageTitle = useSelector(pageTitleSelector);
  const storeIdentiferName = useSelector(storeIdentifierNameSelector);
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);

  // Closes the mobile filter facet mobile when the no product results condition happens
  useEffect(() => {
    if (showMobileFilterFacetModal) {
      dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
    }
  }, []);

  const itemAvailabilityEnabled = getCookie('ItemAvailability');

  return (
    <div className="dsg-flex rs-no-products align-items-flex-end ">
      <div className=" dsg-flex align-items-center flex-column rs-no-result">
        {itemAvailabilityEnabled !== 'false' && (
          <AlertMessage
            title={
              (storeIdentiferName !== 'pl' && storeIdentiferName !== 'mj')
                ? `"${pageTitle}" are Unavailable to Ship or Pickup in Your Area`
                : `"${pageTitle}" are not available to be shipped or available for pickup.`
            }
            message={
              (storeIdentiferName !== 'pl' && storeIdentiferName !== 'mj')
                ? `Due to exceptional demand, "${pageTitle}" are unavailable in your area. Please clear the filters below and change your store location to see what might be available.`
                : `Some items are not available to be shipped or there are no products available to be picked up locally. We’re working on expanding.`
            }
            type="warn"
            customClass="dsg-rs-no-results-product-warning"
            inlineIcon
          />
        )}
        <span className="rs-clear-filters">Remove filters to view products:</span>
        <span>
          <SelectedFiltersChips showClearAllLink={true} />
        </span>
      </div>
    </div>
  );
};

export default NoProductResults;
