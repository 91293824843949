import { decodeAndReplaceHTML } from './utils';

const comparedProductsPrefix = 'DSGCompareItems_';
const comparedProductImgPrefix = 'DSGCompareItemsImg_';
const comparedProductDetailsPrefix = 'DSGCompareDetails_';

export function addComparedProduct(product = {}, categoryDetails = {}) {
  let cookieNameVal = product.searchTerm;

  if (!cookieNameVal) {
    cookieNameVal = getCategoryId(categoryDetails);
  }
  const productCookieName = comparedProductsPrefix + cookieNameVal;
  const imageCookieName = comparedProductImgPrefix + cookieNameVal;
  const catentryId = product.details.parentCatentryId;
  const imgURL = product.imageSource;

  // products should be catId;catId2
  // images are catId,URL;catId2,Url2;

  const productCookieValue = decodeAndReplaceHTML(getCookie(productCookieName)) || '';
  const imgCookieValue = decodeAndReplaceHTML(getCookie(imageCookieName)) || '';
  setCookie(
    productCookieName,
    encodeURIComponent(
      productCookieValue ? `${productCookieValue};${catentryId}` : `${catentryId}`,
    ),
  );
  setCookie(imageCookieName, encodeURIComponent(`${imgCookieValue + catentryId},${imgURL};`));
}

export function getCategoryId(categoryDetails) {
  const keys = Object.keys(categoryDetails);
  if (keys.length === 1) {
    const splitName = keys[0].split('_');
    return splitName.length === 2 ? splitName[1] : splitName[0];
  }
}

export function setAddress(addressline) {
  let value = encodeURIComponent(addressline);
  if (value && typeof value === 'string' && value.length > 5) {
    value = value.slice(0, 5);
  }
  setCookie('myStoreAddress', value);
}

export function getAddress() {
  return decodeAndReplaceHTML(getCookie('myStoreAddress'));
}

export function setStoreCookie(postalcode, name, clientkey) {
  if (postalcode && typeof postalcode === 'string' && postalcode.length > 5) {
    postalcode = postalcode.slice(0, 5);
  }
  const value = encodeURIComponent(`${postalcode}_${name}_${clientkey}`);
  setCookie('setStoreCookie', value);
  setCookie('myStoreAddress', postalcode);
}

export function removeComparedProducts(products = [], categoryDetails = {}) {
  products.forEach((product) => {
    let cookieNameVal = product.searchTerm;

    if (!cookieNameVal) {
      cookieNameVal = getCategoryId(categoryDetails);
    }
    const productCookieName = comparedProductsPrefix + cookieNameVal;
    const imageCookieName = comparedProductImgPrefix + cookieNameVal;
    const catentryId = product.details.parentCatentryId;
    const imgURL = product.imageSource;
    const productCookieValue = decodeAndReplaceHTML(getCookie(productCookieName)) || '';
    const imgCookieValue = decodeAndReplaceHTML(getCookie(imageCookieName)) || '';

    const allCats = productCookieValue ? productCookieValue.split(';') : '';
    const index = allCats.indexOf(`${catentryId}`);
    allCats.splice(index, 1);

    const allImages = imgCookieValue ? imgCookieValue.split(';') : '';
    const imgIndex = allImages.indexOf(`${catentryId},${imgURL}`);
    allImages.splice(imgIndex, 1);

    const newProdVal = allCats.join(';');
    const newImgVal = allImages.join(';');

    setCookie(productCookieName, encodeURIComponent(newProdVal));
    setCookie(imageCookieName, encodeURIComponent(newImgVal));
  });
}

export function getComparedCatentryIds(searchTerm, categoryDetails = {}) {
  let cookieNameVal = searchTerm;

  if (!cookieNameVal) {
    cookieNameVal = getCategoryId(categoryDetails);
  }
  const productCookieName = comparedProductsPrefix + cookieNameVal;
  const productCookieValue = decodeAndReplaceHTML(getCookie(productCookieName)) || '';
  if (productCookieValue === '') {
    return [];
  }
  return productCookieValue ? productCookieValue.split(';') : '';
}

export function getComparedIdImageMap(searchTerm, categoryDetails = {}) {
  let cookieNameVal = searchTerm;

  if (!cookieNameVal) {
    cookieNameVal = getCategoryId(categoryDetails);
  }
  const cookieName = comparedProductImgPrefix + cookieNameVal;
  const cookieValue = decodeAndReplaceHTML(getCookie(cookieName));
  const vals = cookieValue ? cookieValue.split(';') : '';

  return vals.map((val) => {
    const idImgArray = val.split(',');
    return { parentCatentryId: idImgArray[0], imgUrl: idImgArray[1] };
  });
}

export function setCookie(cname, cvalue) {
  if (typeof document !== 'undefined') {
    const expDate = new Date();
    expDate.setUTCFullYear(expDate.getUTCFullYear() + 1);
    document.cookie = `${cname}=${cvalue};expires=${expDate.toString()}; path=/`;
  }
}

export function getCookie(cname) {
  const name = `${cname}=`;
  if (typeof document !== 'undefined') {
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) == ' ') {
        c = c.substring(1);
      }
      if (c.indexOf(name) == 0) {
        return c.substring(name.length, c.length);
      }
    }
  }
  return '';
}

export function deleteCookie(cname) {
  if (typeof document !== 'undefined') {
    document.cookie = `${cname}= ; expires = Thu, 01 Jan 1970 00:00:00 GMT;path=/`;
  }
}
