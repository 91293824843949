import { removeAllFilterData, removeFilterData } from '../../utility/history';
import { primitiveDeepCopy } from '../../utility/utils';
import { filterData } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

export function clearAllFilterData(category = false) {
  return (dispatch, getState) => {
    dispatch(isSearching());

    if (getState().input) {
      const stateInput = primitiveDeepCopy(getState().input);
      if (stateInput.selectedFilters) {
        const myObj = stateInput.selectedFilters;
        if (category) {
          if (myObj) {
            Object.keys(myObj).forEach((key) => {
              if (key.indexOf('categories') > 1) {
                delete myObj[key];
                removeFilterData(key);
              }
              if (key === category) {
                delete myObj[key];
                removeFilterData(key);
              }
            });
          }
          dispatch(filterData({ existingFilters: myObj, setPageDefault: true }));
        } else {
          removeAllFilterData(stateInput.selectedFilters);
          dispatch(filterData({ existingFilters: {}, setPageDefault: true }));
        }
      }

      const stateData = getState();
      const { input } = stateData;

      callAPI(input, dispatch, stateData);
    }
  };
}
