import React, { useState, useEffect, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { updateFilter } from '../../../../utility/history';
import RemoveCircleIcon from '../../../common/SVGIcons/RemoveCircleIcon';
import { toggleOpenCloseFacet, handleADA } from '../../../../utility/utils';
import Checkbox from '../../../common/FacetCheckBox/FacetCheckBox';
import FacetSearchBar from '../FacetSearchBar/FacetSearchBar';
import CreateFacetContainer from './CreateFacetContainer';
import { storeIdentifierNameSelector } from '../../../../reduxStore/selectors';

const BrandSelectFacet = ({
  facet,
  selectedData,
  onFilterData,
  openFacet,
  breakpoint,
  storeName,
  // key,
  onSelection,
}) => {
  const [facetExpanded, setFacetExpanded] = useState(openFacet);
  const [showFullList, setShowFullList] = useState(true);
  const [showNotFound, setShowNotFound] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);

  const brandsBreakQuantity = useMemo(() => 10, []);
  const showTopBrands = useMemo(() => {
    if (
      ['dsg', 'gg', 'pl', 'g3', 'mj'].includes(storeIdentifierName || '') &&
      facet.valueList.length < brandsBreakQuantity + 1
    ) {
      return false;
    }

    return true;
  }, [brandsBreakQuantity, facet, storeIdentifierName]);

  const onFilterChange = (val) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData && selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'multi');
    updateFilter(identifier, values, 'multi');
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const createFacetValues = (valueList, identifier, selectedData) => {
    // Slice the array to a max of [brandsBreakQuantity + 1] items since we only display [brandsBreakQuantity] before showing View All Brands
    const newValueList = [...valueList];

    if (showTopBrands) {
      newValueList.sort((valOne, valTwo) => (valOne.count < valTwo.count ? 1 : -1));
    }

    return newValueList.slice(0, 11).map((value, i) => {
      if (i >= brandsBreakQuantity) {
        return (
          <div
            className="rs-view-all"
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={viewAllFilterHandler}
            onKeyPress={(e) => handleADA(e, 'button', viewAllFilterHandler)}
            key={i}
            tabIndex="0">
            View All Brands +
          </div>
        );
      }

      const facetValue = newValueList[i];
      const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
      const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;

      return (
        <Checkbox
          key={`${identifier}_${i}`}
          checkboxId={`checkbox_${identifier}_${i}`}
          onCheckboxToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
          disabled={disabled}
          labelledby={`brand_facet_value_${facetValue.value.replace(/[^A-Z0-9]/gi, '_')}`}
          facetName={facetValue.value}
          facetCount={facetValue.count}
          breakpoint={breakpoint}
          selectedData={selectedData}
          facetValue={facetValue}
          isCheckedValue={isChecked}
        />
      );
    });
  };

  const [valueList, setValueList] = useState(
    createFacetValues(facet.valueList, facet.attrIdentifier, selectedData),
  );

  const expandList = (valueList, identifier, showNotFound, value = '') => {
    const facetList = [];
    const regex = /[A-za-z]/;
    if (showNotFound) {
      facetList.push(
        <div key={`${value}`} className="rs-facet-error-search dsg-flex align-items-center">
          <RemoveCircleIcon pathClasses={['store-pickup-search-error']} />
          <span>{`No results found for '${value}'`}</span>
        </div>,
      );
    } else {
      const groups = valueList.reduce((group, brand) => {
        let letterKey = brand.value.charAt(0).toUpperCase();
        if (!regex.test(letterKey)) {
          letterKey = '';
        }
        (group[letterKey] || (group[letterKey] = [])).push(brand);
        return group;
      }, {});
      Object.entries(groups)
        .sort()
        .map(([letterKey, groupedFacetList]) => {
          facetList.push(
            <div key={letterKey}>
              <h4>{letterKey}</h4>
            </div>,
          );
          groupedFacetList.forEach((facetValue, i) => {
            const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
            const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;
            facetList.push(
              <div key={`${facetValue.value}`}>
                <Checkbox
                  key={`${identifier}_${i}`}
                  checkboxId={`checkbox_${identifier}_${i}`}
                  onCheckboxToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
                  disabled={disabled}
                  labelledby={`brand_facet_value_${facetValue.value.replace(/[^A-Z0-9]/gi, '_')}`}
                  facetName={facetValue.value}
                  facetCount={facetValue.count}
                  breakpoint={breakpoint}
                  selectedData={selectedData}
                  facetValue={facetValue}
                  isCheckedValue={isChecked}
                />
              </div>,
            );
          });
        });
    }
    setValueList(...[facetList]);
    setShowFullList(false);
  };
  // Brand search
  const searchData = useCallback(
    (value, evt) => {
      if (evt) {
        if (facet) {
          if (value) {
            setIsFilterExpanded(true);
            const lowercaseValue = value.toLowerCase();
            const filteredItems = facet.valueList.filter((values) => {
              return values.value.toLowerCase().indexOf(lowercaseValue) >= 0;
            });
            if (filteredItems.length === 0) {
              setShowNotFound(true);
            } else {
              setShowNotFound(false);
            }
            expandList(filteredItems, facet.attrIdentifier, filteredItems.length === 0, value);
          } else {
            expandList(facet.valueList, facet.attrIdentifier, false, value);
          }
        }
      }
    },
    [facet],
  );

  const viewAllFilterHandler = (identifier) => {
    setIsFilterExpanded(true);
    expandList(facet.valueList, identifier);
  };

  useEffect(() => {
    if (isFilterExpanded) {
      expandList(facet.valueList, facet.attrIdentifier);
      return;
    }
    const createFacetList = createFacetValues(facet.valueList, facet.attrIdentifier, selectedData);
    if (facet) {
      setValueList(createFacetList);
    }
  }, [selectedData]);

  const toggleFacet = (e, identifier) => {
    // Handle toggling open/close of filter facet
    toggleOpenCloseFacet(identifier);
    setFacetExpanded(!facetExpanded);
  };

  useEffect(() => {
    setFacetExpanded(openFacet);
  }, [openFacet]);

  return (
    <CreateFacetContainer
      facet={facet}
      openFacet={openFacet}
      selectedData={selectedData}
      showNotFound={showNotFound}
      storeName={storeName}
      valueList={valueList}
      toggleFacet={toggleFacet}
      facetExpanded={facetExpanded}
      type="brand">
      {showTopBrands && <FacetSearchBar inputValueChange={searchData} name="Brands" />}
      {showFullList && showTopBrands && <span className="rs-facet-brand-title">Top Brands</span>}
    </CreateFacetContainer>
  );
};
export default BrandSelectFacet;

BrandSelectFacet.propTypes = {
  /** facet object */
  facet: PropTypes.object.isRequired,
  /** selected data array for particular facet */
  selectedData: PropTypes.array,
  /** facet open or close flag */
  openFacet: PropTypes.bool.isRequired,
  /** function to apply selected filter */
  onFilterData: PropTypes.func.isRequired,
};
