import { combineReducers } from 'redux';
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import handleAnalyticCall from './plp-srlp-analytics';

// All Slices
import common from './slices/common';
import input from './slices/input';
import productApiData from './slices/productApiData';
import user from './slices/user';

export const rootReducer = combineReducers({
  common,
  input,
  productApiData,
  user,
});

export const analyticMiddleWare = (store) => (next) => (action) => {
  if (action && action.type) {
    handleAnalyticCall(action.type, action.payload, store);
    next(action);
  }
};

export default () =>
  configureStore({
    reducer: rootReducer,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analyticMiddleWare),
    devTools: process.env.NODE_ENV !== 'production',
  });

// export type RootState = ReturnType<typeof store.getState>;

// export type AppDispatch = typeof store.dispatch;
export const useAppDispatch = () => useDispatch(); // Export a hook that can be reused to resolve types
