import React from 'react';
import PropTypes from 'prop-types';

const VehicleCarIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="14"
      height="14"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={classes}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.5 4c.66 0 1.22.42 1.42 1.01L21 11v8c0 .55-.45 1-1 1h-1c-.55 0-1-.45-1-1v-1H6v1c0 .55-.45 1-1 1H4c-.55 0-1-.45-1-1v-8l2.08-5.99C5.29 4.42 5.84 4 6.5 4h11ZM5 13.5c0 .83.67 1.5 1.5 1.5S8 14.33 8 13.5 7.33 12 6.5 12 5 12.67 5 13.5ZM17.5 15c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5Zm-11-9.5L5 10h14l-1.5-4.5h-11Z"
        fill="#191F1C"
        className={pathClasses}
      />
    </svg>
  );
};

export default VehicleCarIcon;

VehicleCarIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

VehicleCarIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
