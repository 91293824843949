import React from 'react';
import PropTypes from 'prop-types';

const AlertInfoIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.6667 11.3333L14 12.6667V13.3333H2V12.6667L3.33333 11.3333V7.33333C3.33333 5.1 4.90667 3.23333 7 2.78V2C7 1.44667 7.44667 1 8 1C8.55333 1 9 1.44667 9 2V2.78C11.0933 3.23333 12.6667 5.1 12.6667 7.33333V11.3333ZM9.32667 14.0067C9.32667 14.74 8.73333 15.3333 8 15.3333C7.26667 15.3333 6.67333 14.74 6.67333 14.0067H9.32667ZM7.33333 10.6667V9.33333H8.66667V10.6667H7.33333ZM7.33333 5.33333V8H8.66667V5.33333H7.33333Z"
        fill="#0F314F"
        className={pathClasses}
      />
    </svg>
  );
};

export default AlertInfoIcon;

AlertInfoIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

AlertInfoIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
