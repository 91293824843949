import React, { useMemo } from 'react';
import { getFeatureFlagByNameSelector, productVOsSelector } from '../reduxStore/selectors';
import { useSelector } from 'react-redux';
import { ProductVO } from '../types';

const useProductApiData = () => {
  const pinnedProductsDisabled = useSelector(getFeatureFlagByNameSelector('pinnedProductsDisabled'));
  const rawProductVOs = useSelector(productVOsSelector);
  const productsVOs = useMemo(() => {
    if (pinnedProductsDisabled) {
      return rawProductVOs.filter((p:ProductVO) => p.isPinned === false)
    }

    return rawProductVOs
  }, [rawProductVOs,pinnedProductsDisabled])
  
  return productsVOs;
};

export default useProductApiData;
