import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import StoreDisplay from '../StoreDisplay/StoreDisplay';
import { default as CaliaFilterIcon } from '../../../common/SVGIcons/Calia/FilterIcon';
import PLFilterIcon from '../../../common/SVGIcons/PublicLands/PLFilterIcon';
import SettingsIcon from '../../../common/SVGIcons/SettingsIcon';
import {
  getFeatureFlagByNameSelector,
  showMobileFilterFacetModalSelector,
  storeIdentifierNameSelector,
} from '../../../../reduxStore/selectors';
import { handleMobileFilterFacetModalClick } from '../../../../reduxStore/thunks';
import { handleADA } from '../../../../utility/utils';
import ShippingMethodButton from '../ShippingMethodFilter/ShippingMethodButton';
import MJFilterIcon from '../../../common/SVGIcons/MooseJaw/MJFilterIcon';

const theme = {
  calia: {
    icon: <CaliaFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  pl: {
    icon: <PLFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  mj: {
    icon :<MJFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  default: {
    icon: <SettingsIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
};

const MobileStickyFilters = ({
  selectedStoreName,
  toggleMyStoreModal,
  selectedShippingMethods,
  storeFacet,
  selectedFilters,
}) => {
  const dispatch = useDispatch();
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const [selectedFilterCount, setSelectedFilterCount] = useState(0);
  const SDDEnabled = useSelector(getFeatureFlagByNameSelector('SDDEnabled'));
  const updateFilterOrderEnabled = useSelector(
    getFeatureFlagByNameSelector('updateFilterOrderEnabled'),
  );

  const themeData = theme[storeIdentifierName] ? theme[storeIdentifierName] : theme.default;

  useEffect(() => {
    if (selectedFilters) {
      let count = 0;

      selectedFilters.map;

      Object.keys(selectedFilters).map((key) => {
        count += selectedFilters[key].length;
      });

      setSelectedFilterCount(count);
    }
  }, [selectedFilters]);

  const isFacetStoreSelected = selectedFilters.facetStore && selectedFilters.facetStore.length > 0;

  return (
    <div
      className={`dsg-flex ${
        isFacetStoreSelected ? 'flex-column' : 'hmf-align-items-center'
      } dsg-mobile-sticky-filters`}>
      <div className="dsg-flex flex-row flex-grow-none">
        <button
          aria-label={`Filter Modal Toggle Button, you have ${selectedFilterCount} filters selected`}
          className="dsg-mobile-sticky-filter-toggle-button"
          tabIndex={0}
          onClick={() => dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal))}
          onKeyPress={(e) =>
            handleADA(e, 'button', () =>
              dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal)),
            )
          }>
          {themeData.icon}
          <div className="rs-filter-text-mobile hmf-label-xs">Filter</div>
          {selectedFilterCount !== 0 ? (
            <span className="filter-count">{selectedFilterCount}</span>
          ) : null}
        </button>
        <div className="dsg-mobile-sticky-filter-divider" />
        {selectedFilters.facetStore && selectedFilters.facetStore.length > 0 ? (
          <StoreDisplay
            selectedStoreName={selectedStoreName}
            toggleMyStoreModal={toggleMyStoreModal}
            facetStore={selectedFilters.facetStore[0]}
          />
        ) : null}
      </div>
      <div
        className={`dsg-flex flex-row flex-grow-none dsg-mobile-sticky-filters-button-group ${
          isFacetStoreSelected && 'hmf-pt-xxs'
        }`}>
        <ShippingMethodButton
          label={'Ship'}
          type="SHIP"
          classes={`${updateFilterOrderEnabled ? 'dsg-rs-flex-order' : ''} ${
            !isFacetStoreSelected ? 'hmf-my-0' : ''
          } ${!SDDEnabled && updateFilterOrderEnabled ? 'hmf-mx-0' : 'hmf-ml-xs'} `}
          selectedShippingMethods={selectedShippingMethods}
          allowDisable={false}
          storeFacet={storeFacet}
        />
        <ShippingMethodButton
          label={'Pickup'}
          type="BOPIS"
          selectedShippingMethods={selectedShippingMethods}
          selectedStoreName={selectedStoreName}
          allowDisable={false}
          storeFacet={storeFacet}
          classes={`${!isFacetStoreSelected ? 'hmf-my-0' : ''}`}
        />
        <ShippingMethodButton
          label={SDDEnabled ? 'Same Day Delivery' : 'Shopping In-Store'}
          type={SDDEnabled ? 'SDD' : 'ISA'}
          selectedShippingMethods={selectedShippingMethods}
          selectedStoreName={selectedStoreName}
          allowDisable={false}
          storeFacet={storeFacet}
          classes={`${!isFacetStoreSelected ? 'hmf-my-0' : ''} ${!SDDEnabled && updateFilterOrderEnabled ? 'hmf-ml-xs dsg-rs-flex-order' : ''}`}
        />
      </div>
    </div>
  );
};

export default MobileStickyFilters;
