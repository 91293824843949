import React from 'react';
import PropTypes from 'prop-types';

const AlertWarningIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8 1.33337C4.32 1.33337 1.33334 4.32004 1.33334 8.00004C1.33334 11.68 4.32 14.6667 8 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 8 1.33337ZM7.33334 11.3334V10H8.66667V11.3334H7.33334ZM7.33334 4.66671V8.66671H8.66667V4.66671H7.33334Z"
        fill="#FFC40C"
        className={pathClasses}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.99334 1.33337C4.31334 1.33337 1.33334 4.32004 1.33334 8.00004C1.33334 11.68 4.31334 14.6667 7.99334 14.6667C11.68 14.6667 14.6667 11.68 14.6667 8.00004C14.6667 4.32004 11.68 1.33337 7.99334 1.33337ZM8.66667 8.66671V4.66671H7.33334V8.66671H8.66667ZM8.66667 11.3334V10H7.33334V11.3334H8.66667ZM2.66667 8.00004C2.66667 10.9467 5.05334 13.3334 8 13.3334C10.9467 13.3334 13.3333 10.9467 13.3333 8.00004C13.3333 5.05337 10.9467 2.66671 8 2.66671C5.05334 2.66671 2.66667 5.05337 2.66667 8.00004Z"
        fill="#191F1C"
        className={pathClasses}
      />
    </svg>
  );
};

export default AlertWarningIcon;

AlertWarningIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

AlertWarningIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
