import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SettingsIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        aria-hidden="true"
        width="12"
        height="12"
        viewBox="0 0 12 12"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0.666992 6.56169L4.32121 6.56169C4.54201 7.21405 5.12815 7.68451 5.82255 7.68451C6.51695 7.68451 7.10308 7.21405 7.32388 6.56169L11.3337 6.56169V5.43888L7.32388 5.43888C7.10308 4.78653 6.51695 4.31607 5.82255 4.31607C5.12815 4.31607 4.54201 4.78653 4.32121 5.43888L0.666992 5.43888V6.56169Z"
          fill="#191F1C"
          className={pathClasses}
        />
        <path
          d="M0.666992 2.35097C0.666992 1.4224 1.38486 0.666748 2.26699 0.666748C2.96139 0.666748 3.54753 1.13721 3.76833 1.78956L11.3337 1.78956V2.91237L3.76833 2.91237C3.54753 3.56473 2.96139 4.03519 2.26699 4.03519C1.38486 4.03519 0.666992 3.27953 0.666992 2.35097Z"
          fill="#191F1C"
          className={pathClasses}
        />
        <path
          d="M8.23233 9.08779H0.666992V10.2106H8.23233C8.45312 10.863 9.03926 11.3334 9.73366 11.3334C10.6158 11.3334 11.3337 10.5778 11.3337 9.64919C11.3337 8.72063 10.6158 7.96498 9.73366 7.96498C9.03926 7.96498 8.45312 8.43543 8.23233 9.08779Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

SettingsIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SettingsIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
