import axios from 'axios';
import { promotionAppKeys, promotionEnvValues } from '../../utility/environments';
import { PROD_PROMOTION_API } from '../../utility/url-utils';
import { setPromotionResponseSuccess, setErrorResponse } from '../slices/common';

export function getPromotionData(catentryIds) {
  return (dispatch) => {
    if (typeof document !== 'undefined') {
      // const PROMOTIONS_API = document.getElementById('DSGFetchPromoESBURLMultiProduct')
      //   ? document.getElementById('DSGFetchPromoESBURLMultiProduct').value
      //   : PROMOTION_API;
      // eslint-disable-next-line no-nested-ternary
      const storeId = document.getElementById('storeId')
        ? document.getElementById('storeId').value
        : typeof window !== 'undefined'
        ? window.storeId
        : '15108';
      const previewTime = document.getElementById('strPreviewTime')
        ? document.getElementById('strPreviewTime').value
        : '';
      const channelId = document.getElementById('channelId')
        ? document.getElementById('channelId').value
        : '-1';

      const requestURL = `${PROD_PROMOTION_API}/${storeId}?catEntryIds=${encodeURIComponent([catentryIds])}&previewTime=${previewTime}&channelId=${channelId}`;

      let apiKey = promotionAppKeys.prod;
      let env = promotionEnvValues.prod;

      const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
      if (host.includes('preview')) {
        env = promotionEnvValues.nonprod;
      }

      // if (
      //   document.getElementById('DSGFetchPromoESBENV') &&
      //   document.getElementById('DSGFetchPromoESBAPI')
      // ) {
      //   env = document.getElementById('DSGFetchPromoESBENV').value;
      //   apiKey = document.getElementById('DSGFetchPromoESBAPI').value;
      // } else {
      //   const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
      //   if (
      //     host.includes('dickssportinggoods') ||
      //     host.includes('dks-auth2') ||
      //     host.includes('calia') ||
      //     host.includes('golfgalaxy') ||
      //     host.includes('fieldandstreamshop') ||
      //     host.includes('vrst') ||
      //     host.includes('publiclands') ||
      //     host.includes('goinggoinggone')
      //   ) {
      //     apiKey = promotionAppKeys.prod;
      //     env = promotionEnvValues.prod;
      //     requestURL = `${PROD_PROMOTION_API}/${storeId}?catentryIds=[${catentryIds}]&previewTime=${previewTime}&channelId=${channelId}`;
      //   } else {
      //     apiKey = promotionAppKeys.nonprod;
      //     env = promotionEnvValues.nonprod;
      //   }
      // }

      return axios(requestURL, {
        method: 'GET',
        headers: {
          Env: env,
          'x-CentraSite-APIKey': apiKey,
        },
      })
        .then((data) => {
          const response = data.data.data ? data.data.data.promoDetailsMap : {};
          dispatch(setPromotionResponseSuccess(response));
        })
        .catch((error) => {
          console.log(`request failed for promotion api...${error.message}`);
          dispatch(setErrorResponse(false));
        });
    }
    return true;
  };
}
