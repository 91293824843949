/* 
  ChangeMyStoreEvent 

  -- Expects --

  Event Name: ChangeMyStoreEvent

  JSON Object of 
  {
    "CollectionCount": "<# of stores>"
    "ZipCode": "<Zip Code Search By Customer>"
  }
*/

const multiUseAnalyticsEvent = (detail) => {
  const evt = new CustomEvent('multiUseAnalyticsEvent', {
    detail,
  });
  document.dispatchEvent(evt);
};

export const changeMyStoreEvent = (collectionCount, zipCode) => {
  const changeMyStoreCustomEvent = new CustomEvent('ChangeMyStoreEvent', {
    detail: {
      CollectionCount: collectionCount,
      ZipCode: zipCode,
    },
  });
  document.dispatchEvent(changeMyStoreCustomEvent);
};

/* 
  ChangePreferredStoreEvent

  -- Expects --

  Event Name: ChangePreferredStore

  JSON Object of 
  {
    "NewStoreID": "<storeId>"
  }
*/
export const changePreferredStoreEvent = (storeId) => {
  const changePreferredStoreCustomEvent = new CustomEvent('ChangePreferredStore', {
    detail: {
      NewStoreID: storeId,
    },
  });
  document.dispatchEvent(changePreferredStoreCustomEvent);
};

export const triggerTargetView = (viewName = 'default-view') => {
  // Validate if the Target Libraries are available on your website
  if (
    window &&
    typeof window.adobe != 'undefined' &&
    window.adobe.target &&
    typeof window.adobe.target.triggerView === 'function'
  ) {
    window.adobe.target.triggerView(viewName);
  }
};

/* 
  sponsoredProductsIntendedEvent

  -- Expects --

  Event Name: multiUseAnalyticsEvent

  JSON Object of 
  {
    "value": "<true/false>"
  }
*/

export const sponsoredProductsIntendedEvent = (value = false) => {
  const detail = {
    event: 'sponsoredProductsIntended',
    value,
  };

  multiUseAnalyticsEvent(detail);
};

export const sponsoredProductsShownEvent = (value = false) => {
  const detail = {
    event: 'sponsoredProductsShown',
    value,
  };

  multiUseAnalyticsEvent(detail);
};

export const RemoveFromFavoritesEvent = (data = '') => {
  const evt = new CustomEvent('RemoveFromFavorites', {
    detail: {
      RemovedFavoriteID: data,
    },
  });
  document.dispatchEvent(evt);
};

export const AddToFavoritesEvent = (data = '') => {
  const evt = new CustomEvent('AddToFavorites', {
    detail: {
      AddedFavoriteID: data,
    },
  });
  document.dispatchEvent(evt);
};

export const signedOutFavoritesEvent = () => {
  const detail = {
    event: 'Signed out athlete clicked Favorites from:',
    value: 'PLP',
  };
  multiUseAnalyticsEvent(detail);
};
