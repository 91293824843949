import { envData, validDsgVhValues } from './environments';
import store from '../reduxStore/store';
import { updateDsgvh } from '../reduxStore/slices/common';
import deviceWidthValues from '../styles/device-width-values.scss';
import { initialState as commonInitialState } from '../reduxStore/slices/common';
import { initialState as inputInitialState } from '../reduxStore/slices/input';

export function getParameterByName(name) {
  if (typeof window !== 'undefined') {
    const url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    const regex = new RegExp(`[?&]${name}(=([^&#]*)|&|#|$)`);
    const results = regex.exec(url);
    if (!results || results.length < 0) return '';
    if (!results || results[2].length < 0) return '';
    return decodeAndReplaceHTML(results[2].replace(/\+/g, ' '));
  }
  return '';
}

export function searchifyTerm(term = '') {
  return term
    .replace(/[!@#$%^&*?"()_=:;,<>\\'`~]+/g, '')
    .replace(/[+]+/g, ' ')
    .trim();
}

export function decodeAndReplaceHTML(str = '') {
  return decodeURIComponent(str).replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
}

export function decodeHTML(decode) {
  // Converts HTML Character Code Names to there normal decoded character
  const textArea = document.createElement('textarea');
  textArea.innerHTML = decode;
  return textArea.value;
}

export function stripSpecialChars(value) {
  if (value) {
    return value.replace(/ |\&|\':|/g, '');
  }
  return '';
}

export function setScrollPosition(xPos, yPos) {
  if (typeof window !== 'undefined') {
    if (document.body) {
      setTimeout(() => {
        document.body.style.height = '';
        window.scrollTo(xPos, yPos);
        document.body.style.height = '100%';
      }, 500);
    }
  }
}

export function getPageType(pageType) {
  let pageTypeUrl = '/f/';
  // Check the page type to set the URL path
  if (pageType === '6') {
    pageTypeUrl = `/c/`;
  } else if (pageType === '2' || pageType === '7') {
    pageTypeUrl = `/f/`;
  } else if (pageType === '1' || pageType === '3' || pageType === '4' || pageType === '5') {
    pageTypeUrl = `/s/`;
  }
  return pageTypeUrl;
}

export function removeSolrURL(filtersApplied) {
  if (typeof window !== 'undefined' && window.location && window.location.href && filtersApplied) {
    const hashIndex = window.location.href.indexOf('#');
    if (hashIndex >= 0) {
      const newHref = window.location.href.substring(0, hashIndex);
      if (window.history) {
        const title = document && document.title ? document.title : undefined;
        const currState = window.history.state;
        window.history.replaceState(currState, title, newHref);
      }
    }
  }
}

export function mapSortValToText(sortVal) {
  sortVal = `${sortVal}`;
  // sort by releavance
  if (sortVal === '0') {
    return 'Most Relevant';
  }
  // Top Sellers
  if (sortVal === '1') {
    return 'Top Sellers';
  }
  // Savings High to Low
  if (sortVal === '2') {
    return 'Savings High To Low';
  }
  // Price Low to high
  if (sortVal === '3') {
    return 'Price Low To High';
  }
  // Price High to Low
  if (sortVal === '4') {
    return 'Price High To Low';
  }
  // Featured Sort
  if (sortVal === '5') {
    return 'Featured Sort';
  }
  // New Products
  if (sortVal === '6') {
    return 'New Products';
  }
  // Brand A-Z
  if (sortVal === '7') {
    return 'Brand A-Z';
  }
  return 'Most Relevant';
}

export function primitiveDeepCopy(obj) {
  if (obj) {
    return JSON.parse(JSON.stringify(obj));
  }
  return {};
}

export function getParameter(param, queryStr) {
  const splitParams = queryStr.split('&');
  const paramIndex = splitParams.findIndex((val) => {
    return val.startsWith(param);
  });

  // Get the facet information while stripping the facet: prefix
  return paramIndex >= 0 ? splitParams[paramIndex].substring(param.length) : '';
}

export function seedParameterData() {
  const seedFiltersFlag = false;

  if (
    typeof window !== 'undefined' &&
    (window.location.href.includes('facet:') ||
      window.location.href.includes('pageSize:') ||
      window.location.href.includes('orderBy:') ||
      window.location.href.includes('minPrice:') ||
      window.location.href.includes('maxPrice:'))
  ) {
    return true;
  }

  const pageNumber = getParameterByName('pageNumber');
  if (pageNumber > 0) {
    return true;
  }
  const pageSize = getParameterByName('pageSize');
  if (pageSize && pageSize != '48') {
    return true;
  }
  const filterFacets = getParameterByName('filterFacets');
  if (filterFacets) {
    return true;
  }
  const selectedSort = getParameterByName('selectedSort');
  if (selectedSort) {
    return true;
  }

  return seedFiltersFlag;
}

export function initStickyClasses(improvedProductGrid) {
  /**
   * Adds a window scroll based event listener to the element that handles applying the sticky class when scrolled tot he top of the viewport.
   * Simply add a class of "dsg-sticky" to any element you want to be sticky.
   * If the element is rendered late you may have to call initStickyClasses() in your component's componentDidMount method to apply the sticky attribute.
   * Otherwise it will get applied when the page is scrolled automatically. Each subsequent element will be offset by the previous.
   */

  document.querySelectorAll('.dsg-sticky').forEach((element, index) => {
    if (!improvedProductGrid) {
      stickyToggle(element, index);
    }
  });
  document.querySelectorAll('.hidden-sticky').forEach((element, index) => {
    filterStickyToggle(element);
  });

  window.addEventListener('scroll', function (e) {
    document.querySelectorAll('.dsg-sticky').forEach((element, index) => {
      improvedProductGrid ? improvedStickyToggle(element, index) : stickyToggle(element, index);
    });
    document.querySelectorAll('.hidden-sticky').forEach((element) => {
      filterStickyToggle(element);
    });
  });

  window.addEventListener('resize', function (e) {
    document.querySelectorAll('.dsg-sticky').forEach((element, index) => {
      improvedProductGrid ? improvedStickyToggle(element, index) : stickyToggle(element, index);
    });
    document.querySelectorAll('.hidden-sticky').forEach((element) => {
      filterStickyToggle(element);
    });
  });
}

export function stickyToggle(element, index) {
  // Get client height of the filter top header used to offset the sticky of the side filter facets
  const stickyTopSortContainer = document.querySelectorAll('.dsg-sticky')[0].clientHeight;

  const stickyAnchorDiv = document.querySelector('#dsg-react-sticky-anchor');
  const stickyGhostDiv = document.querySelector('#dsg-react-sticky-ghost-div');
  const productCardContainer = document.querySelector('.rs_card_container');

  const containerSearch = document.querySelector('div.dsg-header-search-sticky-enabled');

  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
  const innerWidth = window.innerWidth;

  const mobileOffset = innerWidth <= 1023 && containerSearch ? 60 : 0; // offset for sticky search bar

  // When the sticky anchor div is outside the bounding client rect make the elements sticky
  if (scrollTop + mobileOffset > stickyAnchorDiv.offsetTop) {
    // Offset the height the side filter facet sticky by the filter top header
    if (index !== 0) {
      element.style.top = `${stickyTopSortContainer}px`;
    }

    // Add the sticky class to the element
    element.classList.add('sticky');

    if (mobileOffset && containerSearch) {
      element.classList.add('headerStickyOffset');
    } else {
      element.classList.remove('headerStickyOffset');
    }

    // Set the height of the ghost div to match the rendered height of the filter top header
    stickyGhostDiv.style.height = `${stickyTopSortContainer}px`;

    // Get the client height and the product card containers current bounding client rect bottom
    const { clientHeight } = document.documentElement;
    const productCardContainerBottom = productCardContainer.getBoundingClientRect().bottom;

    // // Check if the client height is greater than the current bounding client rect of the product card bottom measurement
    // if (clientHeight > productCardContainerBottom) {
    //   // If true, use the values to force shrink the side filter facet sticky to the available screen space
    //   document.querySelectorAll('.dsg-sticky')[1].style.bottom = `${clientHeight -
    //     productCardContainerBottom}px`;
    // } else {
    //   // Otherwise, reset to normal size
    //   document.querySelectorAll('.dsg-sticky')[1].style.bottom = 0;
    // }

    // When the product card container is off the screen hide the filter top header
    if (productCardContainerBottom < 0 && document.querySelectorAll('.dsg-sticky')[0]) {
      document.querySelectorAll('.dsg-sticky')[0].style.visibility = 'hidden';
    } else if (document.querySelectorAll('.dsg-sticky')[0]) {
      document.querySelectorAll('.dsg-sticky')[0].style.visibility = 'visible';
    }
  } else {
    // Case where the element should no longer be sticky we need to reset things and remove the sticky class

    // Reset to height offset
    if (index !== 0) {
      element.style.top = 0;
    }

    // Remove the sticky class from the element
    element.classList.remove('sticky');

    element.classList.remove('headerStickyOffset');

    // Set the height of the ghost div back to zero to take up no height
    stickyGhostDiv.style.height = '0px';
    // stickyGhostDiv.style.display = "none";
  }
}

export function improvedStickyToggle(element, index) {
  // Get client height of the filter top header used to offset the sticky of the side filter facets
  // const stickyTopSortContainer = document.querySelectorAll('.dsg-sticky')[0].clientHeight;

  // Set some other variables to hold the sticky anchor div and the product card container
  const stickyAnchorDiv = document.querySelector('#dsg-react-sticky-anchor');
  const productCardContainer = document.querySelector('.rs_card_container');
  const filterGhost = document.querySelector('#dsg-react-mobile-shipping-options-ghost-div');

  const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;

  const innerWidth = window.innerWidth;

  const mobileOffset =
    innerWidth <= 1023 && containerSearch ? 68 : 0; // offset for sticky search bar

  const storeShownOffset = document.querySelector('.visible-store-selection') ? '119px' : '79px';
  // When the sticky anchor div is outside the bounding client rect make the elements sticky
  if (scrollTop + mobileOffset > stickyAnchorDiv.offsetTop) {
    // Add the sticky class to the element
    element.classList.add('sticky');
    if (filterGhost) {
      // TODO: different heights depending on filter selection
      filterGhost.style.height = storeShownOffset;
      filterGhost.style.width = '100vw';
    }

    // Get the client height and the product card containers current bounding client rect bottom
    const { clientHeight } = document.documentElement;
    const productCardContainerBottom = productCardContainer.getBoundingClientRect().bottom;

    // Check if the client height is greater than the current bounding client rect of the product card bottom measurement
    if (clientHeight > productCardContainerBottom && document.querySelectorAll('.dsg-sticky')[0]) {
      // If true, use the values to force shrink the side filter facet sticky to the available screen space
      document.querySelectorAll('.dsg-sticky')[0].style.bottom = `${clientHeight -
        productCardContainerBottom}px`;
    } else if (document.querySelectorAll('.dsg-sticky')[0]) {
      // Otherwise, reset to normal size
      document.querySelectorAll('.dsg-sticky')[0].style.bottom = 0;
    }

    // When the product card container is off the screen hide the filter top header
    if (productCardContainerBottom < 0 && document.querySelectorAll('.dsg-sticky')[0]) {
      document.querySelectorAll('.dsg-sticky')[0].style.visibility = 'hidden';
    } else if (document.querySelectorAll('.dsg-sticky')[0]) {
      document.querySelectorAll('.dsg-sticky')[0].style.visibility = 'visible';
    }
  } else {
    // Case where the element should no longer be sticky we need to reset things and remove the sticky class

    if (filterGhost) {
      filterGhost.style.height = '0px';
      filterGhost.style.width = '0px';
    }

    // Remove the sticky class from the element
    element.classList.remove('sticky');
  }
}

export function filterStickyToggle(element) {
  const mobileSearchBar = containerSearch;

  if (element) {
    if (mobileSearchBar && mobileSearchBar.classList.contains('sticky')) {
      mobileSearchBar.classList.add('sharing-row');
      element.classList.add('sticky');
      return;
    }

    element.classList.remove('sticky');
    if (mobileSearchBar) {
      mobileSearchBar.classList.remove('sharing-row');
    }
  }
}

export function getBreadcrumbContainerTop() {
  const el = document.getElementsByClassName('rs-breadcrumb-wrapper')[0];
  const rect = !el ? { top: 0, left: 0 } : el.getBoundingClientRect();
  const scrollLeft =
    (typeof window !== 'undefined' && window.pageXOffset) || document.documentElement.scrollLeft;
  const scrollTop =
    (typeof window !== 'undefined' && window.pageYOffset) || document.documentElement.scrollTop;
  return { top: rect.top + scrollTop, left: rect.left + scrollLeft };
}

export function toggleOpenCloseFacet(identifier) {
  if (typeof document !== 'undefined' && document.getElementById(`facet_list_${identifier}`)) {
    if (document.getElementById(`facet_list_${identifier}`).style.display == 'none') {
      document.getElementById(`facet_list_${identifier}`).style.display = 'block';
      // document.getElementById(`img_expand_collapse_${identifier}`).src = expandLess;
      document.getElementById(`facet_heading_${identifier}`).className =
        'rs-facet-heading  focus-border expanded';
    } else {
      document.getElementById(`facet_list_${identifier}`).style.display = 'none';
      // document.getElementById(`img_expand_collapse_${identifier}`).src = expandMore;
      document.getElementById(`facet_heading_${identifier}`).className =
        'rs-facet-heading  focus-border';
    }
  }
}

export function imagineUrlifyFaceOutImage(value) {
  // This function accepts a string and first replaces any whitespace character with an underscore ( _ ).
  // Next the function looks for places where there are more than one underscore ( _ ) directly beside to each other it removes the extra underscores.
  return value.replace(/[^\w]/gi, '_').replace(/(_+)\1+/g, '$1');
}

export function getFaceoutThumbnailBaseUrl(storeId) {
  if (storeId === envData.dsg.storeId) {
    return 'https://www.dickssportinggoods.com/wcsstore/DicksSportingGoods/';
  }
  if (storeId === envData.gg.storeId) {
    return 'https://www.golfgalaxy.com/wcsstore/GolfGalaxy/';
  }
  if (storeId === envData.fns.storeId) {
    return 'https://www.fieldandstreamshop.com/wcsstore/FieldAndStream/';
  }
  return 'https://www.dickssportinggoods.com/wcsstore/DicksSportingGoods/';
}

export function text_truncate(str, length) {
  const dots = str.length > length ? '...' : '';
  return str.substring(0, length) + dots;
}

export function getLabelFormat(breakpoint, value, charLength = 23, maxCharLength = 32) {
  let label;

  let titlelabel;
  if (breakpoint === 'desktop' && value.length > charLength) {
    label = text_truncate(value, charLength);
    titlelabel = value;
  } else if (breakpoint === 'mobile' && value.length > charLength) {
    if (document.documentElement.clientWidth <= 370) {
      label = text_truncate(value, charLength);
      titlelabel = value;
    } else if (document.documentElement.clientWidth <= 767) {
      label = text_truncate(value, maxCharLength);
    } else {
      label = value;
    }
    titlelabel = value;
  } else {
    label = value;
  }
  return { labelName: label, titlelabel };
}

export function setDsgVhInitialState() {
  // Get initial state from redux store to hijack and add dsgVh header to
  const initialState = { common: { ...commonInitialState }, input: { ...inputInitialState } };

  // If window & window.dsgVh are set match the window.dsgVh value to the valid array if there is a match add the dsgVh header to the redux stores initial state
  if (window && window.dsgVh) {
    const { dsgVh } = window;
    delete window.dsgVh;

    const dsgVhMatch = validDsgVhValues.some((value) => value === dsgVh);
    const storeIdentifierName = getStoreIdentifierNameFromVh(dsgVh);

    if (dsgVhMatch) {
      initialState.common.dsgVh = dsgVh;
      initialState.common.storeIdentifierName = storeIdentifierName;
    }
  }

  initialState.common.audiencePools = getAudiencePools();

  return initialState;
}

export function getStoreIdentifierNameFromVh(virtualHostValue) {
  switch (virtualHostValue) {
    case '111':
    case '112':
      return 'dsg';
    case '121':
    case '125':
      return 'gg';
    case '131':
    case '132':
      return 'fns';
    case '141':
    case '142':
      return 'calia';
    case '151':
    case '152':
      return 'vrst';
    case '161':
    case '162':
      return 'pl';
    case '181':
    case '182':
      return 'mj';  
    case '171':
    case '172':
      return 'g3';
    default:
      return 'dsg';
  }
}

// This function is to get an array within an array. This is for handling V1 of the container
export function unwrapArrayOfArray(nestedArray) {
  if (nestedArray) {
    if (Array.isArray(nestedArray) && Array.isArray(nestedArray[0])) {
      return nestedArray[0];
    }
    return nestedArray;
  }
}

// This function is to get an object within an array. This is for handling V1 of the container
export function unwrapArray(nestedArray) {
  if (nestedArray && Array.isArray(nestedArray)) {
    return nestedArray[0];
  }
  return nestedArray;
}

// This function returns the page type by looking at the url (Used for Homr Calls)

export function getHomrPageType(isFamilyPage, serverSeoUrl = false) {
  // Set page type to 'default'
  let pageType = 'default';

  if (!isFamilyPage) {
    // It's a search page set page type to 'search'
    pageType = 'search';
  } else if (isFamilyPage) {
    if (serverSeoUrl) {
      pageType = serverSeoUrl;
    } else {
      // It's a client side family page set page type to a cleaned /f/ page url
      const splitPath = window && window.location && window.location.pathname.split('/');
      pageType = splitPath.length > 0 ? splitPath[splitPath.length - 1] : 'default';
    }
  }

  return pageType;
}

export function handleADA(event, accessibilityType, callback) {
  if (event instanceof KeyboardEvent || event.key || event.keyCode) {
    switch (accessibilityType) {
      case 'Link':
      case 'link':
        if (event.key === 'Enter' || event.keyCode === 13) {
          callback(event);
          break;
        }
        break;
      case 'Button':
      case 'button':
        if (
          event.key === 'Enter' ||
          event.keyCode === 13 ||
          event.key === ' ' ||
          event.keyCode === 32
        ) {
          callback(event);
          break;
        }
        break;
      case 'Checkbox':
      case 'checkbox':
        if (event.key === ' ' || event.keyCode === 32) {
          callback(event);
          break;
        }
        break;
      default:
        break;
    }
  }
}

export function isBetween(x, min, max) {
  return x >= min && x <= max;
}

/**
 * Formats the time from a 4 digit 24 hour format to the 12 hour format
 * @param {string} time
 */
export const formatTime = (time) => {
  const hours = time.slice(0, 2);
  const hoursNum = +hours;
  const minutes = time.slice(2);
  const period = hoursNum < 12 ? 'am' : 'pm';
  const hoursText = hoursNum > 12 ? hoursNum - 12 : hoursNum;

  return `${hoursText}:${minutes} ${period}`;
};

/**
 * Formats a string to be in title case
 * @param {string} text
 */
export const formatTitleCase = (text = '') => {
  if (!text) {
    return '';
  }
  return text.replace(/(\w)(\w*)/g, (g0, g1, g2) => {
    return g1.toUpperCase() + g2.toLowerCase();
  });
};

/**
 * Decodes an html string to remove special character entities.
 * @param {string} str
 */
export const decode = (str) => {
  return str.replace(/&#(\d+);/g, (match, dec) => {
    return String.fromCharCode(dec);
  });
};

export const getTopTenEcodeArray = (productVOs, productDataSource) => {
  if (!productVOs || productDataSource === 'server') {
    return null;
  }

  const topTenProducts = productVOs
    .map((item) => {
      return item.parentPartnumber;
    })
    .slice(0, 10);

  return topTenProducts;
};

/*
 * Simple debounce function that you can pass any function as the first parameter and a wait time in milliseconds as the second parameter.
 * Useful in cases where user interaction is able to call an API and you want to slow down requests or even slowing or delaying certain function calls.
 */
export const debounce = (func, wait) => {
  let timeout;
  return function (...args) {
    const context = this;
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      timeout = null;
      func.apply(context, args);
    }, wait);
  };
};

export const getSearchSNBAudience = () => {
  if (SNB_AUDIENCE) {
    return SNB_AUDIENCE;
  }

  return '';
};

export const getAudiencePools = () => {
  let audiencePools = [];

  if (
    window &&
    window._dsgTag &&
    window._dsgTag.customerLanes &&
    window._dsgTag.customerLanes.length
  ) {
    audiencePools = window._dsgTag.customerLanes;
  }
  return audiencePools;
};

export const getPreview = () => {
  return window && window.preview ? window.preview : '';
};

// Handles checking if visualAssets exists on the homr response and creates an object to return in a similar format for both self hosted and scene7 routes
export const getImageDataFromHomrResponse = (homrResponse) => {
  const imageData = {
    desktop: { imageURL: null, gifURL: null, altText: null },
    mobile: { imageURL: null, gifURL: null, altText: null },
  };

  if (
    homrResponse &&
    homrResponse.visualAssets &&
    homrResponse.visualAssets[0] &&
    homrResponse.visualAssets[0].length > 0
  ) {
    const desktopVisualAsset = homrResponse.visualAssets[0].find(
      (element) => element.type === 'imageUpload',
    );

    if (desktopVisualAsset) {
      imageData.desktop.imageURL = desktopVisualAsset.value ? desktopVisualAsset.value : null;
      imageData.desktop.altText = desktopVisualAsset.altText ? desktopVisualAsset.altText : null;
    }

    const mobileVisualAsset = homrResponse.visualAssets[0].find(
      (element) => element.type === 'mobileImageUpload',
    );

    if (mobileVisualAsset) {
      imageData.mobile.imageURL = mobileVisualAsset.value ? mobileVisualAsset.value : null;
      imageData.mobile.altText = mobileVisualAsset.altText ? mobileVisualAsset.altText : null;
    }

    const desktopAnimatedVisualAsset = homrResponse.visualAssets[0].find(
      (element) => element.type === 'animationUpload',
    );

    if (desktopAnimatedVisualAsset) {
      imageData.desktop.gifURL = desktopAnimatedVisualAsset.value
        ? desktopAnimatedVisualAsset.value
        : null;
      imageData.desktop.altText = desktopAnimatedVisualAsset.altText
        ? desktopAnimatedVisualAsset.altText
        : null;
    }

    return imageData;
  }

  // If no visualAssets default to old scene7 data approach
  imageData.desktop.imageURL = homrResponse.image
    ? `https://dks.scene7.com/is/image/dksfed/${homrResponse.image}`
    : null;
  imageData.desktop.altText = homrResponse.altText ? homrResponse.altText : null;
  imageData.mobile.imageURL = homrResponse.mobileImg
    ? `https://dks.scene7.com/is/image/dksfed/${homrResponse.mobileImg}`
    : null;
  imageData.mobile.altText = homrResponse.altText ? homrResponse.altText : null;
  imageData.desktop.gifURL = homrResponse.gif
    ? `https://dks.scene7.com/is/content/dksfed/${homrResponse.gif}`
    : null;
  imageData.mobile.gifURL = homrResponse.gifMobile
    ? `https://dks.scene7.com/is/content/dksfed/${homrResponse.gifMobile}`
    : null;

  return imageData;
};

export const isProdURL = () => {
  if (typeof window !== 'undefined') {
    const URL = window.location.href;

    // Includes zeta.dickssportinggoods.com as a prod
    if (
      URL.indexOf('dickssportinggoods.com') > -1 ||
      URL.indexOf('golfgalaxy.com') > -1 ||
      URL.indexOf('fieldandstreamshop.com') > -1 ||
      URL.indexOf('calia.com') > -1 ||
      URL.indexOf('vrst.com') > -1 ||
      URL.indexOf('publiclands.com') > -1 ||
      URL.indexOf('moosejaw.com') > -1 ||
      URL.indexOf('goinggoinggone.com') > -1
    ) {
      return true;
    }
    return false;
  }
  return false;
};

export const deepCompareWithStringify = (varOne, varTwo) => {
  // Deep comparison function to determine equality of two values. Uses JSON.stringify() to compare as strings.
  return JSON.stringify(varOne) === JSON.stringify(varTwo);
};

const pxPlusOne = (pxNum) => `${parseInt(pxNum) + 1}px`;

export const getBreak = () => {
  if (typeof window !== 'undefined' && window.matchMedia) {
    if (window.matchMedia(`(max-width: ${deviceWidthValues.mobile})`).matches) return 'mobile';

    if (
      window.matchMedia(
        `(max-width: ${deviceWidthValues.tablet}) and (min-width: ${pxPlusOne(
          deviceWidthValues.mobile,
        )})`,
      ).matches
    )
      return 'tablet';

    if (
      window.matchMedia(
        `(max-width: ${deviceWidthValues.smallDesktop}) and (min-width: ${pxPlusOne(
          deviceWidthValues.tablet,
        )})`,
      ).matches
    )
      return 'small-desktop';

    return 'desktop';
  }
  return 'desktop';
};

export function getCategoryTypeAsString(categoryType) {
  let catTypeString = '';
  if (categoryType) {
    if (Array.isArray(categoryType)) {
      catTypeString = categoryType[0];
    } else {
      catTypeString = categoryType;
    }
  }
  return catTypeString;
}

export const getUserToken = () => {
  if (typeof window !== 'undefined' && window.authFunctions) {
    return window.authFunctions.getTokens();
  }

  return undefined;
};

export const getIsValidHost = () => {
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
  const isValidHost =
    host.includes('dickssportinggoods.com') ||
    host.includes('golfgalaxy.com') ||
    host.includes('calia.com') ||
    host.includes('vrst.com') ||
    host.includes('publiclands.com') ||
    host.includes('moosejaw.com') ||
    host.includes('goinggoinggone.com');
  return isValidHost;
};
