import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  isFamilyPageSelector,
  selectedSortSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import PLChevronIcon from '../../common/SVGIcons/PublicLands/PLChevronIcon';
import SmallChevonIcon from '../../common/SVGIcons/SmallChevonIcon';
import SortingIcon from '../../common/SVGIcons/SortingIcon';
import { default as CaliaSmallChevonIcon } from '../../common/SVGIcons/Calia/SmallChevronIcon';
import { default as CaliaSortingIcon } from '../../common/SVGIcons/Calia/SortingIcon';
import { updateResultsManipulators } from '../../../utility/history';
import { onSortData } from '../../../reduxStore/thunks/index';
import { getParameterByName } from '../../../utility/utils';
import MJChevronIcon from '../../common/SVGIcons/MooseJaw/MJChevronIcon';

/** Serach Result drop down values order */
const dropdownValues = [
  { value: 0, text: 'Most Relevant' },
  { value: 1, text: 'Top Sellers' },
  { value: 2, text: 'Savings High to Low' },
  { value: 3, text: 'Price Low to High' },
  { value: 4, text: 'Price High to Low' },
  { value: 6, text: 'New Products' },
  { value: 7, text: 'Brand (A-Z)' },
];

/** family page drop down values order */
const familyPageDropdownValues = [
  { value: 0, text: 'Most Relevant' },
  { value: 1, text: 'Top Sellers' },
  { value: 2, text: 'Savings High to Low' },
  { value: 3, text: 'Price Low to High' },
  { value: 4, text: 'Price High to Low' },
  { value: 5, text: 'Featured' },
  { value: 6, text: 'New Products' },
  { value: 7, text: 'Brand (A-Z)' },
];

const Sorting = () => {
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const selectedSort = useSelector(selectedSortSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const [expanded, setExpanded] = useState(false);
  const [values, setValues] = useState(isFamilyPage ? familyPageDropdownValues : dropdownValues);
  const [index, setIndex] = useState(values.findIndex((value) => selectedSort === value.value));
  const [selectedOption, setSelectedOption] = useState(
    index >= 0 ? values[index] : values[0] || { value: '', text: '' },
  );
  const node = useRef<HTMLDivElement>(null);
  const dispatch = useDispatch();

  const onSort = (value) => {
    if (value >= 0) {
      dispatch(onSortData(value));
      const resultsManipulators = [
        {
          identifier: 'selectedSort',
          value,
        },
        {
          identifier: 'pageNumber',
          value: 0,
        },
      ];
      updateResultsManipulators(resultsManipulators, false);
    }
  };

  /**
   * Used to toggle the dropdown being open or closed
   */
  const toggleExpand = (e) => {
    e.preventDefault();
    setExpanded(!expanded);
  };

  /** Handles click events for on/off the dropdown list */
  const handleClick = (e) => {
    if (
      node.current?.contains(e.target) ||
      (e.target.parentElement && e.target.parentElement.id === 'dsg-react-sort-button') ||
      e.target.id === 'dsg-react-sort-button'
    ) {
      if (
        e.target.parentElement.id === 'dsg-react-sort-button' ||
        e.target.id === 'dsg-react-sort-button'
      ) {
        setExpanded(true);
      }
      return;
    }
    handleClickOutside();
  };

  /**
   * Sets the expanded state to false, called when a click event's target is not our component or children of our component
   */
  const handleClickOutside = () => {
    setExpanded(false);
  };

  const createSortingIcon = () => {
    switch (storeIdentifierName) {
      case 'calia':
        return <CaliaSortingIcon />;
      case 'pl':
      case 'mj':  
        return null;
      default:
        return <SortingIcon />;
    }
  };

  const createChevronIcon = () => {
    switch (storeIdentifierName) {
      case 'calia':
        return (
          <CaliaSmallChevonIcon
            classes={[expanded ? 'dsg-react-sort-icon-up' : 'dsg-react-sort-icon-down']}
          />
        );
      case 'mj': return (
        <MJChevronIcon
          classes={[expanded ? 'dsg-react-sort-icon-up' : 'dsg-react-sort-icon-down']}
        />
      );
      case 'pl':  
        return (
          <PLChevronIcon
            classes={[expanded ? 'dsg-react-sort-icon-up' : 'dsg-react-sort-icon-down']}
          />
        );
      default:
        return (
          <SmallChevonIcon
            classes={[expanded ? 'dsg-react-sort-icon-down' : 'dsg-react-sort-icon-up']}
          />
        );
    }
  };

  /**
   * Generates the list items for the dropdown
   */
  const generateList = () => {
    // Generate a list item for the dropdown for each value passed in
    const listItems = values.map((valueObj) => {
      // Create a callback function for clicking each item
      const clickFunc = () => {
        setSelectedOption(valueObj);
        setExpanded(false);
        onSort(valueObj.value);
      };

      const handleKeypress = (event) => {
        if (event.key === 'Enter' || event.keyCode === '13') {
          clickFunc();
        }
      };

      // Determine the class (selected or not selected) for each item
      const className = valueObj.value === selectedOption.value ? 'dsg-react-selected-sort' : '';
      return (
        <li
          className={`${className} dsg-react-selectoption-${valueObj.text
            .toLowerCase()
            .replace(/ /g, '')}`}
          key={valueObj.text}
          onClick={clickFunc}
          onKeyPress={handleKeypress}
          tabIndex={0}>
          <div className="dsg-flex">
            <div className="col-width-100 dsg-react-sort-by-value-text">{valueObj.text}</div>
          </div>
        </li>
      );
    });

    return (
      <ul className="dsg-react-sort-list" style={{ display: expanded ? 'block' : 'none' }}>
        {listItems}
      </ul>
    );
  };

  /**
   * When the selected value changes, update the state selected option
   */
  useEffect(() => {
    setIndex(
      values.findIndex((value) => {
        return selectedSort === value.value;
      }),
    );
  }, [selectedSort]);

  useEffect(() => {
    if (index >= 0) {
      setSelectedOption(values[index]);
    }
  }, [index]);

  useEffect(() => {
    document.addEventListener('click', (e) => {
      handleClick(e);
    });
    return document.removeEventListener('click', (e) => {
      handleClick(e);
    });
  }, []);

  return (
    <div className="dsg-react-sorting-container dsg-flex">
      <div className={`dsg-react-sorting-component ${expanded ? 'expanded' : ''}`}>
        <div
          className={
            expanded ? 'dsg-react-sorting focus-border expanded' : 'dsg-react-sorting focus-border'
          }
          ref={node}
          onMouseDown={(e) => {
            e.preventDefault();
          }}
          onClick={toggleExpand}
          onKeyPress={toggleExpand}
          tabIndex={0}
          role="button">
          <div className="dsg-react-sorting-icon-text">
            <div className={expanded ? 'dsg-react-sort-svg expanded' : 'dsg-react-sort-svg'}>
              {createSortingIcon()}
            </div>
            <div className="dsg-react-sort-text">
              <span>Sort: </span>
              <span className="dsg-react-selected-sort-text">
                {selectedOption ? selectedOption.text : ''}
              </span>
            </div>
          </div>
          <div className="dsg-react-sort-opn-close-icon">{createChevronIcon()}</div>
        </div>
        {generateList()}
      </div>
    </div>
  );
};

export default Sorting;
