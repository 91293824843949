import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ShipIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="14"
        height="11"
        viewBox="0 0 14 11"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M0.875 2.66665H8.45833V8.49998H5.54167C5.54167 9.46831 4.76 10.25 3.79167 10.25C2.82333 10.25 2.04167 9.46831 2.04167 8.49998H0.875V2.66665ZM3.79167 7.91665C3.47083 7.91665 3.20833 8.17915 3.20833 8.49998C3.20833 8.82081 3.47083 9.08331 3.79167 9.08331C4.1125 9.08331 4.375 8.82081 4.375 8.49998C4.375 8.17915 4.1125 7.91665 3.79167 7.91665ZM6.2583 5.30418L4.78743 3.83331L4.37496 4.24579L5.12916 4.99998H3.20833V5.58331H5.15462L4.375 6.36293L4.78747 6.77541L6.2583 5.30418Z"
          fill="#006554"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.04167 8.49998V3.83331H11.9583C12.6027 3.83331 13.125 4.35565 13.125 4.99998V8.49998H12.5417C12.5417 9.46831 11.76 10.25 10.7917 10.25C9.82333 10.25 9.04167 9.46831 9.04167 8.49998ZM10.7917 9.08331C10.4708 9.08331 10.2083 8.82081 10.2083 8.49998C10.2083 8.17915 10.4708 7.91665 10.7917 7.91665C11.1125 7.91665 11.375 8.17915 11.375 8.49998C11.375 8.82081 11.1125 9.08331 10.7917 9.08331Z"
          fill="#006554"
        />
        <path d="M4.375 0.333313H2.04167L0.875 2.08331H4.375V0.333313Z" fill="#006554" />
        <path d="M4.95833 2.08331H8.45833L7.29167 0.333313H4.95833V2.08331Z" fill="#006554" />
      </svg>
    );
  }
}

ShipIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

ShipIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
