import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { onChangePage } from '../../../reduxStore/thunks/index';
import { updateResultsManipulators } from '../../../utility/history';
import { ChevronRight,ChevronLeft } from '@dsg-tech/homefield-react-icons';

class Pager extends React.PureComponent {
  static calculateDisplayPages(currentPage, totalPages) {
    const numOptionsToShow = 4;
    let firstGroupMember = 1;
    for (let i = 1; i <= totalPages; i += numOptionsToShow) {
      if (i <= currentPage && currentPage < i + numOptionsToShow) {
        firstGroupMember = i;
      }
    }
    const potentialPages = [
      firstGroupMember,
      firstGroupMember + 1,
      firstGroupMember + 2,
      firstGroupMember + 3,
    ];
    return potentialPages.filter((pageNum) => pageNum <= totalPages);
  }

  onPageChange = (pageNumber) => {
    const { data } = this.props;
    if (data.pageNumber === pageNumber - 1) return;
    this.props.onChangePage(pageNumber - 1);
    updateResultsManipulators([{ identifier: 'pageNumber', value: pageNumber - 1 }]);
  };

  renderPageItem(pageNum, currentPageNumber) {
    let activeClass = '';
    if (pageNum === currentPageNumber) {
      activeClass = 'active';
    }
    return (
      <div className="hmf-display-flex hmf-justify-content-center hmf-align-items-center rs-item-pagination">
        <div
          className={`hmf-display-flex hmf-flex-column rs-page-item-bubble hmf-display-inline-block ${activeClass}`}
          key={`rs-page-number-${pageNum}`}>
          <a
            className={`${activeClass} rs-page-item`}
            onClick={() => this.onPageChange(pageNum)}
            onKeyPress={(e) => {
              handleADA(e, 'button', () => this.onPageChange(pageNum));
            }}
            tabIndex={0}
            role="button"
            title={`Page ${pageNum}`}>
            {pageNum}
          </a>
        </div>
      </div>
    );
  }

  renderDecrease(prevPageNum) {
    return (
      <a
        className="hmf-display-flex hmf-justify-content-space-between hmf-align-items-center hmf-flex-row rs-page-item"
        onClick={() => this.onPageChange(prevPageNum)}
        onKeyPress={(e) => {
          handleADA(e, 'button', () => this.onPageChange(prevPageNum));
        }}
        tabIndex={0}
        role="button"
        title={`Previous Page ${prevPageNum}`}>
        <div className="hmf-display-flex hmf-justify-content-center hmf-align-items-center rs-item-pagination">
          <ChevronLeft className="rs-size-chevron" />
        </div>
        <span className="rs-prev-item" />
      </a>
    );
  }

  renderIncrease(nextPageNum) {
    return (
      <a
        className="hmf-display-flex hmf-justify-content-space-between hmf-align-items-center hmf-flex-row rs-page-item"
        onClick={() => this.onPageChange(nextPageNum)}
        onKeyPress={(e) => {
          handleADA(e, 'button', () => this.onPageChange(nextPageNum));
        }}
        tabIndex={0}
        role="button"
        title={`Next Page ${nextPageNum}`}>
        <span className="rs-next-item" />
        <div className="hmf-display-flex hmf-justify-content-center hmf-align-items-center rs-item-pagination">
          <ChevronRight className="rs-size-chevron" />
        </div>
      </a>
    );
  }

  render() {
    const { data } = this.props;
    const totalPages = Math.ceil(parseFloat(data.totalCount / data.pageSize));
    const currentPageNumber = data.pageNumber + 1;
    const displayPages = Pager.calculateDisplayPages(currentPageNumber, totalPages);
    return (
      <div className="rs-bottom-pagination">
        <div className="rs-pagination-wrapper hmf-display-flex hmf-text-align-center">
          {currentPageNumber > 1 ? this.renderDecrease(currentPageNumber - 1) : null}
          {displayPages[0] > 1 ? <span className="rs-pagination-separator">...</span> : null}
          {displayPages.map((pageNum) => this.renderPageItem(pageNum, currentPageNumber))}
          {displayPages[displayPages.length - 1] < totalPages ? (
            <span className="rs-pagination-separator">...</span>
          ) : null}
          {currentPageNumber < totalPages ? this.renderIncrease(currentPageNumber + 1) : null}
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return bindActionCreators(
    {
      onChangePage: (pageNumber) => onChangePage(pageNumber),
    },
    dispatch,
  );
};

const mapStateToProps = (state) => {
  return {
    data: state.input ? state.input : {},
    breakpoint: state.common && state.common.breakpoint ? state.common.breakpoint : 'desktop',
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Pager);
