import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../types';

export const breakpointSelector = (state: ReduxState) =>
  state?.common?.breakpoint ? state.common.breakpoint : 'desktop';
export const dsgVhSelector = (state: ReduxState) => state.common.dsgVh;
export const storeIdentifierNameSelector = (state: ReduxState) => state.common.storeIdentifierName;
export const categoryAttrVoDataSelector = (state: ReduxState) => state.common.categoryAttrVoData;
export const categoryIdentifierSelector = (state: ReduxState) => state.common.categoryIdentifier;
export const catTypeStringSelector = (state: ReduxState) => state.common.catTypeString;
export const isSearchingSelector = (state: ReduxState) => state.common.isSearching;
export const errorSelector = (state: ReduxState) => state.common.error;
export const seoFiltersAppliedSelector = (state: ReduxState) =>
  state?.common?.seoFiltersApplied ? state.common.seoFiltersApplied : false;
export const showMobileFilterFacetModalSelector = (state: ReduxState) =>
  state.common.showMobileFilterFacetModal;
export const defaultSortSelector = (state: ReduxState) => state.common.defaultSort;
export const myStoreModalSelector = (state: ReduxState) => state.common.myStoreModal;
export const catgroupIdSelector = (state: ReduxState) =>
  state.common.catgroupId ? state.common.catgroupId : null;
export const selectedSwatchValSelector = (state: ReduxState) =>
  state.common.selectedSwatchVal ? state.common.selectedSwatchVal : '';
export const promotionDataSelector = (state: ReduxState) =>
  state.common.promotionData ? state.common.promotionData : null;
export const itemAvailabilitySelector = (state: ReduxState) =>
  state.common.itemAvailability ? state.common.itemAvailability : null;
export const pinnedContentSelector = (state: ReduxState) => state.common.pinnedContent;
export const breadCrumbDataSelector = (state: ReduxState) =>
  state.common.breadCrumbData ? state.common.breadCrumbData : [];
export const pageTitleSelector = (state: ReduxState) => state.common.pageTitle;
export const productDataSourceSelector = (state: ReduxState) => state.common.productDataSource;
export const familyPageNameSelector = (state: ReduxState) => state.common.familyPageName;
export const piqProductsSelector = (state: ReduxState) => state.common.piqProducts;
export const featureFlagsSelector = (state: ReduxState) => state.common.featureFlags;
export const windowVariablesSelector = (state: ReduxState) => state.common.windowVariables;
export const mlSearchUUIDSelector = (state: ReduxState) => state.common.mlSearchUUID;
export const getFeatureFlagByNameSelector = (value: string) =>
  createSelector(
    featureFlagsSelector,
    windowVariablesSelector,
    storeIdentifierNameSelector,
    (featureFlags, windowVariables, storeIdentifierName) => {
      return !!featureFlags[value][storeIdentifierName] || !!windowVariables[value];
    },
  );
export const isAdSpotsReadySelector = (state: ReduxState) => state.common.isAdSpotsReady;  
