import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class BopisIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path d="M4.70842 0.041687H1.20841L0.041748 1.79169H4.70842V0.041687Z" fill="#006554" />
        <path d="M5.29175 1.79169H9.95842L8.79175 0.041687H5.29175V1.79169Z" fill="#006554" />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.95842 2.37502H0.041748V9.95835H9.95842V2.37502ZM4.70842 6.45835H2.66675C2.50567 6.45835 2.37508 6.58894 2.37508 6.75002V9.08335C2.37508 9.24444 2.50567 9.37502 2.66675 9.37502H4.70842V6.45835ZM5.29175 6.45835H7.33342C7.4945 6.45835 7.62508 6.58894 7.62508 6.75002V9.08335C7.62508 9.24444 7.4945 9.37502 7.33342 9.37502H5.29175V6.45835ZM5.87508 3.54169C5.87508 3.3806 5.7445 3.25002 5.58342 3.25002H4.41675C4.25567 3.25002 4.12508 3.3806 4.12508 3.54169V5.29169C4.12508 5.45277 4.25567 5.58335 4.41675 5.58335H5.58342C5.7445 5.58335 5.87508 5.45277 5.87508 5.29169V3.54169ZM6.75008 3.54169C6.75008 3.3806 6.88067 3.25002 7.04175 3.25002H8.20842C8.3695 3.25002 8.50008 3.3806 8.50008 3.54169V5.29169C8.50008 5.45277 8.3695 5.58335 8.20842 5.58335H7.04175C6.88067 5.58335 6.75008 5.45277 6.75008 5.29169V3.54169ZM3.25008 3.54169C3.25008 3.3806 3.1195 3.25002 2.95842 3.25002H1.79175C1.63067 3.25002 1.50008 3.3806 1.50008 3.54169V5.29169C1.50008 5.45277 1.63067 5.58335 1.79175 5.58335H2.95842C3.1195 5.58335 3.25008 5.45277 3.25008 5.29169V3.54169Z"
          fill="#006554"
        />
      </svg>
    );
  }
}

BopisIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

BopisIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
