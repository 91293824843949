import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { updateFilter } from '../../../../utility/history';
import { toggleOpenCloseFacet } from '../../../../utility/utils';
import ToggleSwitch from '../../../common/ToggleSwitch/ToggleSwitch';
import CreateFacetContainer from './CreateFacetContainer';

// Component that will create single selct facet cards

const ToggleSwitchSingleSelectFacet = ({
  selectedData,
  facet,
  openFacet,
  onSelection,
  onFilterData,
  storeName,
}) => {
  const [facetExpanded, setFacetExpanded] = useState(openFacet);

  const toggleFacet = (e, identifier) => {
    toggleOpenCloseFacet(identifier);
    setFacetExpanded(!facetExpanded);
  };
  useEffect(() => {
    setFacetExpanded(openFacet);
  }, [openFacet]);

  const onFilterChange = (val, e) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'single');
    updateFilter(identifier, values, 'single');
  };

  const createMultiFacetValue = (facetValue, identifier) => {
    return (
      <span key={`${identifier}_${facetValue.value}_${facetValue.count}`}>
        <div
          className="facet-value"
          key={`${identifier}_facet_value_${facetValue.value}`}
          id={`${identifier}_facet_value_${facetValue.value}`}>
          {facetValue.value}
        </div>
        <div className="rs-facet-count" key={`${identifier}_facet_count_${facetValue.count}`}>
          {` (${facetValue.count})`}
        </div>
      </span>
    );
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const createFacetValues = (valueList, identifier) => {
    return valueList.map((facetValue, index) => {
      const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
      const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;

      return (
        <ToggleSwitch
          key={`${identifier}_${index}`}
          id={`${identifier}_facet_value_${facetValue.value}`}
          status={isChecked}
          onToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
          disabled={disabled}
          label={createMultiFacetValue(facetValue, identifier)}
        />
      );
    });
  };

  return (
    <CreateFacetContainer
      facet={facet}
      openFacet={openFacet}
      selectedData={selectedData}
      storeName={storeName}
      facetExpanded={facetExpanded}
      valueList={createFacetValues(facet.valueList, facet.attrIdentifier)}
      toggleFacet={toggleFacet}
      type="toggle"
    />
  );
};

export default ToggleSwitchSingleSelectFacet;

ToggleSwitchSingleSelectFacet.propTypes = {
  /** facet object */
  facet: PropTypes.object.isRequired,
  /** selected data array for particular facet */
  selectedData: PropTypes.array,
  /** facet open or close flag */
  openFacet: PropTypes.bool.isRequired,
  /** function to apply selected filter */
  onFilterData: PropTypes.func.isRequired,
};
