import { createSlice } from '@reduxjs/toolkit';
import { FavoriteListsDetailProductType, UserState } from '../../types';

export const initialState = {
  userFavorites: {
    list: {
      id: '',
      displayName: '',
      defaultList: false,
      products: [],
    },
    listEcodes: {},
  },
} as UserState;

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUserFavorites(state, action) {
      // Useful for mass updating multiple pieces of state at once
      const { userFavorites } = state;
      return {
        ...state,
        userFavorites: {
          ...userFavorites,
          ...action.payload,
        }
      };
    },
    setUserListEcodes(state, action) {
      // Useful for mass updating multiple pieces of state at once
      const { userFavorites } = state;
      return {
        ...state,
        userFavorites: {
          ...userFavorites,
          listEcodes: action.payload,
        }
      };
    },
    addUserFavorite(state, action) {
      const product = action.payload
      const { userFavorites } = state
      const newProductsList = state.userFavorites.list.products
        ? userFavorites.list.products.concat(product)
        : [product];
      const { list, listEcodes } = state.userFavorites;
      return {
        ...state,
        userFavorites: {
        list: {
          ...list,
          products: newProductsList,
        },
        listEcodes,
      }
      };
    },
    addUserListEcode(state, action) {
      const {ecode, added} = action.payload;
      const {listEcodes} = state.userFavorites;
      const updatedListEcodes = {...listEcodes};

      if (!listEcodes[ecode]) {
        Object.assign(updatedListEcodes, {[ecode]: []});
      }
     
      updatedListEcodes[ecode] = [...updatedListEcodes[ecode], ...added];

      return {
        ...state,
        userFavorites: {
          ...state.userFavorites,
          listEcodes: updatedListEcodes,
        }
      };
    },
    removeUserFavorite(state, action) {
      const product = action.payload;
      const { userFavorites } = state;
      const favoritesListContent = userFavorites.list.products.filter(
        (p: FavoriteListsDetailProductType) => p.ecode !== product.ecode || (p.ecode === product.ecode && p.sku !== product.sku));
      const { list, listEcodes } = userFavorites;
      return {
        ...state,
        userFavorites: {
          list: {
            ...list,
            products: favoritesListContent,
          },
          listEcodes,
        },
      };
    },
    removeUserListEcode(state, action) {
      const {ecode, removed} = action.payload;
      const {listEcodes} = state.userFavorites;
      const updatedListEcodes = {...listEcodes};
      const filteredIds = updatedListEcodes[ecode].filter(val => !removed.includes(val));
      
      if (!filteredIds.length) {
        delete updatedListEcodes[ecode];
      } else {        
        updatedListEcodes[ecode] = filteredIds;
      }
     
      return {
        ...state,
        userFavorites: {
          ...state.userFavorites,
          listEcodes: updatedListEcodes,
        }
      };
    }
  },
});

export const {
  setUserFavorites,
  setUserListEcodes,
  addUserFavorite,
  addUserListEcode,
  removeUserFavorite,
  removeUserListEcode,
} = userSlice.actions;


export default userSlice.reducer;