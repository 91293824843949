import React from 'react';
import { handleADA } from '../../../../utility/utils';
import SmallChevonIcon from '../../../common/SVGIcons/SmallChevonIcon';
import { default as CaliaSmallChevonIcon } from '../../../common/SVGIcons/Calia/SmallChevronIcon';
import PLChevronIcon from '../../../common/SVGIcons/PublicLands/PLChevronIcon';
import { gtgtString } from '../../../../utility/feature-flags';
import MJChevronIcon from '../../../common/SVGIcons/MooseJaw/MJChevronIcon';

const CreateFacetContainer = (props) => {
  const {
    facet,
    toggleFacet,
    openFacet,
    facetExpanded,
    storeName,
    selectedData,
    showNotFound,
    valueList,
    children,
    type,
    disableShowSelections = false,
  } = props;

  let chevronIcon;
  switch (storeName) {
    case 'calia':
      chevronIcon = (
        <CaliaSmallChevonIcon
          classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']}
        />
      );
      break;
    case 'mj':
      chevronIcon = (
        <MJChevronIcon classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']} />
      );
      break;
    case 'pl':  
      chevronIcon = (
        <PLChevronIcon classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']} />
      );
      break;
    default:
      chevronIcon = (
        <SmallChevonIcon classes={[facetExpanded ? 'rs-facet-icon-down' : 'rs-facet-icon-up']} />
      );
      break;
  }

  const createFacetHandler = () => {
    let label = selectedData;
    if (facet && facet.valueList && facet.valueList.length > 0) {
      if (type === 'multi') {
        if (facet.attrName === 'Guaranteed_To_Get_There' && label.length) {
          label = gtgtString;
        }
      }
      let hasSelectedDataClass = '';
      if (selectedData && selectedData.length !== 0) {
        hasSelectedDataClass = 'has-selected-facets';
      }
      return (
        <div className="rs-facet-container" key={`container_facet_${facet.attrIdentifier}`}>
          <div
            tabIndex="0"
            className={`${
              openFacet || facetExpanded
                ? 'rs-facet-heading focus-border expanded'
                : 'rs-facet-heading focus-border'
            } ${hasSelectedDataClass}`}
            id={`facet_heading_${facet.attrIdentifier}`}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={(e) => toggleFacet(e, facet.attrIdentifier)}
            onKeyPress={(e) => handleADA(e, 'button', toggleFacet(e, facet.attrIdentifier))}
            role="tab"
            aria-haspopup="true"
            aria-controls={`facet_list_${facet.attrIdentifier}`}
            aria-expanded={facetExpanded}
            aria-labelledby={`facet_list_label_${facet.attrName.replace(/[^A-Z0-9]/gi, '_')}`}>
            <div
              className="rs-facet-name-container"
              id={`facet_list_label_${facet.attrName.replace(/[^A-Z0-9]/gi, '_')}`}>
              {facet.attrName === 'Guaranteed_To_Get_There' ? 'Shipping Options' : facet.attrName}
              {chevronIcon}
            </div>
              <div className="rs-selected-facets">
                {!disableShowSelections && `${label && Array.isArray(label) ? label.join(', ') : label} `}
              </div>
          </div>

          <div
            key={`div_facet_value_list_${facet.attrIdentifier}`}
            className={
              showNotFound
                ? 'rs-show-not-found rs-facet-value-data-container'
                : 'rs-facet-value-data-container'
            }
            id={`facet_list_${facet.attrIdentifier}`}
            style={{ display: openFacet ? 'block' : 'none' }}
            aria-hidden={!facetExpanded}>
            {children}
            <div
              className={`rs-facet-value-data ${type} ${type === 'size' &&
                'dsg-flex flex-wrap justify-content-space-around'} `}
              key={`ul_facet_value_list_${facet.attrIdentifier}`}>
              {valueList}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`rs-multi-select-facet rs-facet-wrapper rs-facet-wrapper-${facet.attrName
        .toLowerCase()
        .replace(/ /g, '')}`}
      key={`wrapper_facet_${facet.attrIdentifier}`}>
      {createFacetHandler()}
    </div>
  );
};

export default CreateFacetContainer;
