import { batch } from 'react-redux';
import { getCookie } from '../../utility/cookie-utils';
import {
  createFilterParameters,
  createSearchParameters,
  updateResultsManipulators,
} from '../../utility/history';
import {
  decodeAndReplaceHTML,
  getParameter,
  primitiveDeepCopy,
  seedParameterData,
} from '../../utility/utils';
import { setSeoFiltersApplied } from '../slices/common';
import { setSeedData } from '../slices/input';

/**
 * Supporting function to seed filters upon page load with data from the url
 */
export function seedFilters(isReloadPage) {
  return (dispatch, getState) => {
    if (typeof window === 'undefined' || !window.location) {
      return;
    }
    if (!seedParameterData()) {
      return;
    }

    const facetPrefix = 'X_BazaarVoice_ratings_DSG';
    const pageParameterUpdates = [];
    let facetMap;
    let useSolrFacets = false;
    let useSolrOrderBy = false;
    let useSolrPageSize = false;
    let useSolrPriceRange = false;

    let solrPageSize;
    let solrOrderBy;

    const solrQueryArr = window.location.href.split('#');
    if (solrQueryArr.length > 1 && solrQueryArr[1].includes('facet:')) {
      const facetEntry = getParameter('facet:', solrQueryArr[1]);

      const facets = decodeAndReplaceHTML(facetEntry).split(',');
      facetMap = new Map();
      facets.forEach((facet) => {
        if (facet !== '') {
          const facetComponents = facet.split(':');
          if (facetComponents.length === 1 && facetComponents[0].trim().startsWith(facetPrefix)) {
            facetMap.set(facetPrefix, [
              `${facetComponents[0].charAt(facetComponents[0].length - 1)}-*`,
            ]);
          } else {
            // Get the facet name
            const facetKey = facetComponents[0].trim();
            // get the facet value
            const facetValue = facetComponents[1].replace(/"/g, '').replace(/[+]+/g, ' ');

            // Check to see if the facet already has any existing values
            const currentValue = facetMap.get(facetKey);
            if (currentValue) {
              // Push the additional value to the array of values for the facet option
              currentValue.push(facetValue);
            } else {
              // Create a new array containing the facet value and set it in the map
              facetMap.set(facetKey, [facetValue]);
            }
          }
        }
      });
    }

    if (
      solrQueryArr.length > 1 &&
      solrQueryArr[1].includes('minPrice:') &&
      solrQueryArr[1].includes('maxPrice:') &&
      useSolrPriceRange
    ) {
      useSolrPriceRange = true;
      const minPrice = getParameter('minPrice:', solrQueryArr[1]);
      const maxPrice = getParameter('maxPrice:', solrQueryArr[1]);
      if (minPrice !== '' && minPrice !== undefined && maxPrice !== '' && maxPrice !== undefined) {
        if (facetMap) {
          facetMap.set('dickssportinggoodsofferprice', [`${minPrice}-${maxPrice}`]);
        } else {
          facetMap = new Map();
          facetMap.set('dickssportinggoodsofferprice', [`${minPrice}-${maxPrice}`]);
        }
      }
    }

    if (solrQueryArr.length > 1 && solrQueryArr[1].includes('pageSize:')) {
      useSolrPageSize = true;
      solrPageSize = getParameter('pageSize:', solrQueryArr[1]);
      pageParameterUpdates.push({ identifier: 'pageSize', value: solrPageSize });
    }

    if (solrQueryArr.length > 1 && solrQueryArr[1].includes('orderBy:')) {
      /**
             * Mapping of long description values to the sort values we use in elastic
                Our values we use in Elastic:                       The legacy values:
                { value: "0", text: "Most Relevant" },              Most Relevant: 0
                { value: "1", text: "Top Sellers" },                Top Sellers: blank or 5
                { value: "2", text: "Savings High to Low" },        Savings High to Low:  12
                { value: "3", text: "Price Low to High" },          Price Low to High: 8
                { value: "4", text: "Price High to Low" },          Price High to Low: 9
                { value: "5", text: "Featured Sort" },              Featured Sort: 10
                { value: "6", text: "New Products" },               New Products: 11
                { value: "7", text: "Brand (A-Z)" },                Brand A-Z: 6
             */
      const searchdexMapping = new Map([
        ['0', '0'],
        ['5', '1'],
        ['12', '2'],
        ['8', '3'],
        ['9', '4'],
        ['10', '5'],
        ['11', '6'],
        ['6', '7'],
      ]);
      useSolrOrderBy = true;
      solrOrderBy = getParameter('orderBy:', solrQueryArr[1]);
      if (solrOrderBy !== '' && solrOrderBy !== undefined) {
        solrOrderBy = searchdexMapping.get(solrOrderBy);
      }
      pageParameterUpdates.push({ identifier: 'selectedSort', value: solrOrderBy });
    }

    useSolrFacets = facetMap && facetMap.size > 0;

    const allSearchParams = createSearchParameters();
    const storeCookie = decodeAndReplaceHTML(getCookie('setStoreCookie')) || '';
    const selectedStoreComponents = storeCookie ? storeCookie.split('_') : '';

    const filterObj = getState().input.selectedFilters
      ? primitiveDeepCopy(getState().input.selectedFilters)
      : {};
    let applyFilters = false;

    if (!useSolrFacets) {
      const filterInformation = createFilterParameters(allSearchParams);

      // Loop over the filter object and use it to find keys that aren't in the url filters and remove them from the filter object
      // This is to handle users using the popstate event (forward/back history) to properly remove price and store pickup filters
      if (filterObj) {
        Object.keys(filterObj).forEach((key) => {
          if (!filterInformation.has(key)) {
            delete filterObj[key];
            applyFilters = true;
          }
        });
      }

      filterInformation.forEach((value, cat) => {
        if (cat !== 'facetStore' || selectedStoreComponents.length > 0) {
          filterObj[cat] = value;
          applyFilters = true;
        }
      });
    } else {
      facetMap.forEach((val, key) => {
        filterObj[key] = val;
        applyFilters = true;
      });
    }

    // LoadMore Logic - comment out pageSize
    const pageSize =
      useSolrPageSize && solrPageSize ? solrPageSize : allSearchParams.get('pageSize');
    const pageNumber = allSearchParams.get('pageNumber');
    const selectedSort =
      useSolrOrderBy && solrOrderBy ? solrOrderBy : allSearchParams.get('selectedSort');
    const selectedStore = selectedStoreComponents.length === 3 ? selectedStoreComponents[2] : '';

    const inputData = primitiveDeepCopy(getState().input);

    if (applyFilters) {
      inputData.selectedFilters = filterObj;
    } else if (!isReloadPage) {
      inputData.selectedFilters = {};
    }

    // LoadMore Logic - comment out pageSize
    if (pageSize) {
      inputData.pageSize = +pageSize;
    } else if (!isReloadPage) {
      inputData.pageSize = '48';
    }

    if (selectedSort) {
      inputData.selectedSort = parseInt(selectedSort, 10);
    } else if (!isReloadPage) {
      let sortOrder = 0;
      if (getState().input && getState().input.isFamilyPage) {
        sortOrder = getState().input ? getState().input.selectedSort : 1;
      }
      inputData.selectedSort = sortOrder;
    }

    if (pageNumber) {
      inputData.pageNumber = +pageNumber;
    } else if (!isReloadPage) {
      inputData.pageNumber = '0';
    }

    if (selectedStore) {
      inputData.selectedStore = selectedStore;
    } else {
      inputData.selectedStore = '0';
    }
    inputData.appliedSeoFilters = useSolrFacets || useSolrOrderBy || useSolrPageSize;
    const seedData = {
      inputData,
    };
    if (pageParameterUpdates.length > 0) {
      updateResultsManipulators(pageParameterUpdates, true);
    }
    batch(() => {
      dispatch(setSeedData(seedData));
      dispatch(setSeoFiltersApplied(true))
    })
  };
}
