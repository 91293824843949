import axios from 'axios';
import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useAppDispatch } from '../reduxStore/store';
import { FavoriteListsDetailProductType } from '../types';
import { userFavoritesSelector } from '../reduxStore/selectors';
import {
  setUserFavorites,
  addUserFavorite,
  removeUserFavorite,
  setUserListEcodes,
  addUserListEcode,
  removeUserListEcode
} from '../reduxStore/slices/user';
import { useSelector } from 'react-redux';
import { getIsValidHost, getUserToken } from '../utility/utils';
import { AddToFavoritesEvent, RemoveFromFavoritesEvent } from '../utility/analytics-events';

const useFavorites = (storeIdentifierName: string) => {
  const dispatch = useAppDispatch();
  const [favoritesList, setFavoritesList] = useState<FavoriteListsDetailProductType[]>([]);
  const [loading, setLoading] = useState<boolean>(false);
  const userFavorites = useSelector(userFavoritesSelector);


  const toastyEvent = useCallback((name = '') => {
    let evt = new CustomEvent('renderToasty', { detail: name });
    window.dispatchEvent(evt);
  }, [])

  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';

  const getFavoritesUrl = () => {
    let returnUrl;

    if (getIsValidHost() || host.includes('prod-')) {
      returnUrl = `https://${window.location.host}/v1/mylocker`;
    } else {
      returnUrl = `/favorites-api/v1/mylocker`;
    }

    return returnUrl;
  };

  const favoritesRequest = (endpoint = '', params = '', method = '', body = null) => {
    return new Promise((resolve) => {
      getUserToken().then(
        (token: string | undefined) => {
          if (typeof token !== 'string') {
            resolve(new Promise((r) => r({ status: 0 })));
            return;
          }

          resolve(
            axios(`${getFavoritesUrl()}${endpoint}?${params}`, {
              method,
              withCredentials: true,
              headers: {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
                Authorization: token,
                Cookie: host.includes('prod-') ? 'np0=true' : '',
              },
              data: body,
            }),
          );
        },
        () => {
          resolve(new Promise((r) => r({ status: 0 })));
        },
      );
    });
  };

  useEffect(() => {
    setFavoritesList(userFavorites?.list?.products || []);
    setLoading(false);
  }, [userFavorites, setFavoritesList, setLoading]);

  const addFavorite = (ecode: string, sku: string | undefined = undefined) => {
    setLoading(true);
    try {
      const body = {
        products: [
          {
            ecode,
            sku
          },
        ],
      };
      favoritesRequest('/products', `brand=${storeIdentifierName}`, 'PUT', JSON.stringify(body))
        .then((response) => {
          if (response.status === 200) {
            dispatch(
              addUserFavorite({
                id: response.data[0].id,
                ecode: response.data[0].ecode,
                sku,
                analytics: {},
              }),
            );
            toastyEvent('Signed In');
            const evt = new CustomEvent('updateFavoritesStoreData', { detail: 'PLP' });
            window.dispatchEvent(evt);
            AddToFavoritesEvent(response.data[0].ecode);
          }
          setLoading(false);
        })
        .catch((e) => {
          const { response } = e;
          if (response.status === 422) {
            if (response.data && response.data.errorIdentifier  === 'OVER_CAPACITY_EXCEPTION') {
              toastyEvent('Signed In Limit');
            } else {
              toastyEvent('Unknown');
            }
          } else if (response.status == 401) {
            toastyEvent('Signed Out');
          } else {
            toastyEvent('Unknown');
          }
          setLoading(false);
        });
    } catch (e) {
      toastyEvent('Unknown');
      setLoading(false);
    }
  };

  const removeFavorite = (ecode: string, sku: string | undefined = undefined) => {
    setLoading(true);
    try {
      const productFavorite = userFavorites.list.products.find(
        (p: FavoriteListsDetailProductType) => p.ecode === ecode && p.sku === sku,
      );
      favoritesRequest(`/products`, `productIds=${productFavorite.id}`, 'DELETE')
        .then((response) => {
          if (response.status === 202) {
            dispatch(removeUserFavorite(productFavorite));
            toastyEvent('Signed In Remove');
            const evt = new CustomEvent('updateFavoritesStoreData', { detail: 'PLP' });
            window.dispatchEvent(evt);
            RemoveFromFavoritesEvent(ecode);
          }
          setLoading(false);
        })
        .catch((e) => {
          if (e.response.status == 401) {
            toastyEvent('Signed Out');
          } else {
            toastyEvent('Unknown');
          }
          setLoading(false);
        });
    } catch (e) {
      toastyEvent('Unknown');
      setLoading(false);
    }
  };

  const fetchFavorites = () => {
    const genericError = 'Favorites couldnt be loaded';
    try {
      favoritesRequest('/lists/detail', `brand=${storeIdentifierName}`, 'GET')
        .then((response) => {
          if (response.status === 200) {
            dispatch(setUserFavorites(response.data));
            setFavoritesList(response.data.list.products || []);
          }
        })
        .catch((e) => {
          console.error(genericError, e);
        });
    } catch (e) {
      console.error(genericError);
    }
  };
  
  const fetchFavoritesFromLists = () => {
    const genericError = `Lists couldn't be loaded`;
    favoritesRequest('/products/mapByEcode', `brand=${storeIdentifierName}`, 'GET')
      .then((response) => {
        if (response.status === 200) {
          // Convert ecodes key in list to uppercase to avoid duplicated ecodes on API
          const upperEcode = ecode =>
            Object.keys(ecode).reduce((acc, key) => {
              acc[key.toUpperCase()] = ecode[key];
              return acc;
            }, {});

          dispatch(setUserListEcodes(upperEcode(response.data)));
        }
      })
      .catch((e) => {
        console.error(genericError, e);
      });
  };

  const listEcodes = useMemo(() => userFavorites.listEcodes, [userFavorites.listEcodes])

  const addListFavorite = (ecode: string) => {
    if (typeof window !== 'undefined' && window.headerFunctions) {
      window.headerFunctions.openListPanel('add-product', {ecode}, true, ({added}) => {
        dispatch(addUserListEcode({ecode, added}));
      });
    }
  };

  const removeListFavorite = (ecode: string) => {
    if (typeof window !== 'undefined' && window.headerFunctions) {
      window.headerFunctions.openListPanel('remove-product', {ecode}, false, ({added, removed}) => {
        dispatch(addUserListEcode({ecode, added}));
        dispatch(removeUserListEcode({ecode, removed}));
      });
    }
  };

  return {
    favoritesRequest,
    favoritesList,
    addFavorite,
    addListFavorite,
    removeFavorite,
    removeListFavorite,
    fetchFavorites,
    fetchFavoritesFromLists,
    listEcodes,
    loading,
  };
};

export default useFavorites;
