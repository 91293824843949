import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { handleADA } from '../../../utility/utils';

export default class CloseIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = `${additionalClasses}`;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = `${additionalPathClasses}`;

    return (
      <svg
        width="14"
        height="15"
        viewBox="0 0 14 15"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg"
        onMouseDown={(e) => e.preventDefault()}
        onClick={this.props.onClick}
        tabIndex={0}
        onKeyPress={(e) => {
          handleADA(e, 'button', this.props.onClick);
        }}>
        <path
          d="M14 2.10522L12.59 0.695221L7 6.28522L1.41 0.695221L0 2.10522L5.59 7.69522L0 13.2852L1.41 14.6952L7 9.10522L12.59 14.6952L14 13.2852L8.41 7.69522L14 2.10522Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

CloseIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
  onClick: PropTypes.func,
};

CloseIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
