import React, { memo } from 'react';
import ProductGrid from './ProductGrid/ProductGrid';
import FacetSection from './FacetSection/FacetSection';
import { useDispatch, useSelector } from 'react-redux';
import { default as CaliaFilterIcon } from '../common/SVGIcons/Calia/FilterIcon';
import PLFilterIcon from '../common/SVGIcons/PublicLands/PLFilterIcon';
import MJFilterIcon from '../common/SVGIcons/MooseJaw/MJFilterIcon';
import SettingsIcon from '../common/SVGIcons/SettingsIcon';
import {
  breakpointSelector,
  catTypeStringSelector,
  showMobileFilterFacetModalSelector,
  snbAudienceSelector,
  totalCountSelector,
  storeIdentifierNameSelector,
} from '../../reduxStore/selectors';
import MobileShippingOptions from '../FeatureTesting/MobileShippingOptions/MobileShippingOptions';
import Sorting from '../FeatureTesting/Sorting/Sorting';
import ViewCount from './Pagination/ViewCount';
import { handleADA } from '../../utility/utils';
import { handleMobileFilterFacetModalClick } from '../../reduxStore/thunks';
import MobileFilterButton from '../FeatureTesting/MobileFilterButton/MobileFilterButton';
import SelectedFiltersChips from '../common/SelectedFilterChips/SelectedFilterChips';

const theme = {
  calia: {
    icon: <CaliaFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  pl: {
    icon: <PLFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  mj:{
    icon: <MJFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  default: {
    icon: <SettingsIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
};

const GridRowTwo = () => {
  const dispatch = useDispatch();
  const catTypeString = useSelector(catTypeStringSelector);
  const totalCount = useSelector(totalCountSelector);
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const breakpoint = useSelector(breakpointSelector);
  const snbAudience = useSelector(snbAudienceSelector);
  const themeData = theme[storeIdentifierName] ? theme[storeIdentifierName] : theme.default;

  if (totalCount === 0) return null;

  if (catTypeString === 'C') {
    return (
      <div className="product-facet-wrapper">
        <div className="rs_card_container">
          <div className="dsg-flex justify-content-center rs-color-break-out">{`${totalCount} Products`}</div>
          <ProductGrid />
        </div>
      </div>
    );
  }

  return (
    <div className="product-facet-wrapper">
      {snbAudience && snbAudience === 'improvedProductGrid' && (
        <>
          <MobileFilterButton />
          <MobileShippingOptions />
          <div className="dsg-react-mobile-filter-button-wrapper dsg-flex align-items-center ">
            <button
              aria-label={`Filter Modal Toggle Button`}
              className="dsg-react-mobile-filter-button dsg-flex align-items-center"
              tabIndex={-1}
              onClick={(e) => {
                e.stopPropagation();
                dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
              }}
              onKeyPress={(e) => {
                e.stopPropagation();
                handleADA(e, 'button', () => {
                  dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
                });
              }}>
              {themeData.icon}
              Filter
            </button>
            <ViewCount display="mobile" />
          </div>
        </>
      )}

      <div
        className={`rs-facet-section-wrapper ${
          showMobileFilterFacetModal ? 'hideFilterFacets' : 'showFilterFacets'
        }`}>
        <FacetSection />
      </div>

      <div className="rs_card_container">
        {breakpoint && (breakpoint === 'desktop' || breakpoint === 'small-desktop') && (
          <div className="dsg-react-product-grid-selected-filters">
            <SelectedFiltersChips showClearAllLink={true} />
          </div>
        )}

        {snbAudience && snbAudience === 'improvedProductGrid' ? (
          <>
            <ViewCount display="desktop" isClient />
            <Sorting />
          </>
        ) : (
          <div className="dsg-flex dsg-react-product-grid-product-count">
            <ViewCount display="desktop" isClient />
          </div>
        )}
        <ProductGrid />
      </div>
    </div>
  );
};

export default memo(GridRowTwo);
