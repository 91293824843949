import { createSlice } from '@reduxjs/toolkit';
import { ProductApiData, ProductVO } from '../../types/index.js';

export const initialState = {
  mlSpelling: false,
} as ProductApiData;

// totalCount: number
// searchVO: searchVOObj
// didYouMeanVO: didYouMeanObj
// fuzzyMatch: boolean
// myStoreZero: boolean
// priceFilterZero: boolean
// snbAudience: string
// mlBypass: boolean
// mlSpelling: boolean
// mlRewrite: boolean
// mlRank: boolean
// productVOs: productVOObj[]
// productDetails: productDetailsObj
// facetVOs: facetVOObj[]
// conversationFacetVOS: conversationFacetVOObj[]
// categoryDetails: categoryDetailsObj

const productApiDataSlice = createSlice({
  name: 'productApiData',
  initialState,
  reducers: {
    updateProductApiData(state, action) {
      let newPayload = { ...action.payload };
      let top10 = action.payload.productVOs
        ?.map((item: ProductVO) => {
          return item.parentPartnumber;
        })
        .slice(0, 10);
      delete newPayload.searchVO;
      return {
        ...newPayload,
        top10: top10,
        mlImpressionEcodes: [],
        mlImpressionProductsByRow: {},
      };
    },
    setMLImpressionEcodes(state, action) {
      return { ...state, mlImpressionEcodes: [...state.mlImpressionEcodes, ...action.payload] };
    },
    setMLImpressionProductsByRow(state, action) {
      return { ...state, mlImpressionProductsByRow: { ...action.payload } };
    },
  },
});

export const {
  updateProductApiData,
  setMLImpressionEcodes,
  setMLImpressionProductsByRow,
} = productApiDataSlice.actions;

export default productApiDataSlice.reducer;
