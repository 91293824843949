import { FacetVO, FacetVOsValueList } from '../../../types';
import {
  categoryAttrVoDataSelector,
  categoryDetailsSelector,
  didYouMeanVOSelector,
  facetVOsSelector,
  familyPageNameSelector,
  isFamilyPageSelector,
  storeIdSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import {
  deepCompareWithStringify,
  getFaceoutThumbnailBaseUrl,
  imagineUrlifyFaceOutImage,
} from '../../../utility/utils';

import CarouselArrow from '../../common/CarouselNavigationArrow/CarouselArrow';
import { getImageHost } from '../../../utility/url-utils';
import React from 'react';
import Slider from 'react-slick';
import { useSelector } from 'react-redux';

/**
 * Renders Super Family Category Carousel
 */

const SuperFamilyCategoryCarousel = () => {
  const facetVOs = useSelector(facetVOsSelector, deepCompareWithStringify);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const storeId = useSelector(storeIdSelector);
  const familyPageName = useSelector(familyPageNameSelector);
  const didYouMeanVO = useSelector(didYouMeanVOSelector, deepCompareWithStringify);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const categoryAttrVoData = useSelector(categoryAttrVoDataSelector, deepCompareWithStringify);
  const categoryDetails = useSelector(categoryDetailsSelector, deepCompareWithStringify);

  /** create tiles of shop by category */
  const generateTiles = (facetValueList: FacetVOsValueList) => {
    return facetValueList.map((values, index: number) => {
      if (categoryDetails[values.value]) {
        let faceOutImage = '';
        let faceOutImageIdentifier;
        if (categoryDetails[values.value].thumbnail) {
          if (categoryDetails[values.value].thumbnail.includes('Attachment/')) {
            // thumbnail field contains WCS url need to generate faceOutImage path for WCS host
            faceOutImage =
              getFaceoutThumbnailBaseUrl(storeId) + categoryDetails[values.value].thumbnail;
          } else {
            // thumbnail field isn't a WCS url generate as Akamai Image Storage path
            faceOutImage = `${getImageHost()}/${
              categoryDetails[values.value].thumbnail
            }?$DSG_ProductCard$`;
          }
        } else if (categoryDetails[values.value].topResultThumbnail) {
          // topResultThumbnail is used as a fallback when a manual override thumbnail isn't set this generates a Akamai Image Storage path for the api generated faceout image
          faceOutImageIdentifier = imagineUrlifyFaceOutImage(
            categoryDetails[values.value].topResultThumbnail,
          );
          faceOutImage = `${getImageHost()}/${faceOutImageIdentifier}?$DSG_ProductCard$`;
        }

        let dataEM = null;
        if (isFamilyPage) {
          // Remove spaces from Family Page Name & Category Name for Data EM tag
          const cleanFamilyPageName = familyPageName.replace(/['\s]/g, '');
          const cleanCategoryName = categoryDetails[values.value].name.replace(/['\s]/g, '');
          dataEM = `LN_Bubble_${cleanFamilyPageName}_${cleanCategoryName}_${index + 1}`;
        }

        return (
          <div key={`header_category_${index}`} className="rs-category-facet-links-wrapper">
            <a
              href={categoryDetails[values.value].url}
              className="rs-facet-category-links"
              tabIndex={0}
              aria-label={categoryDetails[values.value].name}
              data-em={dataEM}>
              <div>
                {faceOutImage && (
                  <img src={faceOutImage} alt={categoryDetails[values.value].name} loading="lazy" />
                )}
                <p className="rs-facet-category-link-text">{categoryDetails[values.value].name}</p>
              </div>
            </a>
          </div>
        );
      }

      return null;
    });
  };

  /** create shop by category carousel */
  const generateCategoryTileCarousel = () => {
    if (facetVOs) {
      return facetVOs.map((facet: FacetVO) => {
        if (facet.attrIdentifier === 'facetCatgroup') {
          return generateTiles(facet.valueList);
        }
      });
    }
  };

  if (didYouMeanVO && didYouMeanVO.suggestions && didYouMeanVO.suggestions.length > 0) {
    return null;
  }

  // If not a family page don't render Top Category Carousel
  if (!isFamilyPage) {
    return null;
  }

  // If it's a Hypercat Page do nothing
  if (categoryAttrVoData && categoryAttrVoData.HYPERCATRULE) {
    return null;
  }

  // If it's not a Super Family page do nothing
  if (
    categoryAttrVoData &&
    categoryAttrVoData.LAYOUT_TYPE &&
    categoryAttrVoData.LAYOUT_TYPE.value !== '2'
  ) {
    return null;
  }

  if (!['calia', 'vrst'].includes(storeIdentifierName) && !isFamilyPage) {
    // If Calia and not a family page don't render anything
    return null;
  }

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    nextArrow: <CarouselArrow />,
    prevArrow: <CarouselArrow />,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 5,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
    ],
  };

  return (
    <div className="rs-top-category-carousel">
      <div className="rs-inner-carousel">
        <Slider {...settings}>{generateCategoryTileCarousel()}</Slider>
      </div>
    </div>
  );
};

export default SuperFamilyCategoryCarousel;
