import React, { useState } from 'react';
import { handleADA } from '../../../utility/utils';

const PermanentChip = ({ text = '', onClick, additionalClasses, isSelected }) => {
  return (
    <button
      aria-label={`${text} filter`}
      aria-checked={isSelected}
      tabIndex={0}
      className={`dsg-permanent-chip ${additionalClasses}`}
      onClick={onClick}
      onMouseDown={(e) => e.preventDefault()}
      onKeyPress={(e) => handleADA(e, 'button', onClick)}>
      <span>{text}</span>
    </button>
  );
};

export default PermanentChip;
