import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SortingIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="10"
        height="12"
        viewBox="0 0 10 12"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.47603 8.96897V4.8149H6.23793V8.96897H4.38079L6.85698 11.3334L9.33317 8.96897H7.47603ZM3.14269 0.666748L0.666504 3.03119H2.52365V7.18527H3.76174V3.03119H5.61888L3.14269 0.666748Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

SortingIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SortingIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
