import FEATUREFLAGS_PERF from './featureFlags.perf';
import FEATUREFLAGS_PRODAUTH from './featureFlags.prodauth';
import FEATUREFLAGS_PROD from './featureFlags.prod';

/*
  Feature Flags can be used throughout the code via the getFeatureFlagByNameSelector. 
  This is a redux selector function that you have to wrap in our useAppSelector hook you can import from the redux store.
  You simply need to pass the getFeatureFlagByNameSelector the name of the feature flag. You can refer to the files in src/config/featureFlags for the names of existing flags.

  Example:

  const sameDayDelivery = useAppSelector(getFeatureFlagByNameSelector('sameDayDeliveryEnabled'))

  In the above the sameDayDelivery variable will be a boolean value you can use throughout the component to handle feature flagging the feature.
*/

/*
  DO NOT USE THIS FUNCTION. This should only be used once in the server script.
*/
const getFeatureFlagsByEnvironment = (environment: string | undefined) => {
  switch (environment) {
    case 'perfredesign':
      return FEATUREFLAGS_PERF;
    case 'prodauthredesign':
      return FEATUREFLAGS_PRODAUTH;
    case 'prodredesign':
      return FEATUREFLAGS_PROD;
    default:
      return FEATUREFLAGS_PROD;
  }
};

export default getFeatureFlagsByEnvironment;
