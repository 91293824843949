import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { pageSizeSelector, totalCountSelector } from '../../../reduxStore/selectors';
import { onPageSizeChange } from '../../../reduxStore/thunks/index';
import { updateResultsManipulators } from '../../../utility/history';
import { handleADA } from '../../../utility/utils';

type Props = {
  display: string;
};

const ViewCount = ({ display }: Props) => {
  const dispatch = useDispatch();
  const prevPageSize = useRef<number | undefined>(undefined);
  const pageSize = useSelector(pageSizeSelector);
  const totalCount = useSelector(totalCountSelector);

  useEffect(() => {
    //assign the ref's current value to the count Hook
    prevPageSize.current = pageSize;
  }, [pageSize]);

  useEffect(() => {
    if (pageSize !== prevPageSize.current) {
      window.dispatchEvent(new CustomEvent('scroll'));
    }
  }, [pageSize]);

  const handleCountClick = (passedPageSize: number) => {
    if (pageSize === passedPageSize) return;
    dispatch(onPageSizeChange(passedPageSize));
    const resultsManipulators = [
      {
        identifier: 'pageSize',
        value: passedPageSize,
      },
      {
        identifier: 'pageNumber',
        value: 0,
      },
    ];
    updateResultsManipulators(resultsManipulators, false);
  };

  const renderPageCount = (count: number) => {
    if (totalCount < count) {
      return null;
    }
    const className = `rs-page-count-item p-${count}`;
    let activeClass = '';
    const activateAllItem = count === totalCount && totalCount < pageSize;
    if (pageSize === count || activateAllItem) {
      activeClass = 'active';
    }
    const key = count === totalCount ? 'all' : count;
    const text = count === totalCount ? 'All' : count;
    return (
      <a
        className={`${activeClass} ${className}`}
        key={key}
        onClick={() => handleCountClick(count)}
        onKeyPress={(e) => {
          handleADA(e, 'button', () => handleCountClick(count));
        }}
        tabIndex={0}
        role="button">
        {text}
      </a>
    );
  };

  const pageCounts = [48, 96, 144];
  const resetCount = pageCounts.indexOf(pageSize) === -1 && pageSize !== totalCount;
  if (resetCount) handleCountClick(totalCount < pageCounts[0] ? totalCount : pageCounts[0]);

  return (
    <div
      className={`rs-pagination-wrapper rs-top-pagination ${
        display === 'desktop' ? 'mobile-hide' : ''
      }`}>
      {display === 'desktop' ? (
        <>
          <span className="rs-page-count-label">View:&nbsp;&nbsp;</span>
          {pageCounts.map((count) => renderPageCount(count))}
        </>
      ) : null}
      {totalCount < 144 ? renderPageCount(totalCount) : null}
      {display === 'desktop' ? <span className="rs-pagination-separator">|</span> : null}
      <span className="rs-page-count-label">{`${totalCount} Products`}</span>
    </div>
  );
};

export default ViewCount;
