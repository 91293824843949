import React from 'react';
import FilterChip from './FilterChip/FilterChip';
import Link from '../Link/Link';
import { gtgtString } from '../../../utility/feature-flags';
import { useDispatch, useSelector } from 'react-redux';
import {
  categoryDetailsSelector,
  facetVOsSelector,
  getFeatureFlagByNameSelector,
  selectedFiltersSelector,
} from '../../../reduxStore/selectors';
import { getCookie } from '../../../utility/cookie-utils';
import { decodeAndReplaceHTML } from '../../../utility/utils';
import { clearAllFilterData, clearSingleData } from '../../../reduxStore/thunks';
import useZipCode from '../../../hooks/useZipCode';

type SelectedFiltersChipProps = {
  showClearAllLink?: boolean;
};

const SelectedFiltersChips = ({ showClearAllLink }: SelectedFiltersChipProps) => {
  const dispatch = useDispatch();
  const facetVOs = useSelector(facetVOsSelector);
  const selectedFilters = useSelector(selectedFiltersSelector);
  const categoryDetails = useSelector(categoryDetailsSelector);
  const SDDEnabled = useSelector(getFeatureFlagByNameSelector('SDDEnabled'));

  const sddZip = useZipCode()

  // Abstract away the actual action that gets dispatched so that the event is not passed in as the first arg
  const clearAllFilterDataAbstraction = () => {
    dispatch(clearAllFilterData());
  };

  const generateChipLabel = (attrIdentifier, attrName, value) => {
    let label;

    switch (attrIdentifier) {
      case 'facetCatgroup':
        label = `${categoryDetails[value].name}`;
        break;

      case '5004':
        // Sale don't lead with the Sale label before the value
        label = value;
        break;

      case '2101':
        // Gender don't lead with the Gender label before the value
        label = value;
        break;

      case 'X_BazaarVoice_ratings_DSG':
        // Customer Rating handling showing a more user friendly filter chip label
        switch (value) {
          case '1-*':
            label = `1 Star & Up`;
            break;
          case '2-*':
            label = `2 Stars & Up`;
            break;
          case '3-*':
            label = `3 Stars & Up`;
            break;
          case '4-*':
            label = `4 Stars & Up`;
            break;
          default:
            label = value;
        }
        break;
      case 'Guaranteed_To_Get_There':
        label = gtgtString;
        break;

      case 'facetStore':
        switch (value) {
          case 'BOPIS':
            label =
              SDDEnabled && getCookie('setStoreCookie')
                ? `Pickup at ${decodeAndReplaceHTML(getCookie('setStoreCookie'))
                    .split('_')[1]
                    .toLowerCase()
                    .replace(/(\b[a-z](?!\s))/g, function(b) {
                      return b.toUpperCase();
                    })}`
                : 'Picking Up';
            break;
          case 'ISA':
            label = 'Shopping In-Store';
            break;
          case 'SDD':
            label = `Same Day Delivery to ${sddZip}`;
            break;
          case 'SHIP':
            label = `Ship to ${sddZip}`;
            break;
          default:
            label = 'Default Store Filter';
        }
        break;

      case 'dickssportinggoodsofferprice':
      case 'golfgalaxyofferprice':
      case 'fieldandstreamofferprice':
      case 'publiclandsofferprice':
        switch (value) {
          case '*-10':
            label = `${attrName}: under $10`;
            break;
          case '*-25':
            label = `${attrName}: under $25`;
            break;
          case '500-*':
            label = `${attrName}: $500 and above`;
            break;
          default:
            label = `${attrName}: $${value.split('-')[0]} - $${value.split('-')[1]}`;
        }
        break;

      default:
        label = `${attrName}: ${value}`;
    }
    return label;
  };

  const generateClearFilterCallback = (category, value, event) => {
    if (event) {
      dispatch(clearSingleData(category, value));
    }
  };

  const generateChips = () => {
    const chips = [];
    for (const attrIdentifier in selectedFilters) {
      let facetVO = {};
      if (attrIdentifier !== 'facetCatgroup' && facetVO) {
        facetVO = facetVOs.find((vo) => {
          return vo.attrIdentifier === attrIdentifier;
        });
      }

      selectedFilters[attrIdentifier].forEach((value) => {
        let label;
        if (facetVO && facetVO.attrName) {
          label = generateChipLabel(attrIdentifier, facetVO.attrName, value);
        } else {
          label = generateChipLabel(attrIdentifier, '', value);
        }

        chips.push(
          <FilterChip
            key={label}
            label={label}
            onClick={(event) => generateClearFilterCallback(attrIdentifier, value, event)}
          />,
        );
      });
    }

    if (chips.length >= 1 && showClearAllLink) {
      chips.push(
        <Link
          key="clear-all-link"
          inline
          text="Clear All"
          onClick={clearAllFilterDataAbstraction}
        />,
      );
    }
    return chips;
  };

  if (selectedFilters && Object.keys(selectedFilters).length !== 0) {
    return (
      <div className="dsg-flex flex-wrap align-items-center filter-chip-container">
        {generateChips()}
      </div>
    );
  }
  return null;
};

export default SelectedFiltersChips;
