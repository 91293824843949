import { primitiveDeepCopy, removeSolrURL } from '../../utility/utils';
import { selectStore } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI, setDefaultFilters } from './utility/thunk-utils';

export function fetchSelectedCategory(selectedStore, performFamilyPostPageRenderEvent = false) {
  return (dispatch, getState) => {
    const stateData = getState();

    removeSolrURL(stateData.common.seoFiltersApplied);

    dispatch(isSearching());

    if (selectedStore) {
      // Cookie should be set prior to this function being called. This is an initializer for client side.
      dispatch(selectStore(selectedStore));
      window.headerFunctions &&
        window.headerFunctions.updateSelectedStore &&
        window.headerFunctions.updateSelectedStore();
    }

    const input = primitiveDeepCopy({
      ...getState().input,
      selectedStore: selectedStore,
    });

    input.selectedFilters = setDefaultFilters(input);
    callAPI(input, dispatch, stateData, performFamilyPostPageRenderEvent);
  };
}
