import React, { PureComponent } from 'react';

export default class LoadingOverlay extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="dsg-react-loading-overlay">
        <div className="dsg-react-loading-spinner" />
      </div>
    );
  }
}
