import React, { useState, useEffect, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CardImage from './components/CardImage';
import ProductDescription from './components/ProductDescription';
import ProductPrice from './components/ProductPrice';
import ProductRating from './components/ProductRating';
import ProductLowStock from './components/ProductLowStock';

import { createProductLinkCallback } from '../../../utility/ml-utils';
import {
  categoryIdentifierSelector,
  catgroupIdSelector,
  searchResultSelector,
  selectedFiltersSelector,
  selectedSwatchValSelector,
  snbAudienceSelector,
  storeIdentifierNameSelector,
  getFeatureFlagByNameSelector,
} from '../../../reduxStore/selectors';
import { generatePdpURL } from '../ProductGrid/utility/product-card-utils';
import { ProductDetails, ProductVO } from '../../../types';
import { deepCompareWithStringify } from '../../../utility/utils';
import useMachineLearningImpression from '../hooks/useMachineLearningImpression';

type Props = {
  product: ProductVO;
  productDetails: ProductDetails;
  row: number;
  column: number;
  rowLength: number;
};

const ColorBreakoutProductCardLayout = ({
  product,
  productDetails,
  row,
  column,
  rowLength,
}: Props) => {
  const searchResult = useSelector(searchResultSelector, deepCompareWithStringify);
  const catgroupId = useSelector(catgroupIdSelector);
  const selectedSwatchVal = useSelector(selectedSwatchValSelector, deepCompareWithStringify);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const categoryIdentifier = useSelector(categoryIdentifierSelector);
  const selectedFilters = useSelector(selectedFiltersSelector, deepCompareWithStringify);
  const snbAudience = useSelector(snbAudienceSelector);
  const productCardRatingEnabled = useSelector(
    getFeatureFlagByNameSelector('productCardRatingEnabled'),
  );
  const ref = useRef<any>();
  const impressionFired = useMachineLearningImpression({
    ref,
    row,
    column,
    rowLength,
  });

  const [name] = useState(
    product.name
      .trim()
      .toLowerCase()
      .replace(/[^\w]/gi, '_')
      .replace(/(_+)\1+/g, '$1'),
  );
  const [pdpUrl, setPdpUrl] = useState(
    generatePdpURL(
      product,
      categoryIdentifier,
      snbAudience,
      catgroupId,
      selectedFilters,
      selectedSwatchVal,
      name,
    ),
  );

  useEffect(() => {
    setPdpUrl(
      generatePdpURL(
        product,
        categoryIdentifier,
        snbAudience,
        catgroupId,
        selectedFilters,
        selectedSwatchVal,
        name,
      ),
    );
  }, [product, selectedSwatchVal, categoryIdentifier, selectedFilters]);

  const createLinkCallback = (event) => {
    event.stopPropagation();

    createProductLinkCallback(pdpUrl, searchResult, catgroupId, product, storeIdentifierName);
  };
  if (!pdpUrl) return null;

  return (
    <div
      className={`dsg-flex flex-column dsg-react-product-card rs_product_card ${
        storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj'
          ? 'dsg-react-product-card-col-4'
          : ''
      }`}
      ref={ref}>
      <div
        className="dsg-flex flex-column rs_card_layout rs_colorbreakout_pd_mt"
        key={`card_layout${product.partnumber}`}
        onClick={createLinkCallback}
        onKeyPress={(event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            createLinkCallback(event);
          }
        }}
        tabIndex="0">
        <CardImage
          imageSrc={product.thumbnail}
          key={`card_image${product.partnumber}`}
          product={product}
          pdpUrl={pdpUrl}
          searchResult={searchResult}
          catgroupId={catgroupId}
          selectedSwatchVal={selectedSwatchVal}
          productDetails={productDetails}
          name={name}
          storeIdentifierName={storeIdentifierName}
        />
        <ProductDescription
          name={product.name}
          key={`card_description${product.partnumber}`}
          pdpUrl={pdpUrl}
          searchResult={searchResult}
          catgroupId={catgroupId}
          details={productDetails}
          storeIdentifierName={storeIdentifierName}
          color={productDetails.skuAttributes.Color && productDetails.skuAttributes.Color[0]}
        />
        <ProductPrice
          productDetails={productDetails}
          catentryId={product.catentryId}
          key={`card_price${product.partnumber}`}
        />
        {productCardRatingEnabled &&
          product.ratingCount != undefined &&
          product.ratingCount > 0 && (
            <ProductRating
              key={`card_rating${product.partnumber}`}
              product={product}
              pdpUrl={pdpUrl}
              searchResult={searchResult}
              catgroupId={catgroupId}
              storeIdentifierName={storeIdentifierName}
              ratingCount={product.ratingCount}
              ratingValue={product.ratingValue}
            />
          )}
        {productDetails.lowStock && product && !product.isComingSoon && <ProductLowStock />}
      </div>
    </div>
  );
};

export default memo(ColorBreakoutProductCardLayout, deepCompareWithStringify);
