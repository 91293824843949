import store from '../reduxStore/store'
function ctrlClickNewTabOpen(event, href) {
  // In that case, event.ctrlKey does the trick.
  if (event.ctrlKey || event.metaKey) {
    window.open(href, '_blank');
  } else {
    window.location.href = href;
  }
}

export const createProductLinkCallback = (href, searchResult, catGroupId, product, storeIdentifierName) => {
  return (event) => {
    event.preventDefault();
    
    // If the area clicked on the product card is the swatch area do nothing
    const eventSwatchDiv = event.currentTarget.querySelector('.rs-fswatch');
    if (
      eventSwatchDiv &&
      eventSwatchDiv.contains(event.target) &&
      event.target.id !== 'swatch-more-desktop' &&
      event.target.id !== 'swatch-more-mobile'
    ) {
      return;
    }

    const eventQuickviewDiv = event.currentTarget.querySelector('.dsg-quick-view-btn');
    if (eventQuickviewDiv && eventQuickviewDiv.contains(event.target)) {
      return;
    }

    if (typeof MLDataCollection === 'undefined') {
      if (typeof window !== 'undefined') {
        ctrlClickNewTabOpen(event, href);
      }
      return;
    }

    if (MLDataCollection) {
      const { recordProductClick } = window.MLDataCollection;
      let storeVal = storeIdentifierName.toUpperCase()
      recordProductClick(storeVal, product, searchResult, searchResult.took, catGroupId);
    }
    ctrlClickNewTabOpen(event, href);
  };
};

export default createProductLinkCallback;
