import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SmallChevronIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = `${additionalClasses}`;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = `${additionalPathClasses}`;

    return (
      <svg
        width="15"
        height="14"
        viewBox="0 0 15 14"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.47408 5.02933C3.59626 4.90746 3.79434 4.90746 3.91652 5.02933L7.20394 8.30866L10.4914 5.02933C10.6135 4.90746 10.8116 4.90746 10.9338 5.02933C11.056 5.15121 11.056 5.34881 10.9338 5.47069L7.42517 8.97069C7.30299 9.09256 7.1049 9.09256 6.98272 8.97069L3.47408 5.47069C3.3519 5.34881 3.3519 5.15121 3.47408 5.02933Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

SmallChevronIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SmallChevronIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
