import React from 'react';

// Returns the proper number of carousel items to show based on breakpoint
export const getShownCarouselItems = (breakpoint, storeIdentifierName) => {
  if (storeIdentifierName === 'pl' || storeIdentifierName === 'mj') {
    switch (breakpoint) {
      case 'mobile':
        return 2;
      case 'tablet':
        return 3;
      case 'small-desktop':
        return 4;
      default:
        return 6;
    }
  } else {
    switch (breakpoint) {
      case 'mobile':
        return 2;
      case 'tablet':
      case 'small-desktop':
        return 3;
      default:
        return 6;
    }
  }
};

// Price Calculator for Certona & PromoteIQ
export const calculatePrice = (product) => {
  let priceData = null;
  let minOfferPrice = product.minofferprice ? product.minofferprice.replace(',', '') : 0;
  let maxOfferPrice = product.maxofferprice ? product.maxofferprice.replace(',', '') : 0;
  let maxMapprice = product.maxmapprice ? product.maxmapprice.replace(',', '') : 0;
  let minMapprice = product.minmapprice ? product.minmapprice.replace(',', '') : 0;

  minOfferPrice = isNaN(minOfferPrice) ? 0 : parseFloat(parseFloat(minOfferPrice).toFixed(2));
  maxOfferPrice = isNaN(maxOfferPrice) ? 0 : parseFloat(parseFloat(maxOfferPrice).toFixed(2));
  maxMapprice = isNaN(maxMapprice) ? 0 : parseFloat(parseFloat(maxMapprice).toFixed(2));
  minMapprice = isNaN(minMapprice) ? 0 : parseFloat(parseFloat(minMapprice).toFixed(2));

  if (minOfferPrice && maxOfferPrice) {
    if (maxMapprice && minOfferPrice < maxMapprice) {
      priceData = <span className="rs-final-price see-price-in-cart">See Price In Cart</span>;
    } else if (maxMapprice || minOfferPrice >= maxMapprice) {
      priceData = <span className="rs-final-price">${parseFloat(minOfferPrice).toFixed(2)}</span>;
      if (minOfferPrice !== maxOfferPrice) {
        priceData = (
          <span>
            ${parseFloat(minOfferPrice).toFixed(2)} - ${parseFloat(maxOfferPrice).toFixed(2)}
          </span>
        );
      }
    }
  }
  return priceData;
};

// Serialize JSON object w/ array inside to a GET method acceptable format
export const serialize = (obj) => {
  const url_params = [];
  for (const k in obj) {
    let v;
    if (Array.isArray(obj[k])) {
      v = obj[k].join(',');
    } else {
      v = obj[k];
    }
    url_params.push(`${k}=${v}`);
  }
  return url_params.join('&');
};

export const getSlotID = (isFamilyPage, breakpoint) => {
  if (isFamilyPage) {
    if (breakpoint === 'mobile') {
      // If it's a family page and mobile use DSG-Mobile-Browse-PLA Slot ID
      return 14020;
    }
    // If it's family page and not mobile use DSG-Desktop-Browse-PLA Slot ID
    return 14010;
  }

  if (breakpoint === 'mobile') {
    // If it's a search page and mobile use DSG-Mobile-Search-PLA Slot ID
    return 14015;
  }
  // If it's a search page and not mobile use DSG-Desktop-Search-PLA Slot ID
  return 14005;
};

export const getEnvironment = () => {
  if (
    typeof window !== 'undefined' &&
    window.location.href.indexOf('localhost') === -1 &&
    window.location.href.indexOf('zeta.dickssportinggoods.com') === -1 &&
    window.location.href.indexOf('preview.dickssportinggoods.com') === -1 &&
    window.location.href.indexOf('dickssportinggoods.com') > -1
  ) {
    return 'prod';
  }

  return 'staging';
};
