import { primitiveDeepCopy } from '../../utility/utils';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

// ML Spelling Bypass
export function searchInsteadFor(searchTerm) {
  return (dispatch, getState) => {
    dispatch(isSearching());

    if (getState().input) {
      const stateData = getState();
      const stateInput = primitiveDeepCopy(getState().input);
      stateInput.mlBypass = true;
      stateInput.searchTerm = searchTerm;

      callAPI(stateInput, dispatch, stateData);
    }
  };
}
