import { primitiveDeepCopy } from '../../utility/utils';
import { selectStore } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI, callSearchAPI, setDefaultFilters } from './utility/thunk-utils';

export function onSelectStore(value, fromMount = false) {
  return (dispatch, getState) => {
    const stateData = getState();

    dispatch(isSearching());

    if (value) {
      // cookie update happens prior to this call.
      dispatch(selectStore(value));
    }

    const input = primitiveDeepCopy({
      ...getState().input,
      selectedStore: value,
    });

    if (fromMount) {
      input.selectedFilters = setDefaultFilters(input);
      callSearchAPI(input, dispatch, stateData);
    } else {
      callAPI(input, dispatch, stateData);
    }
  };
}
