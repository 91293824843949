import React, { memo, useState, useEffect, useRef, useMemo, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Badge from './components/Badge';
import CardImage from './components/CardImage';
import ProductSwatch from './components/ProductSwatch';
import ProductDescription from './components/ProductDescription';
import ProductPrice from './components/ProductPrice';
import ProductRating from './components/ProductRating';
import ProductLowStock from './components/ProductLowStock';
import Favorites from './components/Favorites/Favorites';
import { createProductLinkCallback } from '../../../utility/ml-utils';
import ColorBreakout from './ColorBreakoutProductCardLayout';
import Button from '../../common/buttons/Button';
import AddToCartIcon from '../../common/SVGIcons/AddToCartIcon';
import ProductItemAvailability from './components/ProductItemAvailability';
import { deepCompareWithStringify } from '../../../utility/utils';
import {
  generatePdpURL,
  getExclusiveProduct,
  openQuickView,
} from '../ProductGrid/utility/product-card-utils';
import { onSwatchChange } from '../../../reduxStore/slices/common';
import {
  breakpointSelector,
  categoryIdentifierSelector,
  catgroupIdSelector,
  isFamilyPageSelector,
  itemAvailabilitySelector,
  promotionDataSelector,
  searchResultSelector,
  selectedFiltersSelector,
  selectedSwatchValSelector,
  snbAudienceSelector,
  storeIdentifierNameSelector,
  getFeatureFlagByNameSelector,
} from '../../../reduxStore/selectors';
import {
  CommonState,
  InputState,
  ProductAttributes,
  ProductDetails,
  ProductVO,
} from '../../../types';
import useMachineLearningImpression from '../hooks/useMachineLearningImpression';
import ProductPromotion from './components/ProductPromotion';

type Props = {
  catTypeString: CommonState['catTypeString'];
  catentryId: ProductVO['catentryId'];
  column: number;
  row: number;
  rowLength: number;
  product: ProductVO;
  productAttributes: ProductAttributes;
  productCardHeight: number;
  productDetails: ProductDetails;
  showQuickview: boolean;
};

const ProductCardLayout = ({
  catentryId,
  productDetails,
  product,
  productAttributes,
  showQuickview,
  row,
  column,
  rowLength,
  productCardHeight,
}: Props) => {
  if (
    !product ||
    !product.name ||
    !product.thumbnail ||
    !product.parentPartnumber ||
    !product.partnumber
  ) {
    console.warn(
      'Product does not have a required field and will not be displayed. Missing one of: name, thumbnail, parentPartnumber, partnumber.',
      product,
    );
    return null;
  }

  const handleIsExclusive = () => {
    return storeIdentifierName === 'dsg' && productAttributes
      ? getExclusiveProduct(productAttributes)
      : false;
  };

  const dispatch = useDispatch();
  const ref = useRef();
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const breakpoint = useSelector(breakpointSelector);
  const searchResult = useSelector(searchResultSelector, deepCompareWithStringify);
  const catgroupId = useSelector(catgroupIdSelector);
  const selectedSwatchVal = useSelector(selectedSwatchValSelector, deepCompareWithStringify);
  const categoryIdentifier = useSelector(categoryIdentifierSelector);
  const selectedFilters = useSelector(selectedFiltersSelector, deepCompareWithStringify);
  const snbAudience = useSelector(snbAudienceSelector);
  const promotionData = useSelector(promotionDataSelector, deepCompareWithStringify);
  const itemAvailability = useSelector(itemAvailabilitySelector, deepCompareWithStringify);
  const [selectedSwatchValState, setSelectedSwatchValState] = useState({});
  const addFavoritesEnabled = useSelector(getFeatureFlagByNameSelector('addFavoritesEnabled'));
  const skuFavoritesEnabled = useSelector(getFeatureFlagByNameSelector('skuFavoritesEnabled'));
  const productCardRatingEnabled = useSelector(
    getFeatureFlagByNameSelector('productCardRatingEnabled'),
  );

  const [name] = useState(
    product.name
      .trim()
      .toLowerCase()
      .replace(/[^\w]/gi, '_')
      .replace(/(_+)\1+/g, '$1'),
  );
  const [seoUrl, setSeoUrl] = useState(
    generatePdpURL(
      product,
      categoryIdentifier,
      snbAudience,
      catgroupId,
      selectedFilters,
      selectedSwatchVal,
      name,
    ),
  );
  const [isExclusive, setIsExclusive] = useState(handleIsExclusive);
  const impressionFired = useMachineLearningImpression({ ref, row, column, rowLength });

  const createLinkCallback = (event) => {
    event.stopPropagation();

    createProductLinkCallback(seoUrl, searchResult, catgroupId, product, storeIdentifierName);
  };

  const generateDataEmTag = (
    product: Props['product'],
    isFamilyPage: Props['isFamilyPage'],
    row: Props['row'],
    column: Props['column'],
  ) => {
    const pageType = isFamilyPage ? 'plp_' : 'srlp_';
    const pinnedFlag = product.isPinned ? '_pinned' : '';
    return `${pageType + row}_${column}_${product.thumbnail}${pinnedFlag}`;
  };

  const handleSwatchChange = (payload) => {
    dispatch(onSwatchChange(payload));
  };

  useEffect(() => {
    setSeoUrl(
      generatePdpURL(
        product,
        categoryIdentifier,
        snbAudience,
        catgroupId,
        selectedFilters,
        selectedSwatchVal,
        name,
      ),
    );
    setIsExclusive(handleIsExclusive());
  }, [product, selectedSwatchVal, categoryIdentifier, selectedFilters]);

  // Only render badge if not calia
  let badge = null;
  if (!['calia', 'vrst', 'g3'].includes(storeIdentifierName)) {
    badge = (
      <Badge
        badgeName={isExclusive || product.badge}
        storeIdentifierName={storeIdentifierName}
        key={`badge${product.partnumber}`}
      />
    );
  }

  if (!seoUrl) return null;

  const displayFavoriteIconInProduct = useMemo(
    () => ['CustomizeIt', 'PersonalizedItem'].includes(product.badge),
    [product],
  );

  const openQuickViewHandler = useCallback(() => {
    const productQuickViewParams = {
      ecode: product.parentPartnumber,
      isFamilyPage,
      categoryIdentifier,
      catgroupId,
      swatchColor: selectedSwatchValState?.swatchColor || '',
      template: 'cart',
    };

    openQuickView(productQuickViewParams);
  }, [selectedSwatchValState, product, isFamilyPage, categoryIdentifier, catgroupId]);

  const openSkuFavoriteHandler = useCallback(() => {
    const productQuickViewParams = {
      ecode: product.parentPartnumber,
      isFamilyPage,
      categoryIdentifier,
      catgroupId,
      swatchColor: selectedSwatchValState?.swatchColor || '',
      template: 'favorites',
    };

    openQuickView(productQuickViewParams);
  }, [selectedSwatchValState, product, isFamilyPage, categoryIdentifier, catgroupId]);

  return (
    <div
      ref={ref}
      className={`dsg-flex flex-column dsg-react-product-card rs_product_card ${
        storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj'
          ? 'dsg-react-product-card-col-4'
          : ''
      }`}>
      {badge}
      <div
        className={` dsg-flex flex-column rs_card_layout ${
          showQuickview ? 'quickviewButton' : ''
        } ${
          storeIdentifierName === 'pl' || storeIdentifierName === 'mj'
            ? 'align-items-center rs-text-center'
            : ''
        }`}
        key={`outer_div${product.partnumber}`}
        data-em={generateDataEmTag(product, isFamilyPage, row, column)}
        onClick={createLinkCallback}
        onKeyPress={(event) => {
          if (event.key === 'Enter' || event.keyCode === 13) {
            createLinkCallback(event);
          }
        }}
        tabIndex="0"
        role="link"
        style={{ height: `${productCardHeight}px`, maxHeight: `${productCardHeight}px` }}>
        <CardImage
          imageSrc={product.thumbnail}
          key={`card_image${product.partnumber}`}
          product={product}
          pdpUrl={seoUrl}
          searchResult={searchResult}
          catgroupId={catgroupId}
          selectedSwatchVal={selectedSwatchValState}
          productDetails={productDetails}
          name={name}
          storeIdentifierName={storeIdentifierName}
        />
        {addFavoritesEnabled && !displayFavoriteIconInProduct && !isExclusive && (
          <Favorites
            partName={product ? product.name : ''}
            ecode={product ? product.parentPartnumber : ''}
            storeIdentifierName={storeIdentifierName}
            handleClick={openSkuFavoriteHandler}
            isComingSoonProduct={product.isComingSoon}
          />
        )}
        <ProductSwatch
          key={`card_swatch${product.partnumber}`}
          parentPartNumber={product?.parentPartnumber}
          colorAttribute={productDetails?.skuAttributes?.Color}
          productDetails={productDetails}
          onSwatchChange={handleSwatchChange}
          breakpoint={breakpoint}
          parentSwatchChange={setSelectedSwatchValState}
          url={seoUrl}
        />
        <ProductDescription
          name={product.name}
          key={`card_description${product.partnumber}`}
          pdpUrl={seoUrl}
          searchResult={searchResult}
          catgroupId={catgroupId}
          details={productDetails}
          storeIdentifierName={storeIdentifierName}
        />
        <ProductPrice
          productDetails={productDetails}
          catentryId={product.catentryId}
          key={`card_price${product.partnumber}`}
          storeIdentifierName={storeIdentifierName}
        />
        {productCardRatingEnabled && (
          <ProductRating
            key={`card_rating${product.partnumber}`}
            product={product}
            pdpUrl={seoUrl}
            searchResult={searchResult}
            catgroupId={catgroupId}
            storeIdentifierName={storeIdentifierName}
            ratingCount={product.ratingCount}
            ratingValue={product.ratingValue}
          />
        )}
        {promotionData &&
          typeof promotionData === 'object' &&
          Object.keys(promotionData).length > 0 && (
            <ProductPromotion catentryId={catentryId} ecode={product.parentPartnumber} />
          )}
        <div className="flex-grow" />
        {itemAvailability && itemAvailability[product.parentPartnumber] && (
          <ProductItemAvailability
            itemAvailability={itemAvailability[product.parentPartnumber]}
            comingSoon={product && !product.isComingSoon}
          />
        )}
        {product.quickViewEnabled && showQuickview && !isExclusive && (
          <Button
            label={
              <span className="dsg-flex align-items-center">
                {storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj' && (
                  <AddToCartIcon classes={['dsg-quickview-button-icon']} />
                )}{' '}
                ADD TO CART
              </span>
            }
            ariaLabel="ADD TO CART"
            classes={
              storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj'
                ? ['dsg-quick-view-btn dsg-quick-view-btn-border-top']
                : ['dsg-quick-view-btn']
            }
            disabled={false}
            onClick={openQuickViewHandler}
            variant={
              storeIdentifierName === 'pl' || storeIdentifierName === 'mj'
                ? 'secondary'
                : 'tertiary'
            }
          />
        )}
        {isExclusive && (
          <Button
            label={<span className="dsg-flex align-items-center">LEARN MORE</span>}
            ariaLabel="LEARN MORE"
            classes={['dsg-quick-view-btn dsg-quick-view-btn-border-top']}
            disabled={false}
            onClick={createLinkCallback}
            variant={'tertiary'}
          />
        )}
      </div>
    </div>
  );
};

export default memo(ProductCardLayout, deepCompareWithStringify);
