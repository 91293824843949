import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  breakpointSelector,
  selectedFiltersSelector,
  showMobileFilterFacetModalSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';

import { clearAllFilterData, handleMobileFilterFacetModalClick } from '../../../reduxStore/thunks';
import { handleADA } from '../../../utility/utils';
import Link from '../../common/Link/Link';
import CloseIcon from '../../common/SVGIcons/CloseIcon';
import PLFilterIcon from '../../common/SVGIcons/PublicLands/PLFilterIcon';
import SettingsIcon from '../../common/SVGIcons/SettingsIcon';
import SelectedFiltersChips from '../../common/SelectedFilterChips/SelectedFilterChips';
import MJFilterIcon from '../../common/SVGIcons/MooseJaw/MJFilterIcon';

const FilterHeader = () => {
  const dispatch = useDispatch();

  const breakpoint = useSelector(breakpointSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const selectedFilters = useSelector(selectedFiltersSelector);

  let filterIcon;
  switch (storeIdentifierName) {
    case 'calia':
      filterIcon = <SettingsIcon pathClasses={['facet-heading-filters-icon']} />;
      break;
    case 'pl':
      filterIcon = <PLFilterIcon pathClasses={['facet-heading-filters-icon']} />;
    case 'mj':  
      filterIcon = <MJFilterIcon pathClasses={['facet-heading-filters-icon']} />;
      break;
    default:
      filterIcon = (
        <SettingsIcon
          classes={['mobile-filter-sort-icon']}
          pathClasses={['facet-heading-filters-icon']}
        />
      );
      break;
  }

  if (breakpoint === 'mobile' || breakpoint === 'tablet') {
    return (
      <div className="dsg-react-filter-header">
        <div className="filter-header">
          {filterIcon}
          <span>Filter & Sort</span>
          <button
            type="button"
            className="filter-header-close-btn"
            onClick={() => {
              dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
            }}>
            <CloseIcon pathClasses={['filter-header-close-icon']} />
          </button>
        </div>
        <div className="dsg-flex filter-header-mobile-selected-filter-chips">
          <SelectedFiltersChips showClearAllLink={false} />
        </div>
      </div>
    );
  }
  return null;
};

export default FilterHeader;
