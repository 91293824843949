import React from 'react';

import PropTypes from 'prop-types';
import isString from 'lodash/isString';
import isBoolean from 'lodash/isBoolean';
import isFunction from 'lodash/isFunction';

const ToggleSwitch = ({ status, disabled, label, id, onToggle }) => {
  const onToggleWrapper = (evt) => {
    if (disabled) {
      return;
    }
    onToggle(evt);
  };

  const onKeyboardFilterActivation = (event) => {
    event.preventDefault();
    // Listen for Space (key code 32) to have been pressed
    if (
      event.key !== 'Enter' ||
      event.keyCode !== 13 ||
      event.key === ' ' ||
      event.keyCode === 32
    ) {
      onToggle(event);
    }
  };

  return (
    <div
      className="switch switch--default"
      role="switch"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onToggleWrapper}
      onKeyPress={onKeyboardFilterActivation}
      tabIndex={0}
      aria-checked={status}
      aria-labelledby={id}>
      <div className={`mdc-switch  mdc-switch--${status ? 'checked' : 'disabled'}`}>
        <div className="mdc-switch__track" />
        <div className={`mdc-switch__thumb-underlay  ball-${status ? 'right' : 'left'} `}>
          <div className="mdc-switch__thumb" />
        </div>
      </div>
      {label}
    </div>
  );
};

ToggleSwitch.propTypes = {
  status: PropTypes.bool,
  onToggle: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
};

ToggleSwitch.defaultProps = {
  status: false,
  disabled: false,
};

export default ToggleSwitch;
