import { toggleMobileFilterFacetMobile } from '../slices/common';

export function handleMobileFilterFacetModalClick(isMobileFilterFacetModalShowing) {
  return (dispatch, getState) => {
    const globalState = getState().common;
    if (globalState.breakpoint === 'mobile' || globalState.breakpoint === 'tablet') {
      if (isMobileFilterFacetModalShowing) {
        document
          .querySelector('.plp-srlp-bootstrap-scope')
          .classList.remove('mobile-filter-facet-modal-open');
      } else {
        document
          .querySelector('.plp-srlp-bootstrap-scope')
          .classList.add('mobile-filter-facet-modal-open');
      }

      dispatch(toggleMobileFilterFacetMobile());
    }
  };
}
