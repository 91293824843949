import React, { memo, useState, useEffect } from 'react';
import { createProductLinkCallback } from '../../../../utility/ml-utils';
import StarsRating from '../../../common/StarsRating';

const ProductRating = ({
  product,
  shouldLink = true,
  pdpUrl,
  ratingValue = 0,
  ratingCount = 0,
  searchResult,
  storeIdentifierName,
  catgroupId,
}) => {
  const [showRatingReview, setShowRatingReview] = useState(false);

  useEffect(() => {
    setShowRatingReview(true);
  }, []);

  let createLinkCallback = null;
  if (shouldLink) {
    createLinkCallback = createProductLinkCallback(
      pdpUrl,
      searchResult,
      catgroupId,
      product,
      storeIdentifierName,
    );
  }

  if (!showRatingReview) return null;

  return (
    <div
      className="rs_rating_container dsg-flex flex-row"
      key={`pRating_${product.name}`}
      tabIndex={0}>
      {// Render only if product has a valid rating count and is greater than 0
      product.ratingCount !== null && product.ratingCount > 0 && (
        <a
          onClick={createLinkCallback}
          href={shouldLink ? pdpUrl : null}
          aria-label={`${product.name} rating ${ratingValue}`}
          className="dsg-flex hmf-align-items-center">
          <StarsRating selectedRatingCount={ratingValue} />
          <span className="rs-rating-count"> {`(${ratingCount})`}</span>
        </a>
      )}
    </div>
  );
};

export default memo(ProductRating);
