import React, { memo } from 'react';
import {
  searchifyTerm,
  decodeAndReplaceHTML,
  deepCompareWithStringify,
} from '../../../utility/utils';
import { getCookie } from '../../../utility/cookie-utils';

import Link from '../../common/Link/Link';
import { useDispatch, useSelector } from 'react-redux';
import {
  didYouMeanVOSelector,
  familyPageNameSelector,
  fuzzyMatchSelector,
  isFamilyPageSelector,
  mlSpellingSelector,
  myStoreZeroSelector,
  searchTermSelector,
  totalCountSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import { searchInsteadFor } from '../../../reduxStore/thunks';

/*
 * PageTitle handles rendering both the Family Page Category Title & the Search Results Title.
 * For Search Results this component has dynamic logic that displays alternate titles based on the search conditions.
 */

const PageTitle = () => {
  const dispatch = useDispatch();

  const totalCount = useSelector(totalCountSelector);
  const searchTerm = useSelector(searchTermSelector);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const didYouMeanVO = useSelector(didYouMeanVOSelector, deepCompareWithStringify);
  const mlSpelling = useSelector(mlSpellingSelector);
  const myStoreZero = useSelector(myStoreZeroSelector);
  const familyPageName = useSelector(familyPageNameSelector);
  const fuzzyMatch = useSelector(fuzzyMatchSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const generateSearchResultsText = () => {
    let resultString = null;
    let notFoundResultString = null;
    let alternateSearchString = null;
    let checkSearchSpellingString = null;

    const fuzzySearchMessages = {
      pl : 'No Luck! Keep Exploring.',
      gg : 'We Need a Mulligan!',
      dsg: 'We Struck Out',
    }
      
    // let didyouMeanLinks;
    if (didYouMeanVO && didYouMeanVO.suggestions && mlSpelling) {
      // Not implementing Did You Mean links at this time
      // didyouMeanLinks = generateDidYouMean();
      resultString = `${totalCount} results for "${didYouMeanVO.suggestions[0]}"`;
      alternateSearchString = (
        <span data-testid="searchDYMAlternateSearch">
          Search instead for&nbsp;
          <Link
            onClick={() => {
              dispatch(searchInsteadFor(didYouMeanVO.originalSearchTerm));
            }}
            text={didYouMeanVO.originalSearchTerm}
          />
        </span>
      );
    } else if (totalCount === 0 && myStoreZero) {
      const storeCookieVal = getCookie('setStoreCookie');
      let selectedStoreValue = 'in your selected store';
      if (storeCookieVal) {
        selectedStoreValue = `at ${decodeAndReplaceHTML(storeCookieVal).split('_')[1]}`;
      }
      notFoundResultString = `0 results for "${searchifyTerm(searchTerm)}" ${selectedStoreValue}`;
      alternateSearchString = 'Please adjust your selected filters or try another search.';
    } else if (fuzzyMatch) {
      const lastSentence = storeIdentifierName === 'dsg' ?  'Try these instead.': 'You might like these items instead.'
      notFoundResultString = (storeIdentifierName !== undefined && fuzzySearchMessages.hasOwnProperty(storeIdentifierName)) ? fuzzySearchMessages[storeIdentifierName] : fuzzySearchMessages['dsg'];
      alternateSearchString = `We couldn't find results for "${searchifyTerm(searchTerm)}" right now. ${lastSentence}`;
      
    } else if (searchTerm && (searchTerm.length > 75 || totalCount === 0)) {
      notFoundResultString = `No results for "${searchifyTerm(searchTerm)}".`;
      alternateSearchString = `We're sorry, we did not find any matches.`;
      checkSearchSpellingString = 'Please check for any spelling errors or try more general keywords.';
    } else if (searchTerm && totalCount) {
      resultString = `${totalCount} results for "${searchTerm}"`;
    }
    
    return (
      <>
        {resultString && <div>{resultString}</div>}
        <div className="rs-not-found-result-string" data-testid="searchNotFoundResultString">
          <span className="hmf-p-x">{notFoundResultString}</span>
        </div>
        <div
          className="rs-alternate-search-string hmf-mt-m"
          data-testid="searchAlternateSearchString">
          <span>{alternateSearchString}</span>
        </div>
        <div
          className="rs-alternate-search-string hmf-mt-xs"
          data-testid="searchCheckSpellingString">
          <span>{checkSearchSpellingString}</span>
        </div>
        {/* Not implementing Did You Mean links at this time. {!mlSpelling ? didyouMeanLinks : null} */}
      </>
    );
  };

  // const generateDidYouMean = () => {
  //   setCookie('dymType', '');

  //   if (!didYouMeanVO || (didYouMeanVO.suggestions && didYouMeanVO.suggestions.length === 0)) {
  //     return null;
  //   }
  //   setCookie('dymEnteredSearchTerm', didYouMeanVO.originalSearchTerm);
  //   const didYouMeanLinks = didYouMeanVO.suggestions.map((suggestion, index) => {
  //     if (index != 0) {
  //       const onClick = () => {
  //         if (typeof document !== 'undefined') {
  //           document.getElementById('SearchBoxText').value = suggestion;
  //           setCookie('dymType', 'DYMClicked');
  //           document.getElementById('searchBox').submit();
  //         }
  //       };
  //       return (
  //         <div onClick={onClick} className="rs-did-you-mean-links" key={`key_links_${index}`}>
  //           <span className="rs-did-you-mean-link-text" key={`key_link_text_${index}`}>
  //             {suggestion}
  //           </span>
  //           <svg
  //             width="8"
  //             height="8"
  //             viewBox="0 0 8 8"
  //             fill="none"
  //             xmlns="http://www.w3.org/2000/svg"
  //             key={`key_link_svg_${index}`}>
  //             <path
  //               d="M4 0L3.295 0.705L6.085 3.5H0V4.5H6.085L3.295 7.295L4 8L8 4L4 0Z"
  //               fill="#191F1C"
  //             />
  //           </svg>
  //         </div>
  //       );
  //     }
  //   });

  //   return (
  //     <div className="rs-did-you-mean-container">
  //       <span className="rs-did-you-mean-text">
  //         Did you mean: <div className="rs-did-you-mean-link-wrapper">{didYouMeanLinks}</div>
  //       </span>
  //     </div>
  //   );
  // };

  return (
    <div className="rs-page-title-wrapper" data-testid="pageTitle">
      {isFamilyPage ? <h1>{familyPageName}</h1> : <h1>{generateSearchResultsText()}</h1>}
    </div>
  );
};

export default PageTitle;
