import { getCookie } from '../../utility/cookie-utils';
import { primitiveDeepCopy, removeSolrURL } from '../../utility/utils';
import { isSearching } from '../slices/common';
import { callAPI, callSearchAPI, setDefaultFilters } from './utility/thunk-utils';

export function fetchSearchTermData(searchTerm, fromWillMount, bypassSpellingCorrection) {
  return (dispatch, getState) => {
    const stateData = getState();

    removeSolrURL(stateData.common.seoFiltersApplied);

    dispatch(isSearching());

    const input = primitiveDeepCopy(getState().input);
    input.searchTerm = searchTerm;

    if (typeof window !== 'undefined') {
      input.storeId = window.storeId;
    }
    if (getCookie('searchRefinement') === 'brands') {
      input.selectedFilters = { X_BRAND: [searchTerm] };
    }
    if (bypassSpellingCorrection) {
      input.mlBypass = true;
    }

    if (fromWillMount) {
      input.selectedFilters = setDefaultFilters(input);
      callSearchAPI(input, dispatch, stateData);
    } else {
      callAPI(input, dispatch, stateData);
    }
  };
}
