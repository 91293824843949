import axios from "axios";
import { MODERN_PROMO_MESSAGE_API } from '../../utility/url-utils';
import { setPromotionResponseSuccess, setErrorResponse } from '../slices/common';

export const getPromoMessageData = (ecodes = [], storeIdentifierName: string) => {
  
  return dispatch => axios(`${MODERN_PROMO_MESSAGE_API}/${storeIdentifierName}-ecomm`, {
    method: 'POST',
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
      'Access-Control-Allow-Origin': '*',
    },
    data: {
      ecodes,
      "onlineChannel": "WEB",
      "swimLane": 0
    },
  })
  .then(response => {
    if(response.status === 200) {
      const promotionData = response.data.reduce((acc, cur) => ({...acc, [cur.ecode]: cur.promoMessages}), {})

      dispatch(setPromotionResponseSuccess(promotionData));
    }
  })
  .catch((error) => {
    console.log(`request failed for promo message api...${error.message}`);
    dispatch(setErrorResponse(true));
  });
};
