import React from 'react';
import PropTypes from 'prop-types';

const AlertSuccessIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.33331 8C1.33331 4.32 4.31998 1.33334 7.99998 1.33334C11.68 1.33334 14.6666 4.32 14.6666 8C14.6666 11.68 11.68 14.6667 7.99998 14.6667C4.31998 14.6667 1.33331 11.68 1.33331 8ZM3.33331 7.99998L6.66665 11.3333L12.6666 5.33331L11.7266 4.38664L6.66665 9.44664L4.27331 7.05998L3.33331 7.99998Z"
        fill="#008D75"
        className={pathClasses}
      />
    </svg>
  );
};

export default AlertSuccessIcon;

AlertSuccessIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

AlertSuccessIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
