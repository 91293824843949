export const getInGridSlotID = (isFamilyPage, breakpoint) => {
  if (isFamilyPage) {
    if (breakpoint === 'mobile') {
      // If it's a family page and mobile use DSG-Mobile-Browse-PLA Slot ID
      return 14095;
    }
    // If it's family page and not mobile use DSG-Desktop-Browse-PLA Slot ID
    return 14085;
  }

  if (breakpoint === 'mobile') {
    // If it's a search page and mobile use DSG-Mobile-Search-PLA Slot ID
    return 14090;
  }
  // If it's a search page and not mobile use DSG-Desktop-Search-PLA Slot ID
  return 14080;
};