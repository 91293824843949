import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SortingIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="14"
        height="16"
        viewBox="0 0 14 16"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.34051 0.114868C4.29136 0.135201 4.24532 0.165342 4.20537 0.205291L0.872039 3.53862C0.70932 3.70134 0.70932 3.96516 0.872039 4.12788C1.03476 4.2906 1.29858 4.2906 1.46129 4.12788L4.08333 1.50584V9.66658C4.08333 9.8967 4.26988 10.0833 4.5 10.0833C4.73012 10.0833 4.91667 9.8967 4.91667 9.66658V1.50584L7.53871 4.12788C7.70142 4.2906 7.96524 4.2906 8.12796 4.12788C8.29068 3.96516 8.29068 3.70134 8.12796 3.53862L4.79485 0.205512L4.34051 0.114868Z"
          fill="#191F1C"
          className={pathClasses}
        />
        <path
          d="M4.34051 0.114868C4.38964 0.0944958 4.4435 0.083252 4.5 0.083252C4.61416 0.083252 4.7176 0.129163 4.79286 0.203529L4.34051 0.114868Z"
          fill="#191F1C"
          className={pathClasses}
        />
        <path
          d="M9.47856 15.916C9.48566 15.9164 9.49281 15.9166 9.5 15.9166C9.5565 15.9166 9.61036 15.9053 9.65949 15.885C9.70864 15.8646 9.75468 15.8345 9.79463 15.7945L13.128 12.4612C13.2907 12.2985 13.2907 12.0347 13.128 11.872C12.9652 11.7092 12.7014 11.7092 12.5387 11.872L9.91667 14.494L9.91667 6.33325C9.91667 6.10313 9.73012 5.91658 9.5 5.91658C9.26988 5.91658 9.08333 6.10313 9.08333 6.33325L9.08333 14.494L6.46129 11.872C6.29858 11.7092 6.03476 11.7092 5.87204 11.872C5.70932 12.0347 5.70932 12.2985 5.87204 12.4612L9.20537 15.7945C9.24532 15.8345 9.29136 15.8646 9.34051 15.885C9.38338 15.9027 9.42987 15.9136 9.47856 15.916Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

SortingIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SortingIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
