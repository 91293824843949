import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ShippingMethodFilter from './ShippingMethodFilter/ShippingMethodFilter';
import { decodeAndReplaceHTML, deepCompareWithStringify } from '../../../utility/utils';
import { changePreferredStoreEvent } from '../../../utility/analytics-events';
import { setAddress, setStoreCookie, getCookie } from '../../../utility/cookie-utils';
import {
  onSelectStore,
  clearFiltersByKey,
  onClearSelectedStore,
} from '../../../reduxStore/thunks/index';
import {
  facetVOsSelector,
  selectedFiltersSelector,
  selectedStoreSelector,
} from '../../../reduxStore/selectors';
import { StoreInfo } from '../../../types';
import MobileStickyFilters from './MobileStickyFilters/MobileStickyFilters';

const StorePickup = ({ breakpoint }) => {
  const dispatch = useDispatch();

  const selectedStore = useSelector(selectedStoreSelector);
  const facetVOs = useSelector(facetVOsSelector, deepCompareWithStringify);
  const selectedFilters = useSelector(selectedFiltersSelector, deepCompareWithStringify);
  const [selectedStoreName, setSelectedStoreName] = useState('');

  useEffect(() => {
    const storeCookieVal = getCookie('setStoreCookie');
    const selectedStoreValue = decodeAndReplaceHTML(storeCookieVal).split('_')[1];

    if (selectedStoreName !== selectedStoreValue) {
      if (storeCookieVal) {
        setSelectedStoreName(selectedStoreValue);
      } else {
        setSelectedStoreName('');
      }
    }
  }, [selectedStore]);

  const onStoreSelection = (store: StoreInfo) => {
    // Update cookie information first
    // set the cookie setStoreCookie
    // 15241_SOUTH%2520HILLS_638 - postalcode_name_clientkey

    // Fire ChangePreferredStore Event
    changePreferredStoreEvent(store.location);

    // Set store selection info. The fetching of data will be handled by componentWillReceiveProps
    const selectedStoreInfo = store.location;
    dispatch(onSelectStore(selectedStoreInfo));
  };

  const handleToggleStorePickupModal = (event: React.MouseEvent) => {
    if (
      typeof window !== 'undefined' &&
      window.headerFunctions &&
      window.headerFunctions.storeSelection
    ) {
      window.headerFunctions.storeSelection({ callback: (store) => onStoreSelection(store) });
    }
  };

  const selectedStoreNameLabel = selectedStoreName || 'Select a nearby store';

  const storeFacet = facetVOs[facetVOs.findIndex((facet) => facet.attrIdentifier === 'facetStore')];

  return (
    <div className="dsg-flex dsg-rs-shipping-filter-store-container">
      <MobileStickyFilters
        selectedStoreName={selectedStoreNameLabel}
        toggleMyStoreModal={handleToggleStorePickupModal}
        selectedShippingMethods={
          selectedFilters && selectedFilters.facetStore ? selectedFilters.facetStore : null
        }
        storeFacet={storeFacet}
        selectedFilters={selectedFilters}
      />
      <ShippingMethodFilter
        storeLabel={selectedStoreNameLabel}
        toggleMyStoreModal={handleToggleStorePickupModal}
        selectedShippingMethods={
          selectedFilters && selectedFilters.facetStore ? selectedFilters.facetStore : null
        }
        selectedStoreName={selectedStoreName}
        storeFacet={storeFacet}
        breakpoint={breakpoint}
      />
    </div>
  );
};

export default StorePickup;
