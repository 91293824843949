import axios from 'axios';
import { getHomrPinnedContentUrl } from '../../utility/url-utils';
import {
  getHomrPageType,
  getImageDataFromHomrResponse,
  getStoreIdentifierNameFromVh,
} from '../../utility/utils';
import { setPinnedContent } from '../slices/common';

export function getPinnedContentFromHomR() {
  return (dispatch, getState) => {
    try {
      const { storeIdentifierName, requestSeoUrl } = getState().common;
      const { isFamilyPage, searchTerm } = getState().input;

      const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
      const isValidHost =
        host.includes('dickssportinggoods') ||
        host.includes('golfgalaxy') ||
        host.includes('fieldandstreamshop') ||
        host.includes('calia') ||
        host.includes('vrst') ||
        host.includes('publiclands') ||
        host.includes('moosejaw') ||
        host.includes('goinggoinggone') ||
        host.includes('qa6.dksxchange');

      const pinnedContentHost = getHomrPinnedContentUrl(isValidHost);
      let pinnedContentPageType = getHomrPageType(isFamilyPage || requestSeoUrl, requestSeoUrl);

      if (pinnedContentPageType === 'search' && !isFamilyPage && searchTerm) {
        // Search page use search term and replace spaces with underscores
        pinnedContentPageType = searchTerm.replace(/ /g, '_');
      }

      return axios
        .get(`${pinnedContentHost}${pinnedContentPageType}&chain=${storeIdentifierName}`, {
          timeout: 1000,
        })
        .then((response) => {
          if (
            response &&
            response.data &&
            response.data.content &&
            response.data.content['pinned-content']
          ) {
            let pinnedContentResponse = response.data.content['pinned-content'];
            // Map over pinnedContent data and add a new structuredImageData object to the existing object data
            pinnedContentResponse = pinnedContentResponse.map((contentSpot) => {
              return {
                ...contentSpot,
                structuredImageData: getImageDataFromHomrResponse(contentSpot),
              };
            });
            dispatch(setPinnedContent(pinnedContentResponse));
          }
        })
        .catch((e) => {
      console.log(`Pinned Content general error: ${e.message}`);
      return false;
        });
    } catch (e) {
      console.log(`Pinned Content general error: ${e.message}`);
      return false;
    }
  };
}
