import React, { useCallback, useMemo } from 'react';
import { ReactionLoveOutlined, ReactionLoveFilled } from '@dsg-tech/homefield-react-icons';
import { getFeatureFlagByNameSelector, userFavoritesSelector } from '../../../../../reduxStore/selectors';
import { useSelector } from 'react-redux';
import { FavoriteListsDetailProductType } from '../../../../../types';
import useFavorites from '../../../../../hooks/useFavorites';
import { getUserToken } from '../../../../../utility/utils';
import { signedOutFavoritesEvent } from '../../../../../utility/analytics-events';

interface FavoritesProps {
  partName: string;
  ecode: string;
  storeIdentifierName: string;
  handleClick: () => void;
  isComingSoonProduct?: boolean;
}

const Favorites = ({ partName, ecode, storeIdentifierName, handleClick, isComingSoonProduct }: FavoritesProps) => {
  const skuFavoritesEnabled = useSelector(getFeatureFlagByNameSelector('skuFavoritesEnabled'));
  const listPanelEnabled = useSelector(getFeatureFlagByNameSelector('listPanelEnabled'));
  const favorites = useSelector(userFavoritesSelector);
  const { addFavorite, removeFavorite, loading, listEcodes, addListFavorite, removeListFavorite } = useFavorites(storeIdentifierName);
  const icon = useMemo(() => {
    if (favorites && favorites?.list?.products) {     
      return (listPanelEnabled ? listEcodes[ecode] 
      : favorites.list.products.some((p: FavoriteListsDetailProductType) => p.ecode === ecode && p.sku === undefined))
        ? 'filled'
        : 'outlined';
    }

    return 'outlined';
  }, [favorites, ecode]);

  const handleFavoritesState = useCallback(async (e) => {
    e.stopPropagation()
    e.preventDefault()

    let userToken: undefined | string = undefined;
    try {
      userToken = await getUserToken();
    } catch (e) {
      userToken = undefined;
    }

    if (typeof window === 'undefined' || !window.quickview?.openProductQuickView) {
      return;
    }

    if (!userToken || typeof userToken !== "string") {
      signedOutFavoritesEvent();
      
      if (window?.authFunctions) {
        window.authFunctions.login(); 
      }
    
      return;
    }

    switch (icon) {
      case 'filled':
        if (listPanelEnabled) {
          removeListFavorite(ecode);
        } else {
          removeFavorite(ecode);
        }

        break;
      case 'outlined':
        // check if window.skuFavoritesEnabled is already true to avoid adding favorite at ecode level when page is loading
        if ((window.skuFavoritesEnabled || skuFavoritesEnabled) && !isComingSoonProduct ) return handleClick();

        if (listPanelEnabled) {
          addListFavorite(ecode);
        } else {
          addFavorite(ecode);
        }
       
        break;
      default:
        console.error('Unknown action received');
    }
  }, [addFavorite, removeFavorite, ecode]);

  return (
    <button
      tabIndex={0}
      disabled={loading}
      aria-label={icon === 'filled' ? `Remove ${partName} from favorites` : `Add ${partName} to favorites`}
      className={'plp-add-favorite-button hmf-display-flex hmf-align-items-center hmf-justify-content-center'}
      onClick={handleFavoritesState}
      type="button">
      {icon === 'filled' ? (
        <ReactionLoveFilled width="24" height="24" className="selected" />
      ) : (
        <ReactionLoveOutlined width="24" height="24" />
      )}
    </button>
  );
};

export default Favorites;


Favorites.defaultProps = {
  isComingSoonProduct: false,
};
