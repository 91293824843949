import { envData } from './environments';
import { getIsValidHost } from './utils';

const host = typeof window !== 'undefined' && window.location ? window.location.host : '';

export const SEARCH_URL = 'v2/search';
export const PRODUCT_DETAILS_URL = 'v2/quickview/ecode';
export const HOST = '';
export const IMAGE_HOST = 'https://dks.scene7.com/is/image/';
export const PROMOTION_API = 'https://gateway.dksxchange.com/ws/WCS/v2/api/promotiondescription';
export const PROD_PROMOTION_API = '/discounts';
export const MODERN_PROMO_MESSAGE_API = getIsValidHost() || host.includes('prod-') ?
'/promomessages':
'/promo-message-api/promomessages'; 
export const CRITEO_API = 'https://d.us.criteo.com/delivery/v2/api/page';
export const HOMR_PROD_HOST = 'https://www.dickssportinggoods.com';
export const HOMR_DEV_HOST = 'https://kappa.dickssportinggoods.com';

// Localhost Note: In order to gain access to kappa in your browser visit: https://kappa.dickssportinggoods.com/homr first to get the cookies
const getHomrEnvironment = () => {
  const host = typeof window !== 'undefined' && window.location ? window.location.host : '';
  if (host && host.includes('localhost')) {
    return HOMR_DEV_HOST;
  }
  return HOMR_PROD_HOST;
};

export const getHomrBannerUrl = (isValidHost) => {
  return `${
    isValidHost ? '' : getHomrEnvironment()
  }/dcsg-ngx-home/homr/v2/api/v4/content?pageType=banner&name=`;
};
export const getHomrMessageUrl = (isValidHost) => {
  return `${
    isValidHost ? '' : getHomrEnvironment()
  }/dcsg-ngx-home/homr/v2/api/v4/content?pageType=messaging&name=`;
};
export const getHomrPinnedContentUrl = (isValidHost) => {
  return `${
    isValidHost ? '' : getHomrEnvironment()
  }/dcsg-ngx-home/homr/v2/api/v4/content?pageType=pinned-content&name=`;
};

export const getPrefix = (store: string, host: string) => {
  if (host.includes('https://')) {
    return host;
  }
  return `https://api-search.${envData[store].domainName}${host}`;
};

export const getImageHost = () => {
  const isNP0 =
    typeof window !== 'undefined' &&
    window.location.hostname.includes('dksxchange') &&
    !window.location.hostname.includes('prod-');
  return `${IMAGE_HOST}${isNP0 ? 'GolfGalaxyTest' : 'dkscdn'}`;
};
