import { createSlice } from '@reduxjs/toolkit';
import { InputState } from '../../types';

export const initialState = {
  pageNumber: 0,
  pageSize: 48,
  selectedStore: '0',
  selectedFilters: {},
  selectedSort: 0,
} as InputState;

let setPageNumber;

// This slice is dedicated to managing the product API parameters.

const inputSlice = createSlice({
  name: 'input',
  initialState,
  reducers: {
    filterData(state, action) {
      setPageNumber = action.payload.setPageDefault ? 0 : state.pageNumber;
      return {
        ...state,
        selectedFilters: action.payload.existingFilters,
        pageNumber: setPageNumber,
      };
    },
    pageSizeChange(state, action) {
      setPageNumber = action.payload.setPageDefault ? 0 : state.pageNumber;
      return {
        ...state,
        pageSize: action.payload.pageSize,
        pageNumber: setPageNumber,
      };
    },
    changePage(state, action) {
      return { ...state, pageNumber: action.payload };
    },
    sortData(state, action) {
      return {
        ...state,
        selectedSort: action.payload,
        pageNumber: 0,
      };
    },
    selectStore(state, action) {
      return { ...state, selectedStore: action.payload };
    },
    setSeedData(state, action) {
      return {
        ...action.payload.inputData,
      };
    },
    updateInput(state, action) {
      return {
        ...action.payload.categoryData.searchVO,
        snbAudience: action.payload.snbAudience ? action.payload.snbAudience : state.snbAudience,
        preview: action.payload.preview ? action.payload.preview : state.preview,
      };
    },
  },
});

export const {
  filterData,
  pageSizeChange,
  changePage,
  sortData,
  selectStore,
  setSeedData,
  updateInput,
} = inputSlice.actions;

export default inputSlice.reducer;
