export const staticSizes = [
  {
    attrId: '299',
    attrName: 'Size',
  },
  {
    attrId: '5212',
    attrName: 'Mens Shoe Size',
  },
  {
    attrId: '3574',
    attrName: 'Pack Size',
  },
  {
    attrId: '405',
    attrName: 'Sock Size',
  },
  {
    attrId: '5213',
    attrName: 'Womens Shoe Size',
  },
  {
    attrId: '5214',
    attrName: 'Kids Shoe Size',
  },
  {
    attrId: '2544',
    attrName: 'Number of Seats',
  },
  //   {
  //     attrId: '3196',
  //     attrName: 'Sold In',
  //   },
  //   {
  //     attrId: '3197',
  //     attrName: 'Weight Range',
  //   },
  {
    attrId: '4263',
    attrName: 'Bike Frame Size',
  },
  {
    attrId: '3213',
    attrName: 'Hoop Size',
  },
  {
    attrId: '4755',
    attrName: 'Wheel Size',
  },
  {
    attrId: '3999',
    attrName: 'Sleeping Bag Size',
  },
  {
    attrId: '1918',
    attrName: 'Bat Length',
  },
];

export const isSizeAvalable = (ID) => {
  if (ID) {
    return staticSizes.filter((e) => e.attrId === ID).length > 0;
  }
  return false;
};

export const createMultiFacetValue = (facetValue, identifier, gtgtString = 'Guaranteed To Get There') => {
  if (facetValue.value === 'N' && identifier === 'Guaranteed_To_Get_There') {
    // Do not render anything for No for guaranteed to get there
    return;
  }
  if (identifier.indexOf('Color') > -1 || identifier === '3232') {
    if (facetValue && facetValue.value) {
      if (
        facetValue.value.toLowerCase() === 'white' ||
        facetValue.value.toLowerCase() === 'multi'
      ) {
        return facetValue.value;
      }
      return facetValue.value;
    }
  } else if (identifier === 'X_ISA_DSG' || identifier.indexOf('Availability') > -1) {
    if (facetValue.value === 'Y') {
      return facetValue.value;
    }
    return facetValue.value;
  } else if (identifier === 'Guaranteed_To_Get_There') {
    if (facetValue.value === 'N') {
      return null;
    }
    return gtgtString;
  } else {
    return facetValue.value;
  }

  return facetValue.value;
};
