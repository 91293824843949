import React from 'react';
import { useSelector } from 'react-redux';
import {
  breadCrumbDataSelector,
  catTypeStringSelector,
  isFamilyPageSelector,
  searchTermSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';

/**
 * Component that will contain creating breadcrumb for search and plp.
 */

const Breadcrumb = () => {
  const breadCrumbData = useSelector(breadCrumbDataSelector);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const catTypeString = useSelector(catTypeStringSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const searchTerm = useSelector(searchTermSelector);

  /** create data em for family pages breadcrumb */
  const createDataEm = (breadCrumbData) => {
    let dataEm = '';
    if (breadCrumbData) {
      breadCrumbData.forEach((breadCrumb) => {
        const breadcrumbVal = breadCrumb.name;
        dataEm += `_${breadcrumbVal.replace(/\s/g, '')}`;
      });
    }
    return dataEm;
  };

  /** create family page breadcrumb */
  const generateFamilyPageBreadcrumb = (dataEm) => {
    const listItems = [];
    if (breadCrumbData) {
      const breadcrumbLength = breadCrumbData.length - 1;

      return breadCrumbData.map((breadCrumb, idx: number) => {
        const breadcrumbName = breadCrumb.name;
        const breadcrumbUrl = breadCrumb.url;

        if (breadCrumb && breadcrumbName && breadcrumbUrl) {
          return (
            <li
              id=""
              itemType="http://schema.org/ListItem"
              itemProp="itemListElement"
              itemScope
              key={`key_li_family_breadcrumb${breadcrumbUrl}`}
              data-testid={`familyPageBreadcrumb_${breadcrumbName
                .replace(/[^\w]/gi, '_')
                .replace(/(_+)\1+/g, '$1')}`}
              className="rs-breadcrumb-li">
              <span
                className={`rs-breadcrumb-separation ${(storeIdentifierName === 'pl' || storeIdentifierName === 'mj') 
                && 'ellipse'}`}>
                {(storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj') && '|'}
              </span>
              {idx === breadcrumbLength ? (
                <span className="last-breadcrumb-item">
                  <span itemProp="name" key={`key_name_family_breadcrumb${breadcrumbUrl}`}>
                    {breadcrumbName}
                  </span>
                  <link itemProp="item" href={breadcrumbUrl} />
                  <meta content={idx + 2} itemProp="position" />
                </span>
              ) : (
                <a
                  href={breadcrumbUrl}
                  itemProp="item"
                  tabIndex="0"
                  data-em={`Breadcrumb_Home${dataEm}`}
                  data-testid={`familyPageBreadcrumbLink_${breadcrumbName
                    .replace(/[^\w]/gi, '_')
                    .replace(/(_+)\1+/g, '$1')}`}>
                  <span itemProp="name" className="breadcrumb-item">
                    {breadcrumbName}
                  </span>
                  <meta content={idx + 2} itemProp="position" />
                </a>
              )}
            </li>
          );
        }
      });
    }
  };

  const dataEm = createDataEm(breadCrumbData);
  /** derive breadcrumb from breadcrumb data */
  const derivedBreadCrumb = [];
  /** If it is famiy page use breadcrumb data else use entered search term */
  if (isFamilyPage) {
    derivedBreadCrumb.push(
      <ul
        className="dsg-flex justify-content-center"
        key="key_FamilyPage_Breadcrumb"
        data-testid="familyPageBreadcrumbs"
        itemProp="breadcrumb"
        itemScope
        itemType="http://schema.org/BreadcrumbList">
        <li
          id="home"
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
          itemScope
          key="key_li_home_family_breadcrumb"
          data-testid="familyPageBreadcrumbLi-Home"
          className="rs-breadcrumb-li">
          <a
            href="/"
            itemProp="item"
            data-em={`Breadcrumb_Home${dataEm}`}
            key="key_a_home_family_breadcrumb"
            data-testid="familyPageBreadcrumbLink-Home"
            tabIndex="0">
            <span itemProp="name" className="breadcrumb-item" key="key_span_home_family_breadcrumb">
              Home
            </span>
            <meta content="1" itemProp="position" key="key_meta_home_family_breadcrumb" />
          </a>
        </li>
        {!['calia', 'vrst'].includes(storeIdentifierName)
          ? generateFamilyPageBreadcrumb(dataEm)
          : null}
      </ul>,
    );
  } else {
    derivedBreadCrumb.push(
      <ul
        key="key_SearchResult_Breadcrumb"
        data-testid="searchPageBreadcrumbs"
        itemProp="breadcrumb"
        itemScope
        itemType="http://schema.org/BreadcrumbList">
        <li
          id="searchhome"
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
          itemScope
          key="key_searchresult_li_home_breadcrumb"
          data-testid="searchPageBreadcrumbLi-Home"
          className="rs-breadcrumb-li">
          <a
            href="/"
            itemProp="item"
            data-em="Breadcrumb_Home_"
            key="key_searchresult_a_home_breadcrumb"
            data-testid="searchPageBreadcrumbLink-Home"
            tabIndex="0">
            <span itemProp="name" className="breadcrumb-item">
              Home
            </span>
            <meta content="1" itemProp="position" />
          </a>
          <span
            key="key_searchresult_breadcrumb_separation"
            className={`rs-breadcrumb-separation ${(storeIdentifierName === 'pl' || storeIdentifierName === 'mj') && 'ellipse'}`}>
            {(storeIdentifierName !== 'pl' && storeIdentifierName !== 'mj') && '|'}
          </span>
        </li>
        <li
          id="search"
          itemType="http://schema.org/ListItem"
          itemProp="itemListElement"
          itemScope
          key="key_li_search_breadcrumb"
          data-testid="searchPageBreadcrumbSearchTerm"
          className="rs-breadcrumb-li">
          <span itemProp="item">
            <span itemProp="name" className="last-breadcrumb-item hmf-display-flex hmf-mr-s">
              Search: <span>{searchTerm}</span>
            </span>
            <meta content="2" itemProp="position" />
          </span>
        </li>
      </ul>,
    );
  }

  return <div className="rs-breadcrumb-wrapper">{derivedBreadCrumb}</div>;
};

export default Breadcrumb;
