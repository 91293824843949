import React, { Suspense, useEffect, useState } from 'react';
import { useSelector, useDispatch, shallowEqual, batch } from 'react-redux';
import lazy from 'react-lazy-ssr';
import ErrorBoundary from './common/ErrorBoundary/ErrorBoundary';
import HeaderSection from './HeaderSection/HeaderSection';
import GridRowOne from './GridRowOne/GridRowOne';
import GridRowTwo from './GridRowTwo/GridRowTwo';
import LoadingOverlay from './common/LoadingOverlay/LoadingOverlay';
import CeopsScripts from './common/CEOPSscripts/CeopsScripts';
import FilterFeedback from './FilterFeedback/filter-feedback';
import Sorting from './GridRowOne/Sorting/Sorting';

const Banner = lazy(() => import(/* webpackChunkName: "Banner" */ './Banner/Banner'), {
  chunkName: 'Banner',
  noSsr: true,
});
const CertonaRecommendation = lazy(
  () =>
    import(
      /* webpackChunkName: "CertonaRecommendation" */ './ProductAdsBelowGrid/CertonaRecommendations'
    ),
  { chunkName: 'CertonaRecommendation', noSsr: true },
);
const PromoteIQ = lazy(
  () => import(/* webpackChunkName: "PromoteIQ" */ './ProductAdsBelowGrid/PromoteIQ'),
  { chunkName: 'PromoteIQ', noSsr: true },
);
const ProTipsCarousel = lazy(
  () => import(/* webpackChunkName: "ProTipsCarousel" */ './ProTipsCarousel/ProTipsCarousel'),
  {
    chunkName: 'ProTipsCarousel',
  },
);
const SEOData = lazy(() => import(/* webpackChunkName: "SEOData" */ './SEOData/SEOData'), {
  chunkName: 'SEOData',
});
const GoogleAdContainer = lazy(
  () =>
    import(
      /* webpackChunkName: "GoogleAdContainer" */ './GridRowTwo/GoogleAdContainer/GoogleAdContainer'
    ),
  {
    chunkName: 'GoogleAdContainer',
    noSsr: true,
  },
);

import {
  getParameterByName,
  decodeAndReplaceHTML,
  initStickyClasses,
  getStoreIdentifierNameFromVh,
  getBreak,
} from '../utility/utils';
import { getCookie, deleteCookie } from '../utility/cookie-utils';
import {
  fetchSearchTermData,
  fetchSelectedCategory,
  onSelectStore,
  seedFilters,
} from '../reduxStore/thunks/index';
import { setBreakPoint } from '../reduxStore/slices/common';
import { selectStore } from '../reduxStore/slices/input';
import {
  breakpointSelector,
  categoryAttrVoDataSelector,
  categoryIdentifierSelector,
  catTypeStringSelector,
  dsgVhSelector,
  isSearchingSelector,
  isFamilyPageSelector,
  selectedFiltersSelector,
  selectedStoreSelector,
  totalCountSelector,
  landingPageUrlSelector,
  myStoreZeroSelector,
  priceFilterZeroSelector,
  familyPageNameSelector,
  searchTermSelector,
  productDataSourceSelector,
  snbAudienceSelector,
  getFeatureFlagByNameSelector,
} from '../reduxStore/selectors/index';
import useWindowVariables from '../hooks/useWindowVariables';
import useFavorites from '../hooks/useFavorites';
const ProductGridTemplate = () => {
  const dispatch = useDispatch();

  // Common Slice Global State Selectors
  const breakpoint = useSelector(breakpointSelector);
  const dsgVh = useSelector(dsgVhSelector);
  const categoryIdentifier = useSelector(categoryIdentifierSelector);
  const catTypeString = useSelector(catTypeStringSelector);
  const isSearching = useSelector(isSearchingSelector);
  const familyPageName = useSelector(familyPageNameSelector);
  const productDataSource = useSelector(productDataSourceSelector);
  const retailMediaNetworkEnabled = useSelector(
    getFeatureFlagByNameSelector('retailMediaNetworkEnabled'),
  );
  const addFavoritesEnabled = useSelector(getFeatureFlagByNameSelector('addFavoritesEnabled'));
  const listPanelEnabled = useSelector(getFeatureFlagByNameSelector('listPanelEnabled'));

  // Input Slice Global State Selectors
  const totalCount = useSelector(totalCountSelector);
  const isFamilyPage = useSelector(isFamilyPageSelector);
  const selectedStore = useSelector(selectedStoreSelector);
  const selectedFilters = useSelector(selectedFiltersSelector);
  const searchTerm = useSelector(searchTermSelector);
  const snbAudience = useSelector(snbAudienceSelector);

  // ProductAPIData Slice Global State Selectors
  const priceFilterZero = useSelector(priceFilterZeroSelector);
  const myStoreZero = useSelector(myStoreZeroSelector);
  const landingPageURL = useSelector(landingPageUrlSelector);

  useWindowVariables();
  // Handling redirecting to landingPageUrl
  if (landingPageURL) {
    if (landingPageURL.toLowerCase().includes('www.')) {
      if (landingPageURL.toLowerCase().includes('http')) {
        window.location.assign(landingPageURL);
      } else {
        window.location.assign(`https://${landingPageURL}`);
      }
    } else if (!landingPageURL.startsWith('/')) {
      window.location.href = landingPageURL;
    } else {
      window.location.href = `${window.location.protocol}//${window.location.hostname}${landingPageURL}`;
    }

    return null;
  }

  const storeName = getStoreIdentifierNameFromVh(dsgVh);
  const isMyStoreNoResultsCase = totalCount === 0 && (myStoreZero || priceFilterZero);
  const selectedFiltersCheck = selectedFilters && Object.keys(selectedFilters).length !== 0;
  const { fetchFavorites, fetchFavoritesFromLists } = useFavorites(storeName);

  useEffect(() => {
    if (addFavoritesEnabled) {
      //call user favorites
      dispatch(fetchFavorites());
      window.addEventListener('updateFavoritesStoreData', (event) => {
        if (event.detail !== 'PLP') {
          if (listPanelEnabled) {
            return fetchFavoritesFromLists();
          }

          dispatch(fetchFavorites());
        }
      });
    }
  }, [addFavoritesEnabled, listPanelEnabled]);

  useEffect(() => {
    if (listPanelEnabled) {
      // Call list of ecodes from multi lists
      dispatch(fetchFavoritesFromLists());
      window.addEventListener('updateFavoritesListStoreData', () => 
        dispatch(fetchFavoritesFromLists())
      );
    }
  }, [listPanelEnabled]);

  useEffect(() => {
    window.addEventListener('HeaderServices:StoreSelection:Change', ({ detail }) => {
      dispatch(
        selectStore(
          detail.location ? detail.location : detail.number ? detail.number : detail.store,
        ),
      );
      dispatch(onSelectStore(detail));
    });
  }, []);

  useEffect(() => {
    initStickyClasses(snbAudience === 'improvedProductGrid');
    deleteCookie('enterSTerm');
    deleteCookie('enterSType');
    dispatch(setBreakPoint(getBreak()));

    const handlePopState = () => {
      // Handle seeding filters from URL when browser history changes
      // this.performSearch = true;
      dispatch(seedFilters(false));
    };

    const handleScreenResize = () => {
      // Update screen breakpoint size on browser resize
      dispatch(setBreakPoint(getBreak()));
    };

    // Handling setting store and seeding filters
    const storeCookieVal = getCookie('setStoreCookie');
    let selectedStoreValue: string = '';
    if (storeCookieVal) {
      selectedStoreValue = decodeAndReplaceHTML(storeCookieVal).split('_')[2];
      batch(() => {
        dispatch(selectStore(selectedStoreValue));
        dispatch(seedFilters(true));
      });
    } else {
      dispatch(seedFilters(true));
    }

    if (isFamilyPage) {
      // Initial client side data refetch for family page
      if (selectedStoreValue) {
        dispatch(fetchSelectedCategory(selectedStoreValue, true));
      } else {
        dispatch(fetchSelectedCategory(selectedStore, true));
      }
    } else {
      // Get searchTerm query param from URL and perform client side initial search call
      let searchValue: string = getParameterByName('searchTerm');
      if (searchValue) {
        dispatch(fetchSearchTermData(searchValue, true, false));
      }
    }

    if (typeof window !== 'undefined') {
      window.addEventListener('popstate', handlePopState);
      window.addEventListener('resize', handleScreenResize);
    }

    return () => {
      // Cleanup handlePopState and handleScreenResize event listeners
      window.removeEventListener('popstate', handlePopState);
      window.removeEventListener('resize', handleScreenResize);
    };
  }, []);

  return (
    <div
      className={`plp-srlp-bootstrap-scope ${(totalCount > 0 || isSearching) &&
        'prod-grid-mh'} product-grid-container dsg-react-container`}>
      <CeopsScripts storeVH={dsgVh} />
      {isSearching && <LoadingOverlay />}

      {/* Header section component contains Breadcrumb, Heading and carousel shows top categories */}
      <div className="dsg-flex flex-column rs-header-section">
        <HeaderSection
          totalCount={totalCount}
          selectedFiltersCheck={selectedFiltersCheck}
          isSearching={isSearching}
        />
      </div>
      <div
        className="dsg-flex flex-column topEspotContainerDiv"
        id={`${categoryIdentifier}Row1_Content`}>
        <ErrorBoundary>
          <Suspense fallback="">
            <Banner isFamilyPage={isFamilyPage} breakpoint={breakpoint} chain={storeName} />
          </Suspense>
        </ErrorBoundary>
      </div>
      {(totalCount || isMyStoreNoResultsCase) && (
        <>
          {/* 
              These divs are the core foundation of the sticky logic for the filter top header and the side filter facets.
              The sticky anchor is the div used as the main anchor point which is monitored to determine when the sticky elements should become sticky.
              The sticky ghost div acts to take the place of the filter top headers height once it becomes sticky to prevent a page jump.
             */}
          <div id="dsg-react-sticky-anchor" />
          <div id="dsg-react-sticky-ghost-div" />
          {/* Component contains filters heading and open & close filter action,store pickup and sort */}

          {totalCount > 0 &&
            catTypeString !== 'C' &&
            (!snbAudience || snbAudience !== 'improvedProductGrid') && (
              <div>
                <div className="rs-filter-sort-row dsg-flex dsg-sticky">
                  <GridRowOne breakpoint={breakpoint} />
                </div>
                {breakpoint !== 'desktop' && breakpoint !== 'small-desktop' && (
                  <div className="rs-filter-sort-row dsg-flex">
                    <Sorting />
                  </div>
                )}
              </div>
            )}

          {/* Component contains facet cards, product cards, total count and selected chips */}
          <div
            className={`rs-facet-product-cards dsg-flex ${
              snbAudience && snbAudience === 'improvedProductGrid' ? 'topBorder' : ''
            }`}>
            <GridRowTwo />
          </div>
        </>
      )}

      {!['calia', 'vrst', 'g3'].includes(storeName) && (
        /* No Certona or PromoteIQ on Calia, VRST, or G3 microsites */
        <div>
          <div className="rs_product_listing_container">
            <ErrorBoundary>
              <Suspense fallback="">
                <CertonaRecommendation
                  isFamilyPage={isFamilyPage}
                  storeIdentifierName={storeName}
                  searchResults={totalCount || isMyStoreNoResultsCase}
                />
              </Suspense>
            </ErrorBoundary>
            {storeName === 'dsg' && (
              <ErrorBoundary>
                <Suspense fallback="">
                  <PromoteIQ />
                </Suspense>
              </ErrorBoundary>
            )}
            {retailMediaNetworkEnabled && (
              <ErrorBoundary>
                <Suspense fallback="">
                  <GoogleAdContainer id="bottom_banner" />
                </Suspense>
              </ErrorBoundary>
            )}
          </div>
        </div>
      )}

      {['dsg', 'gg', 'fns', 'pl', 'mj'].includes(storeName) ? (
        /* Only Renders ProTipsCarousel and SEOData sections on DSG, GG, FNS family and search pages. */
        <div id="res-searchDex">
          <ErrorBoundary>
            <Suspense fallback="">
              <ProTipsCarousel storeIdentifierName={storeName} />
            </Suspense>
          </ErrorBoundary>
          <ErrorBoundary>
            <Suspense fallback="">
              <SEOData />
            </Suspense>
          </ErrorBoundary>
        </div>
      ) : null}
    </div>
  );
};

export default ProductGridTemplate;
