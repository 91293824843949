import { getItemAvailabilityData } from '../reduxStore/thunks/utility/thunk-utils';
import { getCookie } from './cookie-utils';
import { getStoreIdentifierNameFromVh } from './utils';

export function updateRequestAndHeadersWithAudienceDetails(
  request,
  productApiHeaders,
  snbAudience,
  audiencePools,
  storeIdentifier,
) {
  // Add SNB Audience parameter to search api call (Both search and family pages)
  if (snbAudience) {
    request.snbAudience = snbAudience;
    // This header is purely for akamai routing
    productApiHeaders['snb-audience-routing'] = snbAudience;
  } else {
    request.snbAudience = '';
  }

  if (audiencePools && Array.isArray(audiencePools)) {
    productApiHeaders['pool-c-swimlane'] = audiencePools[2];
  }

  if (isRisaEnabledForStore(storeIdentifier)) {
    request.zipcode = getZipCode(request);
  }
}

export function shouldRenderProductCardItemAvailability(dsgVh) {
  const storeIdentifier = getStoreIdentifierNameFromVh(dsgVh);

  return (dispatch, getState) => {
    if (isRisaEnabledForStore(storeIdentifier)) {
      // Make request to Product API /v1/inventory/messages endpoint to request Item Availability data
      getItemAvailabilityData(dispatch, getState, getZipCode());
    }
  };
}

export function getZipCode(request) {
  if (
    request &&
    request.selectedFilters &&
    request.selectedFilters.facetStore &&
    request.selectedFilters.facetStore.includes('SDD')
  ) {
    let sddZip = getCookie('deliveryZip');
    return sddZip;
  }
  // Get zip code from myStoreAddress cookie
  let myStoreAddress = getCookie('setStoreCookie');
  if (myStoreAddress && typeof myStoreAddress === 'string' && myStoreAddress.length > 5) {
    myStoreAddress = myStoreAddress.split('_')[0];
  }
  return myStoreAddress;
}

export function isRisaEnabledForStore(storeIdentifier) {
  return ['dsg', 'calia', 'vrst'].includes(storeIdentifier);
}
