import { useEffect } from 'react';
import { useAppDispatch } from '../reduxStore/store';
import {
  featureFlagsSelector,
  windowVariablesSelector,
} from '../reduxStore/selectors/commonSelector';
import { setWindowVariables } from '../reduxStore/slices/common';
import { useSelector } from 'react-redux';

/**
 * This custom hook handles listening and grabbing values from window variables on the page and loading them into Redux.
 * This hook should only ever be used invoked once and should only reside in the top level ProductGridTemplate component.
 */

const useWindowVariables = () => {
  const dispatch = useAppDispatch();
  const windowVariables = useSelector(windowVariablesSelector);
  const featureFlags = useSelector(featureFlagsSelector);

  const getWindowVariableChanges = () => {
    let newWindowVariables = {};
    Object.keys(featureFlags).forEach((featureFlagName) => {
      if (window[featureFlagName] !== windowVariables[featureFlagName]) {
        newWindowVariables = { ...newWindowVariables, [featureFlagName]: window[featureFlagName] };
      }
    });

    if (Object.keys(newWindowVariables).length > 0) {
      dispatch(setWindowVariables({ ...windowVariables, ...newWindowVariables }));
    }
  };

  useEffect(() => {
    document.addEventListener('WindowFlagsEvent', getWindowVariableChanges);
    window.addEventListener('load', getWindowVariableChanges);
  }, []);

  return null;
};

export default useWindowVariables;
