import React, { useEffect, useState } from 'react';

import PropTypes from 'prop-types';
import { handleADA } from '../../../utility/utils';
import multiswatch from '../../../../public/ecom-static-content/images/multiswatch.png';

const Checkbox = ({
  facetName,
  facetCount,
  disabled,
  labelledby,
  checkboxId,
  onCheckboxToggle,
  facet,
  selectedData,
  facetValue,
  ariaLabel = '',
}) => {
  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (selectedData && selectedData.indexOf(facetValue.value) > -1) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedData, facetValue]);

  const onCheckHandler = (evt) => {
    if (disabled) {
      return;
    }
    setIsChecked(true);
    onCheckboxToggle(evt);
  };

  const CheckboxValue = (e) => {
    if (facet === 'Color') {
      if (facetName === 'White') {
        return (
          <span
            className={`dsg-color-checkmark-wrapper  `}
            style={{ backgroundColor: facetName, border: '#a7a7a7 1px solid' }}
          />
        );
      }
      if (facetName === 'Multi') {
        return (
          <img
            src={multiswatch}
            height="20"
            width="20"
            alt="Multi Swatch"
            className="dsg-color-checkmark-wrapper"
            loading="lazy"
          />
        );
      }
      return (
        <span className={`dsg-color-checkmark-wrapper  `} style={{ backgroundColor: facetName }} />
      );
    }
    return <span className="dsg-checkmark-wrapper" />;
  };

  return (
    <div
      className={`dsg-checkbox-container-wrapper dsg-flex flex-row align-items-center ${
        isChecked ? 'checked' : ''
      }`}
      tabIndex={0}
      data-testid="checkbox"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onCheckHandler}
      onKeyPress={(e) => handleADA(e, 'button', onCheckHandler)}
      alt={typeof facetName === 'string' ? facetName : ariaLabel}
      role="checkbox"
      aria-checked={isChecked}
      aria-labelledby={labelledby}>
      <input
        type="checkbox"
        id={checkboxId}
        title={typeof facetName === 'string' ? facetName : ariaLabel}
        checked={isChecked}
        onChange={onCheckHandler}
        tabIndex={-1}
        aria-hidden={true}
        disabled={true}
      />
      <CheckboxValue facet={facet} />
      <label
        id={labelledby}
        htmlFor={checkboxId}
        className="rs-facet-title"
        title={typeof facetName === 'string' ? facetName : ariaLabel}>
        {facetName}
      </label>
      {facetCount !== null ? `(${facetCount})` : ''}
    </div>
  );
};

Checkbox.defaultProps = {
  size: 'normal',
  labelledby: null,
};

Checkbox.propTypes = {
  size: PropTypes.oneOf(['normal', 'large']), // Size of checkbox
  disabled: PropTypes.bool, // Disabled flag
  onCheckboxToggle: PropTypes.func.isRequired, // Function called when checkbox is toggled
  isCheckedValue: PropTypes.bool, // Is checked or is not checked
  checkboxId: PropTypes.string.isRequired, // The id for the checkbox and the label to make the label for the checkbox
  labelledby: PropTypes.string,
};

export default Checkbox;
