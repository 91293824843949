import React from 'react';
import { handleADA } from '../../../utility/utils';

const CarouselArrow = ({ className, onClick }) => {
  return (
    <div
      className={`dsg-react-carousel-arrow ${className}`}
      onClick={onClick}
      onKeyPress={(e) => {
        handleADA(e, 'button', onClick);
      }}
      tabIndex={0}
      role="button">
      <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.66673 0L0.0217285 1.645L5.36506 7L0.0217285 12.355L1.66673 14L8.66673 7L1.66673 0Z"
          fill="white"
        />
      </svg>
    </div>
  );
};

export default CarouselArrow;
