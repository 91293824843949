import { createSlice } from '@reduxjs/toolkit';
import { CommonState } from '../../types/index.js';
import { setCookie } from '../../utility/cookie-utils.jsx';
import { updateUrlFilters } from '../../utility/history.js';
import getFeatureFlagsByEnvironment from '../../config/featureFlags';

export const initialState = {
  breakpoint: 'desktop',
  showMobileFilterFacetModal: false,
  pageTitle: null,
  seoFiltersApplied: false,
  featureFlags: getFeatureFlagsByEnvironment(
    (typeof window !== 'undefined' && window.REACT_PLP_ENV) || 'perfredesign',
  ),
  windowVariables: {},
  isAdSpotsReady: false,
} as CommonState;

const commonSlice = createSlice({
  name: 'common',
  initialState,
  reducers: {
    createAnalyticData(state, action) {
      return { ...state };
    },
    setBreakPoint(state, action) {
      return { ...state, breakpoint: action.payload };
    },
    updateCommon(state, action) {
      return {
        ...state,
        selectedSwatchVal: {},
        error: false,
        mlGroup: action.payload.responseData.mlGroup,
        productDataSource: 'client',
      };
    },
    initializeSession(state, action) {
      return {
        ...state,
        breadCrumbData: action.payload.breadCrumbData,
        catgroupId: action.payload.catgroupId,
        categoryIdentifier: action.payload.categoryIdentifier,
        familyPageName: action.payload.familyPageName,
        categoryAttrVoData: action.payload.categoryAttrVoData,
        selectedSwatchVal: {},
        dsgVh: action.payload.dsgVh,
        storeIdentifierName: action.payload.storeIdentifierName,
        catTypeString: action.payload.catTypeString,
        requestSeoUrl: action.payload.requestSeoUrl,
        productDataSource: 'server',
        defaultSort: action.payload.defaultSort,
        primaryL1: action.payload.primaryL1,
        featureFlags: action.payload.featureFlags,
      };
    },
    updateDsgvh(state, action) {
      return { ...state, dsgVh: action.payload };
    },
    onSwatchChange(state, action) {
      setCookie('swColor', encodeURIComponent(action.payload.swatchColor));
      return {
        ...state,
        selectedSwatchVal: {
          swatchColor: action.payload.swatchColor,
          thumbnail: action.payload.thumbnail,
        },
      };
    },
    setPromotionResponseSuccess(state, action) {
      return { ...state, promotionData: action.payload };
    },
    setPinnedContent(state, action) {
      return { ...state, pinnedContent: action.payload };
    },
    setPiqProducts(state, action) {
      return { ...state, piqProducts: action.payload };
    },
    setItemAvailability(state, action) {
      return { ...state, itemAvailability: action.payload };
    },
    toggleMobileFilterFacetMobile(state) {
      return { ...state, showMobileFilterFacetModal: !state.showMobileFilterFacetModal };
    },
    setAPIResponseSuccess(state, action) {
      updateUrlFilters(action.payload.responseData.searchVO.selectedFilters);
      let pageTitle;
      if (
        action.payload.responseData.didYouMeanVO &&
        action.payload.responseData.didYouMeanVO.suggestions &&
        action.payload.responseData.mlSpelling
      ) {
        pageTitle = action.payload.responseData.didYouMeanVO.suggestions[0];
      } else if (
        action.payload.responseData.searchVO &&
        action.payload.responseData.searchVO.isFamilyPage &&
        state.familyPageName
      ) {
        pageTitle = state.familyPageName;
      } else if (
        action.payload.responseData.searchVO &&
        action.payload.responseData.searchVO.searchTerm
      ) {
        pageTitle = action.payload.responseData.searchVO.searchTerm;
      }
      return {
        ...state,
        isSearching: false,
        error: false,
        pageTitle,
        mlSearchUUID: action.payload.mlSearchUUID,
      };
    },
    setErrorResponse(state, action) {
      return {
        ...state,
        isSearching: false,
        error: action.payload,
      };
    },
    isSearching(state) {
      return { ...state, isSearching: true };
    },
    setSeoFiltersApplied(state, action) {
      return {
        ...state,
        seoFiltersApplied: action.payload,
      };
    },
    setWindowVariables(state, action) {
      return { ...state, windowVariables: { ...state.windowVariables, ...action.payload } };
    },
    isAdSpotsReady(state, action) {
      return { ...state, isAdSpotsReady: action.payload };
    }
  },
});

export const {
  createAnalyticData,
  setBreakPoint,
  initializeSession,
  onSwatchChange,
  setPromotionResponseSuccess,
  setPinnedContent,
  setPiqProducts,
  setItemAvailability,
  toggleMobileFilterFacetMobile,
  updateDsgvh,
  updateCommon,
  setAPIResponseSuccess,
  setErrorResponse,
  isSearching,
  setMyStore,
  clearMyStoreModal,
  toggleMyStoreModal,
  setSeoFiltersApplied,
  setWindowVariables,
  isAdSpotsReady,
} = commonSlice.actions;

export default commonSlice.reducer;
