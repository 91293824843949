import React from 'react';

type Props = {
  errorMessage?: string;
};

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends React.Component<Props> {
  state: State = {
    hasError: false,
  };

  static getDerivedStateFromError(error: Error) {
    console.error(error);
    return { hasError: true };
  }

  render() {
    if (this.state.hasError) {
      return this.props.errorMessage ? this.props.errorMessage : null;
    }

    return this.props.children;
  }
}
