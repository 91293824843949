import React, { useEffect, Suspense, useRef, useState } from 'react';
import Slider from 'react-slick';
import { useDispatch, useSelector } from 'react-redux';
import {
  convert24HourTo12HourTime,
  createFormatedStoreHoursObject,
  getDayOfWeekWithOffset,
  getHoursFromMilitaryTime,
} from '../store-hour-utilities';
import { applyFilter, handleMobileFilterFacetModalClick } from '../../../reduxStore/thunks';
import { deepCompareWithStringify, handleADA } from '../../../utility/utils';
import { clearFiltersByKey, onClearSelectedStore, onSelectStore } from '../../../reduxStore/thunks';
import { default as CaliaFilterIcon } from '../../common/SVGIcons/Calia/FilterIcon';
import PLFilterIcon from '../../common/SVGIcons/PublicLands/PLFilterIcon';
import SettingsIcon from '../../common/SVGIcons/SettingsIcon';
import { changePreferredStoreEvent } from '../../../utility/analytics-events';
import {
  breakpointSelector,
  selectedFiltersSelector,
  selectedStoreSelector,
} from '../../../reduxStore/selectors';
import VehicleCarIcon from '../../common/SVGIcons/VehicleCarIcon';
import BopisIcon from '../../common/SVGIcons/BopisIcon';
import ShipIcon from '../../common/SVGIcons/ShipIcon';
import {
  deleteCookie,
  getAddress,
  getCookie,
  setAddress,
  setStoreCookie,
} from '../../../utility/cookie-utils';
import { decodeAndReplaceHTML } from '../../../utility/utils';
import Link from '../../common/Link/Link';
import { StoreInfo } from '../../../types';
import axios from 'axios';
import Checkbox from '../../common/FacetCheckBox/FacetCheckBox';
import { updateFilter } from '../../../utility/history';

const MobileShippingOptions = () => {
  const dispatch = useDispatch();
  const selectedStore = useSelector(selectedStoreSelector);
  const [myStoreIndicator, setMyStoreIndicator] = useState<string>('');
  const [selectedFilterCount, setSelectedFilterCount] = useState<number>(0);
  const selectedFilters = useSelector(selectedFiltersSelector, deepCompareWithStringify);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const [selectedStoreHours, setSelectedStoreHours] = useState<{ open: string; close: string }>();
  const breakpoint = useSelector(breakpointSelector);
  const selectedData = selectedFilters.facetStore;
  const onSelection = undefined;
  const selectedStoreNameLabel = selectedStoreName || 'Select a nearby store';

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const storeAriaLabel = {
    SHIP: 'Ship To Me',
    BOPIS: 'In Store Pickup',
    ISA: 'Buy at the Store',
  };

  const LabelRef = {
    SHIP: (
      <div className="dsg-react-mobile-shipping-options-shipping-option">
        <ShipIcon />
        <span>Shipping</span>
      </div>
    ),
    BOPIS: (
      <div className="dsg-react-mobile-shipping-options-shipping-option">
        <VehicleCarIcon />
        <span>Picking Up</span>
      </div>
    ),
    ISA: (
      <div className="dsg-react-mobile-shipping-options-shipping-option">
        <BopisIcon />
        <span>Shopping In-Store</span>
      </div>
    ),
  };

  const options = {
    attrIdentifier: 'facetStore',
    attrName: 'Shipping & Pickup',
    valueList: [
      { count: null, value: 'SHIP' },
      { count: null, value: 'BOPIS' },
      { count: null, value: 'ISA' },
    ],
  };

  const createValues = (valueList, identifier, selectedData) => {
    return valueList.map((option, i) => {
      const isChecked = selectedData && selectedData.indexOf(option.value) > -1;
      const disabled = !isChecked && option.count !== undefined && option.count === 0;

      return (
        <Checkbox
          key={`${identifier}_${i}`}
          checkboxId={`checkbox_${identifier}_${i}`}
          onCheckboxToggle={createOnFilterChangeCallback(option.value, disabled)}
          disabled={disabled}
          labelledby={`store_select_facet_value_${option.value.replace(/[^A-Z0-9]/gi, '_')}`}
          facetName={LabelRef[option.value]}
          facetCount={option.count}
          breakpoint={breakpoint}
          selectedData={selectedData}
          facetValue={option}
          isCheckedValue={isChecked}
          ariaLabel={storeAriaLabel[option.value]}
        />
      );
    });
  };

  const settings = {
    className: 'dsg-react-mobile-shipping-options-carousel',
    dots: false,
    infinite: false,
    lazyLoad: false,
    speed: 500,
    variableWidth: true,
    focusOnClick: true,
    focusOnSelect: true,
    arrows: false,
  };

  const [valueList, setValueList] = useState(
    createValues(options.valueList, options.attrIdentifier, selectedData),
  );

  useEffect(() => {
    const createList = createValues(options.valueList, options.attrIdentifier, selectedData);
    if (options) {
      setValueList(createList);
    }
  }, [selectedData, selectedStoreNameLabel]);

  useEffect(() => {
    if (selectedFilters) {
      let count = 0;

      selectedFilters.map;

      Object.keys(selectedFilters).map((key) => {
        count += selectedFilters[key].length;
      });

      setSelectedFilterCount(count);
    }
  }, [selectedFilters]);

  useEffect(() => {
    const storeCookieVal = getCookie('setStoreCookie');
    const selectedStoreValue = decodeAndReplaceHTML(storeCookieVal).split('_')[1];
    const STORE_INFO_URL = 'https://availability.dickssportinggoods.com/api/v1/stores/';

    if (selectedStoreName !== selectedStoreValue) {
      if (storeCookieVal) {
        setSelectedStoreName(selectedStoreValue);

        axios(STORE_INFO_URL + selectedStore, {
          method: 'GET',
          timeout: 5000,
          headers: {
            'Content-Type': 'application/json',
          },
        })
          .then((response) => {
            if (response.status === 200 && response.data) {
              setSelectedStoreHours(response.data.hours);
            } else {
              console.log(
                `Store number ${selectedStore} is invalid. Please enter correct store number.`,
              );
            }
          })
          .catch((error) => {
            console.log(
              `Store number ${selectedStore} is invalid. Please enter correct store number.`,
            );
          });
      } else {
        setSelectedStoreName('');
        setSelectedStoreHours(undefined);
      }
    }
  }, [selectedStore]);

  const onStoreSelection = (store: StoreInfo) => {
    // Update cookie information first
    // set the cookie setStoreCookie
    // 15241_SOUTH%2520HILLS_638 - postalcode_name_clientkey
    setStoreCookie(store.zip, store.name, store.location);
    setAddress(store.zip);

    // Fire ChangePreferredStore Event
    changePreferredStoreEvent(store.location);

    // Set store selection info. The fetching of data will be handled by componentWillReceiveProps
    const selectedStoreInfo = store.location;
    dispatch(onSelectStore(selectedStoreInfo));
  };

  const handleToggleStorePickupModal = (event: React.MouseEvent) => {
    if (
      typeof window !== 'undefined' &&
      window.headerFunctions &&
      window.headerFunctions.storeSelection
    ) {
      window.headerFunctions.storeSelection({ callback: (store) => onStoreSelection(store) });
    }
  };

  const onFilterData = (identifier: string, values: string[], facetType: string) => {
    dispatch(applyFilter(identifier, values, facetType));
  };

  const onFilterChange = (val) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = options.attrIdentifier;
    const values = [];
    if (selectedData && selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'multi');
    updateFilter(identifier, values, 'multi');
  };

  return (
    <>
      <div id="dsg-react-mobile-shipping-options-ghost-div" />
      <div
        className={`dsg-flex dsg-react-mobile-shipping-options dsg-sticky ${
          (selectedData && selectedData.includes('BOPIS')) ||
          (selectedData && selectedData.includes('ISA'))
            ? 'visible-store-selection'
            : ''
        }`}>
        <p className="dsg-react-mobile-shipping-options-header">How are you shopping today?</p>
        <Slider {...settings}>{valueList}</Slider>
        {(selectedData && selectedData.includes('BOPIS')) ||
        (selectedData && selectedData.includes('ISA')) ? (
          <div className="dsg-react-mobile-shipping-options-my-store">
            <div
              className={`dsg-react-mobile-shipping-options-store-info dsg-flex align-items-center ${myStoreIndicator}`}>
              <p>
                <span>My Store • </span>
                <Link
                  text={selectedStoreName ? `${selectedStoreName.toLowerCase()}` : ` Find A Store`}
                  classes={['dsg-react-mobile-shipping-options-store-link']}
                  tabIndex={0}
                  ariaLabel={
                    selectedStoreName && selectedStoreName != 'Select a nearby store'
                      ? `Change selected store from ${selectedStoreName}`
                      : `No active store selected. Click to Select a store`
                  }
                  onClick={handleToggleStorePickupModal}
                  onKeyPress={(e) => handleADA(e, 'button', handleToggleStorePickupModal)}
                  inline={false}
                  secondary
                />
              </p>
            </div>
          </div>
        ) : null}
      </div>
    </>
  );
};

export default MobileShippingOptions;
