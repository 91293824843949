import { getBreadcrumbContainerTop, setScrollPosition } from '../../utility/utils';
import { resultsViewUpdate } from '../plp-srlp-analytics';
import { changePage } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

export function onChangePage(pageNumber) {
  const values = getBreadcrumbContainerTop();
  return (dispatch, getState) => {
    if (values) {
      setScrollPosition(0, values.top);
    }

    dispatch(isSearching());
    dispatch(changePage(pageNumber));

    const stateData = getState();
    const { input } = stateData;
    let newPageNumber;

    if (pageNumber !== undefined && pageNumber !== null) {
      newPageNumber = parseInt(pageNumber, 10) + 1;
    }

    const refinementUpdateObj = { UpdateType: 'Pagination', UpdateValue: newPageNumber };
    resultsViewUpdate(refinementUpdateObj);

    callAPI(input, dispatch, stateData);
  };
}
