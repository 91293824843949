import axios from 'axios';
import { getPromoteIQUrl } from '../../components/ProductAdsBelowGrid/utility/productAdConstants';
import {
  getEnvironment,
  serialize,
} from '../../components/ProductAdsBelowGrid/utility/productAdUtils';
import { v4 as uuidv4 } from 'uuid';
import { setPiqProducts } from '../slices/common';
import { getInGridSlotID } from '../../components/GridRowTwo/PiqProduct/utility/piq-utils';
import { getBreak } from '../../utility/utils';

export function getPiqProducts(count: number, audience: number, category: string | null) {
  return (dispatch, getState) => {
    try {
      const breakpoint = getBreak();
      const { isFamilyPage } = getState().input;
      const { top10 } = getState().productApiData;

      const piqProductHost = getPromoteIQUrl(getEnvironment());

      const params = serialize({
        id: uuidv4(),
        slot: getInGridSlotID(isFamilyPage, breakpoint),
        session: _dsgTag.CustomerIDs.AdobeMCVID,
        ...(_dsgTag.CustomerIDs.AthleteID && { user: _dsgTag.CustomerIDs.AthleteID }),
        count: count,
        count_fill: count,
        targets: serialize({
          match_products: top10,
        }),
        audience_id: audience,
        ...(category && { category_id: category }),
      });

      return axios
        .get(`${piqProductHost}?${params}`)
        .then((response) => {
          dispatch(setPiqProducts(response.data));
        })
        .catch((error) => {
          console.log(error);
        });
    } catch (e) {
      console.log(`Promote IQ Content general error: ${e.message}`);
      return false;
    }
  };
}
