import React, { MouseEventHandler, useState } from 'react';
import PropTypes from 'prop-types';
import { updateFilter } from '../../../../utility/history';
import { toggleOpenCloseFacet, handleADA } from '../../../../utility/utils';
import RemoveCircleIcon from '../../../common/SVGIcons/RemoveCircleIcon';
import Checkbox from '../../../common/FacetCheckBox/FacetCheckBox';
import Button from '../../../common/buttons/Button';
import CreateFacetContainer from './CreateFacetContainer';
import { FacetVO, FacetVOsValueList } from '../../../../types';
import { useSelector } from 'react-redux';
import { getFeatureFlagByNameSelector } from '../../../../reduxStore/selectors';
// Component that will create price select facet cards

type Props = {
  facet: FacetVO;
  openFacet: boolean;
  storeName: string;
  onSelection?: () => void;
  selectedData: string[];
  onFilterData: (identifier: string, values: string[], facetType: string) => void;
};

const PriceSelectFacet = ({
  facet,
  openFacet,
  storeName,
  selectedData,
  onFilterData,
  onSelection,
}: Props) => {
  const [fromVal, setFromVal] = useState<number>(0);
  const [toVal, setToVal] = useState<number>(0);
  const [btnClass, setBtnClass] = useState(true);
  const [facetExpanded, setfacetExpanded] = useState(openFacet);
  const [priceOutOfRangeMessage, setPriceOutOfRangeMessage] = useState('');
  const isMultiSelectPriceFilterEnabled = useSelector(
    getFeatureFlagByNameSelector('isMultiSelectPriceFilterEnabled'),
  );

  const onFilterChange = (val: string) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    const type = isMultiSelectPriceFilterEnabled ? 'multi' : 'single';
    onFilterData(identifier, values, type);
    updateFilter(identifier, values, type);
  };

  const createOnFilterCallback = () => {
    let isMaxPriceAbove = false;
    if ((fromVal !== 0 && !Number(fromVal)) || !Number(toVal)) {
      setPriceOutOfRangeMessage('Please enter min and max values.');
      return;
    }
    if (Number(fromVal) >= Number(toVal)) {
      setPriceOutOfRangeMessage('Enter price values from low to high.');
      return;
    }
    const priceList: number[] = [];
    facet &&
      facet.valueList &&
      facet.valueList.map((facetValue) => {
        const prices = facetValue.value.split('-');
        if (prices[0] === '*') {
          priceList.push(0);
        } else {
          priceList.push(parseInt(prices[0], 10));
        }
        if (prices[1] === '*') {
          isMaxPriceAbove = true;
        } else {
          priceList.push(parseInt(prices[1], 10));
        }
        return null;
      });
    const maxPrice = Math.max(...priceList);

    if (fromVal > maxPrice) {
      setPriceOutOfRangeMessage('No result in your price range.');
    } else if (fromVal || toVal) {
      setPriceOutOfRangeMessage('');
      onFilterChange(`${fromVal}-${toVal}`);
    }
  };

  const addSymbolForPriceDisplay = (value: string) => {
    let valArray: string[] | number[] = [];
    let displayText = '';
    if (value) {
      valArray = value.split('-');
      if (valArray[0] && !isNaN(valArray[0])) {
        valArray[0] = parseFloat(valArray[0].trim()).toFixed(0);
      }
      if (valArray[1] && !isNaN(valArray[1])) {
        valArray[1] = parseFloat(valArray[1].trim()).toFixed(0);
      }
    }
    displayText = `$${valArray[0]} - $${valArray[1]}`;
    if (valArray[0] === '0' || valArray[0] === '*') {
      displayText = `under $${valArray[1]}`;
    }
    if (valArray[1] === '*') {
      displayText = `$${valArray[0]} above`;
    }
    return displayText;
  };

  const createOnFilterChangeCallback = (filterValue: string, doNothing: boolean) => {
    return (event: React.MouseEvent<HTMLDivElement, React.MouseEvent>) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const toggleFacet = (e, identifier) => {
    toggleOpenCloseFacet(identifier);
    setfacetExpanded(!facetExpanded);
  };

  // Onkeypress  Listen for Enter (key code 13) to have been pressed
  const onKeyPressHandler = (keyEvt) => {
    if (keyEvt.charCode !== 13 && keyEvt.key !== 'Enter') {
      return;
    }
    // calling createOnFilterCallback function
    keyEvt.stopPropagation();

    createOnFilterCallback();
  };

  const createFacetValues = (valueList: FacetVOsValueList, identifier: string) => {
    return (
      valueList &&
      valueList.map((facetValue, index) => {
        const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
        const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;

        return (
          <Checkbox
            key={`${identifier}_${index}`}
            checkboxId={`checkbox_${identifier}_${index}`}
            onCheckboxToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
            disabled={disabled}
            labelledby={`${identifier}_facet_value_${facetValue.value.replace(/[^A-Z0-9]/gi, '_')}`}
            facetName={addSymbolForPriceDisplay(facetValue.value)}
            facetCount={facetValue.count}
            selectedData={selectedData}
            facetValue={facetValue}
            isCheckedValue={isChecked}
          />
        );
      })
    );
  };

  const updateInputValue = (event, valType) => {
    const priceInputValue = event.target.value.replace(/\D/, '');
    let toValue = '';
    let fromValue = '';
    if (valType && valType === 'to') {
      setToVal(priceInputValue);
      setPriceOutOfRangeMessage('');
    } else if (valType && valType === 'from') {
      setFromVal(priceInputValue);
      setPriceOutOfRangeMessage('');
    }
    if (fromValue && toValue && parseInt(fromValue) < parseInt(toValue)) {
      setBtnClass(false);
      setPriceOutOfRangeMessage('');
    } else {
      setBtnClass(false);
      setPriceOutOfRangeMessage('');
    }
  };

  const createCustomPriceRange = () => {
    let leftInput = 'normal-input';
    let rightInput = 'normal-input';

    if (priceOutOfRangeMessage.length > 0) {
      if (fromVal !== '0' && Number(fromVal) && Number(toVal)) {
        leftInput = 'normal-input border-input';
        rightInput = 'normal-input border-input';
      } else {
        if (fromVal !== '0' && !Number(fromVal)) {
          leftInput = 'normal-input border-input';
        }
        if (!Number(toVal)) {
          rightInput = 'normal-input border-input';
        }
      }
    }

    return (
      <div className="rs-custom-price-range">
        <div className="rs-from-range">
          <span className="rs-dollor-sym">$</span>
          <input
            type="text"
            name="price"
            id="low_price_input"
            maxLength={4}
            className={leftInput}
            aria-label="Lower Bound"
            onChange={(evt) => updateInputValue(evt, 'from')}
            value={fromVal}
          />
        </div>
        <div className="rs-range-seperation"> -</div>
        <div className="rs-to-range">
          <span className="rs-dollor-sym">$</span>
          <input
            type="text"
            name="price"
            id="high_price_input"
            maxLength={4}
            className={rightInput}
            aria-label="Upper Bound"
            onChange={(evt) => updateInputValue(evt, 'to')}
            value={toVal}
            onKeyPress={onKeyPressHandler}
          />
        </div>
        <div className="rs-custom-range-submit-btn">
          <Button
            label="Go"
            variant="tertiary"
            classes={['rs-custom-price-filter-btn']}
            disabled={btnClass}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={createOnFilterCallback}
            onKeyPress={(e) => handleADA(e, 'button', createOnFilterCallback)}
          />
        </div>
      </div>
    );
  };

  const createSelectedData = () => {
    return (
      selectedData &&
      selectedData.map((data) => {
        return addSymbolForPriceDisplay(data);
      })
    );
  };

  const renderPriceRangeForm = () => {
    if (!isMultiSelectPriceFilterEnabled) {
      return (
        <>
          {createCustomPriceRange()}
          {priceOutOfRangeMessage && (
            <div className="rs-facet-value-data-price">
              <div className="rs_filled">
                <RemoveCircleIcon pathClasses={['store-pickup-search-error']} />
              </div>
              <div className="rs-facet-value-data-price-range">{priceOutOfRangeMessage}</div>
            </div>
          )}
          <div className="rs-custom-price-range-border" />
        </>
      );
    }
  };

  return (
    <CreateFacetContainer
      facet={facet}
      openFacet={openFacet}
      selectedData={createSelectedData()}
      storeName={storeName}
      facetExpanded={facetExpanded}
      valueList={createFacetValues(facet.valueList, facet.attrIdentifier)}
      toggleFacet={toggleFacet}
      type="price">
      {renderPriceRangeForm()}
    </CreateFacetContainer>
  );
};

export default PriceSelectFacet;
