import {
  ProductApiData,
  ProductDetail,
  ProductVO,
  SearchResult,
  SelectedSwatchVal,
} from '../../../../types';
import React, { memo } from 'react';

import { getImageHost } from '../../../../utility/url-utils';
import { createProductLinkCallback } from '../../../../utility/ml-utils';
import { deepCompareWithStringify } from '../../../../utility/utils';
import defaultImage from './grayImageBox';

type Props = {
  product: ProductVO;
  productDetails: ProductDetail;
  pdpUrl: string;
  name: string;
  catgroupId: string;
  searchResult: ProductApiData;
  selectedSwatchVal: SelectedSwatchVal;
  storeIdentifierName: string;
};
const CardImage = ({
  product,
  productDetails,
  selectedSwatchVal,
  pdpUrl,
  searchResult,
  catgroupId,
  storeIdentifierName,
}: Props) => {
  let imageColor = '';
  let imageSrc = '';

  if (selectedSwatchVal && product.thumbnail === selectedSwatchVal.thumbnail) {
    imageColor = selectedSwatchVal.swatchColor;
  } else if (productDetails && productDetails.skuAttributes && productDetails.skuAttributes.Color) {
    imageColor = productDetails.skuAttributes.Color[0];
  }

  if (imageColor) {
    imageColor = imageColor.replace(/[^\w]/gi, '_');
    imageSrc = `${getImageHost()}/${product.thumbnail}_${imageColor}_is/?wid=252&hei=252&qlt=85,0&fmt=jpg&op_sharpen=1`;
  } else {
    imageSrc = `${getImageHost()}/${product.thumbnail}_is/?wid=252&hei=252&qlt=85,0&fmt=jpg&op_sharpen=1`;
  }

  const createLinkCallback = createProductLinkCallback(
    pdpUrl,
    searchResult,
    catgroupId,
    product,
    storeIdentifierName,
  );
  return (
    <div className="card_image">
      <a
        onClick={createLinkCallback}
        href={pdpUrl}
        title={product.name}
        aria-label={product.name}
        role="button"
        key={`a_${product.thumbnail}`}
        className="image"
        tabIndex={0}>
        <span className="product-image-span">
          <img
            key={`img_${product.thumbnail}`}
            src={imageSrc}
            alt={product.name}
            title={product.name}
            aria-hidden={true}
            loading="lazy"
          />
        </span>
      </a>
    </div>
  );
};

export default CardImage;
