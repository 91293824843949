import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { updateFilter } from '../../../../utility/history';
import { toggleOpenCloseFacet, handleADA } from '../../../../utility/utils';
import SmallChevonIcon from '../../../common/SVGIcons/SmallChevonIcon';
import { default as CaliaSmallChevonIcon } from '../../../common/SVGIcons/Calia/SmallChevronIcon';

import Link from '../../../common/Link/Link';
import PLChevronIcon from '../../../common/SVGIcons/PublicLands/PLChevronIcon';
import { useSelector } from 'react-redux';
import { familyPageNameSelector } from '../../../../reduxStore/selectors';
import MJChevronIcon from '../../../common/SVGIcons/MooseJaw/MJChevronIcon';

// Component that will create single selct facet cards

const LinkSingleSelectFacet = ({
  selectedData,
  facet,
  openFacet,
  categoryDetails,
  onSelection,
  onFilterData,
  storeName,
  isFamilyPage,
  isV3Template,
}) => {
  const familyPageName = useSelector(familyPageNameSelector);
  const [facetExpanded, setFacetExpanded] = useState(openFacet);

  const toggleFacet = (e, identifier) => {
    toggleOpenCloseFacet(identifier);
    setFacetExpanded(!facetExpanded);
  };

  const onFilterChange = (val, e) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'single');
    updateFilter(identifier, values, 'single');
  };

  const createMultiFacetValue = (facetValue, identifier) => {
    // Handle Shop by Category Facet
    if (identifier === 'facetCatgroup' && categoryDetails[facetValue.value]) {
      return (
        <span key={`${identifier}_${facetValue.value}_${facetValue.count}`}>
          <div
            className="facet-value"
            key={`${identifier}_facet_value_${facetValue.value}`}
            id={`${identifier}_facet_value_${facetValue.value}`}>
            {categoryDetails[facetValue.value].name}
          </div>
          {!isV3Template && (
            <div className="rs-facet-count" key={`${identifier}_facet_count_${facetValue.count}`}>
              {` (${facetValue.count})`}
            </div>
          )}
        </span>
      );
    }

    return (
      <span key={`${identifier}_${facetValue.value}_${facetValue.count}`}>
        <div
          className="facet-value"
          key={`${identifier}_facet_value_${facetValue.value}`}
          id={`${identifier}_facet_value_${facetValue.value}`}>
          {facetValue.value}
        </div>
        <div className="rs-facet-count" key={`${identifier}_facet_count_${facetValue.count}`}>
          {` (${facetValue.count})`}
        </div>
      </span>
    );
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const createFacetValues = (valueList, identifier) => {
    return valueList.map((facetValue, index) => {
      const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
      const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;

      // If Shop by Category and no matching categoryDetails key don't render the facet option
      // This could happen if there is a data issue
      if (identifier === 'facetCatgroup' && categoryDetails[facetValue.value] === undefined) {
        return null;
      }

      return (
        <Link
          key={`${identifier}_${index}`}
          onClick={!isV3Template ? createOnFilterChangeCallback(facetValue.value, disabled) : null}
          text={createMultiFacetValue(facetValue, identifier)}
          labelledby={`${identifier}_facet_value_${facetValue.value}`}
          href={isV3Template ? categoryDetails[facetValue.value].url : '#'}
          dataEm={
            isV3Template && familyPageName
              ? `LN_${familyPageName.replace(/['\s]/g, '')}_${categoryDetails[
                  facetValue.value
                ].name.replace(/['\s]/g, '')}_${index + 1}`
              : null
          }
        />
      );
    });
  };

  const createFacet = () => {
    let hasSelectedDataClass = '';
    if (selectedData && selectedData.length !== 0) {
      hasSelectedDataClass = 'has-selected-facets';
    }

    // Handle Shop by Category Facet - Converts Selected Data values to their Category Details Name and leaves other values as is
    const formattedSelectedFilters = selectedData.map((value) => {
      if (
        facet.attrIdentifier === 'facetCatgroup' &&
        categoryDetails[value] &&
        categoryDetails[value].name &&
        selectedData.indexOf(value) !== -1
      ) {
        return categoryDetails[value].name;
      }

      return value;
    });

    let chevronIcon;
    switch (storeName) {
      case 'calia':
        chevronIcon = (
          <CaliaSmallChevonIcon
            classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']}
          />
        );
        break;
      case 'mj':
        chevronIcon = (
          <MJChevronIcon classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']} />
        );
        break;
      case 'pl':  
        chevronIcon = (
          <PLChevronIcon classes={[facetExpanded ? 'rs-facet-icon-up' : 'rs-facet-icon-down']} />
        );
        break;
      default:
        chevronIcon = (
          <SmallChevonIcon classes={[facetExpanded ? 'rs-facet-icon-down' : 'rs-facet-icon-up']} />
        );
        break;
    }

    if (facet && facet.valueList && facet.valueList.length > 0) {
      return (
        <div className="rs-facet-container" key={`container_facet_${facet.attrIdentifier}`}>
          <div
            key={`div_facet_value_heading_${facet.attrIdentifier}`}
            tabIndex="0"
            className={`${
              openFacet ? 'rs-facet-heading focus-border expanded' : 'rs-facet-heading focus-border'
            } ${hasSelectedDataClass}`}
            id={`facet_heading_${facet.attrIdentifier}`}
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={(e) => toggleFacet(e, facet.attrIdentifier)}
            onKeyPress={(e) => handleADA(e, 'button', () => toggleFacet(e, facet.attrIdentifier))}
            aria-haspopup="true"
            aria-controls={`facet_list_${facet.attrIdentifier}`}
            aria-expanded={facetExpanded}
            aria-labelledby={`facet_list_label_${facet.attrName.replace(/[^A-Z0-9]/gi, '_')}`}>
            <div
              className="rs-facet-name-container"
              id={`facet_list_label_${facet.attrName.replace(/[^A-Z0-9]/gi, '_')}`}>
              {facet.attrName === 'Shop by Category' ? 'Categories' : facet.attrName}
              {chevronIcon}
            </div>
            <div className="rs-selected-facets">{`${formattedSelectedFilters}`}</div>
          </div>
          <div
            key={`div_facet_value_list_${facet.attrIdentifier}`}
            className="rs-facet-value-data-container link-facet"
            id={`facet_list_${facet.attrIdentifier}`}
            style={{ display: openFacet ? 'block' : 'none' }}
            aria-hidden={!facetExpanded}>
            <div className="rs-facet-value-data" key={`facet_value_list_${facet.attrIdentifier}`}>
              {createFacetValues(facet.valueList, facet.attrIdentifier)}
            </div>
          </div>
        </div>
      );
    }
    return null;
  };

  return (
    <div
      className={`rs-multi-select-facet rs-facet-wrapper rs-facet-wrapper-${facet.attrName
        .toLowerCase()
        .replace(/ /g, '')}`}
      key={`wrapper_facet_${facet.attrIdentifier}`}>
      {createFacet()}
    </div>
  );
};

export default LinkSingleSelectFacet;

LinkSingleSelectFacet.propTypes = {
  /** facet object */
  facet: PropTypes.object.isRequired,
  /** selected data array for particular facet */
  selectedData: PropTypes.array,
  /** facet open or close flag */
  openFacet: PropTypes.bool.isRequired,
  /** function to apply selected filter */
  onFilterData: PropTypes.func.isRequired,
};
