import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class RemoveCircleIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        width="10"
        height="10"
        viewBox="0 0 10 10"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M0 5C0 2.24 2.24 0 5 0C7.76 0 10 2.24 10 5C10 7.76 7.76 10 5 10C2.24 10 0 7.76 0 5ZM2.5 5.5H7.5V4.5H2.5V5.5Z"
          fill="#191F1C"
          className={pathClasses}
        />
      </svg>
    );
  }
}

RemoveCircleIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

RemoveCircleIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
