import React, { Component } from 'react';
import PropTypes from 'prop-types';

//Component that will create multi select facet cards

class FacetSearchBar extends Component {
  static propTypes = {
    /** function to fire when value changes */
    inputValueChange: PropTypes.func,
    /** name is a string for display as a place holder */
    name: PropTypes.string,
  };

  constructor(props) {
    super(props);
    this.state = {
      searchVal: '',
    };
  }

  render() {
    return (
      <div className="rs-facet-search-bar-container" key={'facet_search_bar_' + this.props.name}>
        <div className="rs-facet-search-icon">
          <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 24 24">
            <path
              fill="#191F1C"
              d="M15.5 14h-.79l-.28-.27C15.41 12.59 16 11.11 16 9.5 16 5.91 13.09 3 9.5 3S3 5.91 3 9.5 5.91 16 9.5 16c1.61 0 3.09-.59 4.23-1.57l.27.28v.79l5 4.99L20.49 19l-4.99-5zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z"
            />
          </svg>
        </div>
        <div className="rs-facet-search-text">
          <input
            type="text"
            name={this.props.name}
            value={this.state.searchVal}
            placeholder={'Search ' + this.props.name}
            onChange={this.handleChange}></input>
        </div>
        <div className="rs-facet-search-arrow">
          <svg viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M8 0L6.59 1.41L12.17 7H0V9H12.17L6.59 14.59L8 16L16 8L8 0Z" fill="#8C8F8E" />
          </svg>
        </div>
      </div>
    );
  }

  handleChange = (evt) => {
    this.setState({ searchVal: evt.target.value });
    this.props.inputValueChange(evt.target.value, evt);
  };
}

export default FacetSearchBar;
