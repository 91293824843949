import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class ErrorCircle extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = `${additionalClasses}`;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = `${additionalPathClasses}`;

    return (
      <svg
        width="15"
        height="15"
        viewBox="0 0 15 15"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.66699 7.49935C1.66699 4.27352 4.27449 1.66602 7.50033 1.66602C10.7262 1.66602 13.3337 4.27352 13.3337 7.49935C13.3337 10.7252 10.7262 13.3327 7.50033 13.3327C4.27449 13.3327 1.66699 10.7252 1.66699 7.49935ZM9.59449 10.416L10.417 9.59351L8.32282 7.49935L10.417 5.40518L9.59449 4.58268L7.50033 6.67685L5.40616 4.58268L4.58366 5.40518L6.67782 7.49935L4.58366 9.59351L5.40616 10.416L7.50033 8.32185L9.59449 10.416Z"
          fill="#B80818"
          className={pathClasses}
        />
      </svg>
    );
  }
}
ErrorCircle.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

ErrorCircle.defaultProps = {
  classes: [],
  pathClasses: [],
};
