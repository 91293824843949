import React from 'react';
import PropTypes from 'prop-types';

const MJChevronIcon = ({ classes, pathClasses }) => {
  const additionalClasses = classes.join(' ').trim();
  const joinedClasses = `${additionalClasses}`;
  const additionalPathClasses = pathClasses.join(' ').trim();
  const joinedPathClasses = `${additionalPathClasses}`;

  return (
    <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    className={classes}
    xmlns="http://www.w3.org/2000/svg">
    <path
      d="M12 16L18 10L16.59 8.59L12 13.17L7.41 8.59L6 10L12 16Z"
      fill="#191F1C"
      className={pathClasses}
    />
  </svg>
  ); 
};

export default MJChevronIcon;

MJChevronIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

MJChevronIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
