import {
  getBreadcrumbContainerTop,
  mapSortValToText,
  setScrollPosition,
} from '../../utility/utils';
import { resultsViewUpdate } from '../plp-srlp-analytics';
import { sortData } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

export function onSortData(sortType) {
  return (dispatch, getState) => {
    const values = getBreadcrumbContainerTop();

    if (values) {
      setScrollPosition(0, values.top);
    }

    dispatch(isSearching());
    dispatch(sortData(sortType));

    const stateData = getState();
    const { input } = stateData;

    const refinementUpdateObj = {
      UpdateType: 'SortMethod',
      UpdateValue: mapSortValToText(sortType),
    };
    resultsViewUpdate(refinementUpdateObj);

    callAPI(input, dispatch, stateData);
  };
}
