import React from 'react';
import { useSelector } from 'react-redux';
import { totalCountSelector } from '../../../reduxStore/selectors';

const MobileProductCount = () => {
  const totalCount = useSelector(totalCountSelector);

  return (
    <div className="dsg-react-mobile-product-count">
      <span>{totalCount} products</span>
    </div>
  );
};

export default MobileProductCount;
