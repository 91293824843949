import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class MJFilterIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        aria-hidden="true"
        width="16"
        height="16"
        viewBox="0 0 16 16" 
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.6665 8.56096L6.32073 8.56096C6.54153 9.21332 7.12766 9.68377 7.82206 9.68377C8.51646 9.68377 9.10259 9.21332 9.32339 8.56096L13.3332 8.56096V7.43815L9.32339 7.43815C9.10259 6.7858 8.51646 6.31534 7.82206 6.31534C7.12766 6.31534 6.54153 6.7858 6.32073 7.43815L2.6665 7.43815V8.56096Z"
          fill="#A93338"
          className={pathClasses}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M2.6665 4.35023C2.6665 3.42167 3.38437 2.66602 4.2665 2.66602C4.9609 2.66602 5.54704 3.13647 5.76784 3.78883L13.3332 3.78883V4.91164L5.76784 4.91164C5.54704 5.56399 4.9609 6.03445 4.2665 6.03445C3.38437 6.03445 2.6665 5.2788 2.6665 4.35023Z"
          fill="#A93338"
          className={pathClasses}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.2318 11.0871H2.6665V12.2099H10.2318C10.4526 12.8622 11.0388 13.3327 11.7332 13.3327C12.6153 13.3327 13.3332 12.577 13.3332 11.6485C13.3332 10.7199 12.6153 9.96424 11.7332 9.96424C11.0388 9.96424 10.4526 10.4347 10.2318 11.0871Z" 
          fill="#A93338"
          className={pathClasses}
        />
      </svg>
    );
  }
}

MJFilterIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

MJFilterIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
