import { createSelector } from '@reduxjs/toolkit';
import { ReduxState } from '../../types';

export const selectedStoreSelector = (state: ReduxState) => state.input.selectedStore;
export const selectedFiltersSelector = (state: ReduxState) => state.input.selectedFilters;
export const selectedSortSelector = (state: ReduxState) => state.input.selectedSort;
export const isFamilyPageSelector = (state: ReduxState) =>
  state.input.isFamilyPage ? state.input.isFamilyPage : false;
export const searchTermSelector = (state: ReduxState) =>
  state.input.searchTerm
    ? state.input.searchTerm
    : state.input.originalSearchTerm
    ? state.input.originalSearchTerm
    : null;
export const totalCountSelector = (state: ReduxState) => state.input.totalCount;
export const storeIdSelector = (state: ReduxState) => state.input.storeId;
export const snbAudienceSelector = (state: ReduxState) => state.input.snbAudience;
export const previewSelector = (state: ReduxState) => state.input.preview;
export const landingPageUrlSelector = (state: ReduxState) =>
  state.input.landingPageURL ? state.input.landingPageURL : '';
export const pageNumberSelector = (state: ReduxState) =>
  state.input.pageNumber ? state.input.pageNumber : 0;
export const pageSizeSelector = (state: ReduxState) =>
  state.input.pageSize ? state.input.pageSize : 0;
