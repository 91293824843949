import React, { useState, useEffect } from 'react';
import { getCookie } from '../utility/cookie-utils';

const useZipCode = () => {
  const [sddZip, setSddZip] = useState(getCookie('deliveryZip'))
  
  useEffect(() => {
    window.addEventListener('HeaderServices:SameDayDelivery:Change', () => {
      setSddZip(getCookie('deliveryZip'));
    });
  }, [sddZip]);

  return sddZip
}

export default useZipCode;