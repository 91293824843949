import React from 'react';
import PropTypes from 'prop-types';
import CloseIcon from '../../SVGIcons/CloseIcon';

const FilterChip = ({ label, onClick }) => {
  const onKeyPress = (event) => {
    // Handle when event is a KeyboardEvent
    if (event instanceof KeyboardEvent) {
      // Listen for Enter (key code 13) to have been pressed for ADA
      if (event.key !== 'Enter' || event.keyCode !== 13) {
        return;
      }
    }

    onClick();
  };

  return (
    <>
      <div
        role="button"
        className="filter-chip"
        onKeyPress={onKeyPress}
        tabIndex="0"
        onClick={onClick}
        title={label}>
        <span>{label}</span>
        <CloseIcon classes={['filter-chip-close']} />
      </div>
    </>
  );
};

export default FilterChip;

FilterChip.defaultProps = {
  label: '',
};

FilterChip.propTypes = {
  label: PropTypes.string, // Label on the chip
  onClick: PropTypes.func.isRequired, // Function to remove the filter chip
};
