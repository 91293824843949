import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { handleMobileFilterFacetModalClick } from '../../../reduxStore/thunks';
import { deepCompareWithStringify, handleADA } from '../../../utility/utils';
import { default as CaliaFilterIcon } from '../../common/SVGIcons/Calia/FilterIcon';
import PLFilterIcon from '../../common/SVGIcons/PublicLands/PLFilterIcon';
import SettingsIcon from '../../common/SVGIcons/SettingsIcon';
import {
  selectedFiltersSelector,
  showMobileFilterFacetModalSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import MJFilterIcon from '../../common/SVGIcons/MooseJaw/MJFilterIcon';

const theme = {
  calia: {
    icon: <CaliaFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  pl: {
    icon: <PLFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
  mj:{
    icon: <MJFilterIcon classes={['dsg-mobile-sticky-filter-icon']} />
  },
  default: {
    icon: <SettingsIcon classes={['dsg-mobile-sticky-filter-icon']} />,
  },
};

const MobileFilterButton = () => {
  const dispatch = useDispatch();
  const [selectedFilterCount, setSelectedFilterCount] = useState<number>(0);
  const showMobileFilterFacetModal = useSelector(showMobileFilterFacetModalSelector);
  const selectedFilters = useSelector(selectedFiltersSelector, deepCompareWithStringify);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const filterButton = useRef<any>();
  const themeData = theme[storeIdentifierName] ? theme[storeIdentifierName] : theme.default;

  useEffect(() => {
    if (selectedFilters) {
      let count = 0;

      selectedFilters.map;

      Object.keys(selectedFilters).map((key) => {
        count += selectedFilters[key].length;
      });

      setSelectedFilterCount(count);
    }
  }, [selectedFilters]);

  return (
    <div
      className="dsg-flex align-items-center dsg-react-mobile-filter-button "
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={(e) => {
        e.stopPropagation();
        dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
      }}
      onKeyPress={(e) => {
        e.stopPropagation();
        handleADA(e, 'button', () =>
          dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal)),
        );
      }}
      tabIndex={0}
      role="button">
      <div id="dsg-react-sticky-filter-button-ghost-div" />
      <button
        aria-label={`Filter Modal Toggle Button, you have ${selectedFilterCount} filters selected`}
        className="dsg-mobile-sticky-filter-toggle-button dsg-sticky hidden-sticky"
        tabIndex={-1}
        ref={filterButton}
        onClick={(e) => {
          e.stopPropagation();
          dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
        }}
        onKeyPress={(e) => {
          e.stopPropagation();
          handleADA(e, 'button', () => {
            dispatch(handleMobileFilterFacetModalClick(showMobileFilterFacetModal));
          });
        }}>
        {themeData.icon}
        Filter
        {selectedFilterCount !== 0 ? (
          <span className="filter-count">{selectedFilterCount}</span>
        ) : null}
      </button>
    </div>
  );
};

export default MobileFilterButton;
