import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { updateFilter } from '../../../../utility/history';
import { applyFilter } from '../../../../reduxStore/thunks/index';
import PermanentChip from '../../../common/PermanentChip/PermanentChip';
import { getCookie } from '../../../../utility/cookie-utils';
import { getFeatureFlagByNameSelector } from '../../../../reduxStore/selectors';

const ShippingMethodButton = ({
  label,
  type,
  selectedShippingMethods,
  selectedStoreName,
  allowDisable,
  storeFacet,
  classes,
}) => {
  const [isSelected, setSelected] = useState(false);
  const [disabled, setDisabled] = useState(allowDisable);
  const SDDEnabled = useSelector(getFeatureFlagByNameSelector('SDDEnabled'));

  const dispatch = useDispatch();

  const verifyZipAndStore = (event) => {
    let sddZip = getCookie('deliveryZip');
    let store = getCookie('setStoreCookie');
    if (type && type === 'SDD') {
      if (!sddZip || sddZip === 'null') {
        window.headerFunctions.sameDayDelivery();
        window.addEventListener(
          'HeaderServices:SameDayDelivery:Change',
          () => {
            toggleOption(event);
          },
          { once: true },
        );
      } else {
        toggleOption(event);
      }
    } else {
      if (!store || store === 'null') {
        window.headerFunctions.storeSelection();
        window.addEventListener(
          'HeaderServices:StoreSelection:Change',
          () => {
            toggleOption(event);
          },
          { once: true },
        );
      } else {
        toggleOption(event);
      }
    }
  };

  const toggleOption = (event) => {
    if (!disabled) {
      event.stopPropagation();
      setSelected(!isSelected);

      const values = [];

      if (selectedShippingMethods && selectedShippingMethods.indexOf(type) > -1) {
        values.push(`-${type}`);
      } else {
        values.push(type);
      }

      dispatch(applyFilter('facetStore', values, `${SDDEnabled ? 'single' : 'multi'}`));
      updateFilter('facetStore', values, `${SDDEnabled ? 'single' : 'multi'}`);
    }
  };

  useEffect(() => {
    if (selectedShippingMethods && selectedShippingMethods.indexOf(type) > -1) {
      setSelected(true);
    } else {
      setSelected(false);
    }
  }, [selectedShippingMethods]);

  useEffect(() => {
    if (allowDisable) {
      if (
        !selectedStoreName ||
        (storeFacet &&
          storeFacet.values &&
          !storeFacet.values[type] &&
          storeFacet.values[type] === 0)
      ) {
        setDisabled(true);
      } else {
        setDisabled(false);
      }
    }
  }, [selectedStoreName, storeFacet]);

  return <PermanentChip
    text={label}
    onClick={(e) => (SDDEnabled ? verifyZipAndStore(e) : toggleOption(e))}
    additionalClasses={`${isSelected ? 'selected' : ''} ${disabled ? 'disabled' : ''} ${classes}`}
    isSelected
  />;
};

ShippingMethodButton.defaultProps = {
  selectedStoreName: '',
  allowDisable: true,
  classes: '',
};

export default ShippingMethodButton;
