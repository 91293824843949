import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { updateFilter } from '../../../../utility/history';
import { toggleOpenCloseFacet } from '../../../../utility/utils';
import Checkbox from '../../../common/FacetCheckBox/FacetCheckBox';
import CreateFacetContainer from './CreateFacetContainer';
import { createMultiFacetValue } from '../utility/facet-section-utils';
import { gtgtString } from '../../../../utility/feature-flags';

const MultiSelectFacet = ({
  facet,
  selectedData,
  // key,
  onFilterData,
  openFacet,
  breakpoint,
  storeName,
  onSelection,
}) => {
  const [facetExpanded, setFacetExpanded] = useState(openFacet);

  const onFilterChange = (val, e) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'multi');
    updateFilter(identifier, values, 'multi');
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }

      onFilterChange(filterValue);
    };
  };

  const createFacetValues = (valueList, identifier) => {
    return valueList.map((facetValue, index) => {
      const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
      const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;

      if (facetValue.value === 'N' && identifier === 'Guaranteed_To_Get_There') {
        // Do not render anything for No for guaranteed to get there
        return null;
      }

      return (
        <Checkbox
          key={`${identifier}_${index}`}
          checkboxId={`checkbox_${identifier}_${index}`}
          onCheckboxToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
          disabled={disabled}
          labelledby={`${identifier}_facet_value_${facetValue.value.replace(/[^A-Z0-9]/gi, '_')}`}
          breakpoint={breakpoint}
          facetName={createMultiFacetValue(facetValue, identifier, gtgtString)}
          facetCount={facetValue.count}
          facet={facet.attrName}
          selectedData={selectedData}
          facetValue={facetValue}
          isCheckedValue={isChecked}
        />
      );
    });
  };

  const toggleFacet = (e, identifier) => {
    toggleOpenCloseFacet(identifier);
    setFacetExpanded(!facetExpanded);
  };

  useEffect(() => {
    setFacetExpanded(openFacet);
  }, [openFacet]);

  return (
    <CreateFacetContainer
      facet={facet}
      toggleFacet={toggleFacet}
      facetExpanded={facetExpanded}
      openFacet={openFacet}
      valueList={createFacetValues(facet.valueList, facet.attrIdentifier)}
      selectedData={selectedData}
      storeName={storeName}
      type="multi"
    />
  );
};

export default MultiSelectFacet;

MultiSelectFacet.propTypes = {
  /** facet object */
  facet: PropTypes.object.isRequired,
  /** selected data array for particular facet */
  selectedData: PropTypes.array,
  /** facet open or close flag */
  openFacet: PropTypes.bool.isRequired,
  /** function to apply selected filter */
  onFilterData: PropTypes.func.isRequired,
};
