/**
 * Feature Flags
 * @environment PROD AUTH / PREVIEW
 * @description These should be treated as you want the feature either on or off. These will completely override any A/B testing window variables if set to TRUE.
 * These should only be set to true when product or we decide the feature should be enabled for 100% of traffic. Be sure to also find out which brands a particular feature should be enabled for as this is brand specific.
 */

const featureFlags = {
  retailMediaNetworkEnabled: {
    dsg: true,
    gg: true,
    fns: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  isPLPMLIntegrationEnabled: {
    dsg: true,
    gg: true,
    fns: false,
    calia: false,
    vrst: false,
    pl: true,
    g3: false,
    mj: true,
  },
  SDDEnabled: {
    dsg: true,
    gg: false,
    fns: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  isMultiSelectPriceFilterEnabled: {
    dsg: true,
    gg: true,
    fns: true,
    calia: true,
    vrst: true,
    pl: true,
    g3: true,
  },
  addFavoritesEnabled: {
    dsg: true,
    gg: true,
    calia: false,
    vrst: false,
    pl: true,
    g3: false,
    mj: true,
  },
  updateFilterOrderEnabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  pinnedProductsDisabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  pinnedContentDisabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  sponsoredListingDisabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  medalliaSurveyFlag: {
    dsg: true,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  productCardRatingEnabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  skuFavoritesEnabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  sponsoredAnalyticsTrackingEnabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
  listPanelEnabled: {
    dsg: true,
    gg: true,
    calia: false,
    vrst: false,
    pl: true,
    g3: false,
    mj: true,
  },
  adeptMindLinksEnabled: {
    dsg: false,
    gg: false,
    calia: false,
    vrst: false,
    pl: false,
    g3: false,
    mj: false,
  },
};

export default featureFlags;
