import React, { useState, useEffect } from 'react';
import { getCookie } from '../../../../utility/cookie-utils';
import { useSelector } from 'react-redux';
import { promotionDataSelector } from '../../../../reduxStore/selectors';
import { deepCompareWithStringify } from '../../../../utility/utils';

interface ProductPromotionProps {
  catentryId: number;
  ecode: string;
}

const ProductPromotion = ({ catentryId, ecode }: ProductPromotionProps) => {
  const [promotionMessage, setPromotionMessage] = useState('');
  const promotionData = useSelector(promotionDataSelector, deepCompareWithStringify);
  const modernCookie = getCookie('modern');

  useEffect(() => {
    if (promotionData) {
      if (modernCookie === 'true' && promotionData[ecode]) {
        setPromotionMessage(promotionData[ecode].plpMessage);
      } else if (promotionData[catentryId]) {
        setPromotionMessage(promotionData[catentryId].promoDesc);
      }
    }
  }, [promotionData]);

  return (
    <div
      className="rs-promotions"
      key={`promotion_${catentryId}`}
      id={`promotion_${catentryId}`}
      title={promotionMessage}>
      {promotionMessage}
    </div>
  );
};

export default ProductPromotion;
