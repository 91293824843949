import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SmallChevronIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = `${additionalClasses}`;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = `${additionalPathClasses}`;

    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M4.3225 8.98925L7 6.31758L9.6775 8.98925L10.5 8.16675L7 4.66675L3.5 8.16675L4.3225 8.98925Z"
          fill="black"
          className={pathClasses}
        />
      </svg>
    );
  }
}

SmallChevronIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SmallChevronIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
