import React, { RefObject, useEffect, useRef, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { useDispatch, useSelector } from 'react-redux';
import {
  getFeatureFlagByNameSelector,
  mlImpressionEcodesSelector,
  mlImpressionProductsByRowSelector,
  mlSearchUUIDSelector,
  productVOsSelector,
  storeIdentifierNameSelector,
} from '../../../reduxStore/selectors';
import { setMLImpressionEcodes } from '../../../reduxStore/slices/productApiData';

type Props = {
  ref: RefObject<Element>;
  row: number;
  column: number;
  rowLength: number;
};

const useMachineLearningImpression = ({ ref, row, column, rowLength }: Props) => {
  const dispatch = useDispatch();
  const isPLPMLIntegrationEnabled = useSelector(
    getFeatureFlagByNameSelector('isPLPMLIntegrationEnabled'),
  );
  const mlSearchUUID = useSelector(mlSearchUUIDSelector);
  const mlImpressionEcodes = useSelector(mlImpressionEcodesSelector);
  const storeIdentifierName = useSelector(storeIdentifierNameSelector);
  const mlImpressionProductsByRow = useSelector(mlImpressionProductsByRowSelector);
  const productVOs = useSelector(productVOsSelector);
  const [eventFired, setEventFired] = useState(false);
  const [entry, setEntry] = useState<IntersectionObserverEntry>();

  const updateEntry = ([entry]: IntersectionObserverEntry[]): void => {
    setEntry(entry);
  };

  useEffect(() => {
    if (column !== 1 || !isPLPMLIntegrationEnabled) return;

    const node = ref?.current; // DOM Ref
    const hasIOSupport = !!window.IntersectionObserver;

    if (!hasIOSupport || !node) return;

    // Reset event when DOM nodes or products change in anyway
    setEventFired(false);

    const observerParams = { threshold: 0.5, root: null, rootMargin: '0%' };
    const observer = new IntersectionObserver(updateEntry, observerParams);

    observer.observe(node);

    return () => observer.disconnect();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [ref?.current, productVOs]);

  const fireEvent = (store: string) => {
    let loyalty;
    let isGold;
    let adobeMCVID;
    let athleteId;

    if (
      !mlImpressionProductsByRow[`${row}-${rowLength}`] ||
      (mlImpressionProductsByRow[`${row}-${rowLength}`] &&
        mlImpressionProductsByRow[`${row}-${rowLength}`].length === 0)
    )
      return;

    const filteredProducts = mlImpressionProductsByRow[`${row}-${rowLength}`].filter((value) => {
      const match = mlImpressionEcodes.some((impressionEcode) => impressionEcode.id === value.id);
      if (!match) {
        return value;
      }
    });

    if (window._dsgTag) {
      loyalty = window._dsgTag.CustomerIDs.Loyalty;
      isGold = window._dsgTag.CustomerIDs.isGold;
      adobeMCVID = window._dsgTag.CustomerIDs.AdobeMCVID;
      athleteId = window._dsgTag.CustomerIDs.AthleteID;
    }

    const payload = {
      type: 'I',
      store: storeIdentifierName?.toUpperCase(),
      result: {
        items: filteredProducts,
      },
      track: {
        CustomerKey: '',
        HashedEmail: '',
        Loyalty: loyalty,
        Webstore: '',
        DeviceID: '',
        AdobeMCVID: adobeMCVID,
        AthleteID: athleteId,
        isGold: isGold,
      },
      channel: 'web',
      parentId: mlSearchUUID,
      id: uuidv4(),
    };

    if (window.MLDataCollection && filteredProducts && filteredProducts.length > 0 && mlSearchUUID) {
      const { recordEvent } = window.MLDataCollection;
      recordEvent(store, payload);
      dispatch(setMLImpressionEcodes(filteredProducts));
      setEventFired(true);
    }
  };

  useEffect(() => {
    if (!eventFired && !!entry?.isIntersecting && storeIdentifierName) {
      fireEvent(storeIdentifierName.toUpperCase());
    }
  }, [entry]);

  return [eventFired];
};

export default useMachineLearningImpression;
