import { resultsViewUpdate } from '../plp-srlp-analytics';
import { pageSizeChange } from '../slices/input';
import { isSearching } from '../slices/common';
import { callAPI } from './utility/thunk-utils';

export function onPageSizeChange(pageCount) {
  return (dispatch, getState) => {
    dispatch(isSearching());
    dispatch(pageSizeChange({ pageSize: pageCount, setPageDefault: true }));

    const stateData = getState();
    const { input } = stateData;

    const refinementUpdateObj = { UpdateType: 'ProductPerPage', UpdateValue: pageCount };
    resultsViewUpdate(refinementUpdateObj);

    callAPI(input, dispatch, stateData);
  };
}
