import React, { memo } from 'react';
import StorePickup from './StorePickup/StorePickup';
import Sorting from './Sorting/Sorting';
import FilterHeading from './FilterHeading/FilterHeading';

type Props = {
  breakpoint: string;
};

const GridRowOne = ({ breakpoint }: Props) => {
  return (
    <div className="filter-store-sort-header">
      {breakpoint !== 'mobile' && <FilterHeading breakpoint={breakpoint} />}
      <StorePickup breakpoint={breakpoint} />
      {(breakpoint === 'desktop' || breakpoint === 'small-desktop') && <Sorting />}
    </div>
  );
};

export default memo(GridRowOne);
