import { useEffect } from 'react';
import axios from 'axios';
import { getStoreIdentifierNameFromVh, isProdURL } from '../../../utility/utils';

const CeopsScripts = ({ storeVH }) => {
  useEffect(() => {
    const path = window.location.pathname + window.location.search;
    const chain = getStoreIdentifierNameFromVh(storeVH);
    let apiHost = `https://ceops.dickssportinggoods.com`;
    if (isProdURL() === false) {
      apiHost = `https://stg-ceops.iso.an01.pcf.dcsg.com`;
    }

    const url = `${apiHost}/scripts?chain=${chain}&page=${path}`;

    axios.get(url).then((res) => {
      const CEOPSContent = res.data;

      if (CEOPSContent.scripts && CEOPSContent.scripts.length > 0) {
        Object.keys(CEOPSContent.scripts).forEach((i) => {
          if (CEOPSContent.scripts[i]) {
            // Update path
            CEOPSContent.scripts[
              i
            ].path = `${apiHost}/out${CEOPSContent.scripts[i].path}.min.js?_=${CEOPSContent.version}`;

            // Create and place script tag into document head
            const newEl = document.createElement('script');
            newEl.src = CEOPSContent.scripts[i].path;
            newEl.type = 'application/javascript';
            newEl.id = `ceopsScript${i}`;
            document.head.appendChild(newEl);
          }
        });
      }
    });
  }, []);

  return null;
};

export default CeopsScripts;
