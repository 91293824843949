import { decodeAndReplaceHTML, decodeHTML } from './utils';

export function updateFilter(identifier, value, filterType) {
  const searchParams = createFilterParameters();
  if (filterType === 'multi') {
    const valuesList = Array.isArray(value) ? value : [value];
    const values = searchParams.get(identifier) || [];
    valuesList.forEach((v) => {
      // Perform multi check and add or remove the filter values
      const remove = v[0].charAt(0) === '-';
      const filterValue = remove ? v.substring(1) : v;
      if (remove) {
        values.splice(values.indexOf(filterValue), 1);
      } else if (values.indexOf(filterValue) < 0) {
        values.push(filterValue);
      }

      if (!values || values.length === 0) {
        searchParams.delete(identifier);
      } else {
        searchParams.set(identifier, values.join(','));
      }
    });
  } else if (filterType === 'single') {
    if (value[0].charAt(0) === '-') {
      searchParams.delete(identifier);
    } else {
      searchParams.delete(identifier);
      searchParams.set(identifier, value);
    }
  } else {
    searchParams.set(identifier, value.join(','));
  }
  replaceHistory(searchParams, true);
}

export function updateUrlFilters(selectedFilters) {
  // Iterate over the selected filters

  if (
    selectedFilters &&
    typeof window !== 'undefined' &&
    window.location &&
    !window.location.href.includes('filterFacets=')
  ) {
    const keys = Object.keys(selectedFilters);
    if (!keys || keys.length === 0) {
      return;
    }
    let filtersString = '';
    keys.forEach((key, index) => {
      // Set the key in the url
      filtersString += `${key}:${selectedFilters[key].join(',')}${
        index === keys.length - 1 ? '' : ';'
      }`;
    });
    const searchParams = createSearchParameters();
    const includeAnd = searchParams && searchParams.size > 0;
    const includeQuery = !window.location.href.includes('?');
    const prependStr = includeAnd
      ? '&filterFacets='
      : includeQuery
      ? '?filterFacets='
      : 'filterFacets=';
    const newUrl =
      window.location.href.replace(/#/g, '') + prependStr + encodeURIComponent(filtersString);
    const title = document && document.title ? document.title : undefined;
    const currState = window.history.state;
    window.history.replaceState(currState, title, newUrl);
  }
}

/**
 *
 * @param {*} identifierValuePairs - Object that is structured as { identifier: string, value: string|number }
 */
export function updateResultsManipulators(identifierValuePairs, shouldReplacePageState) {
  const searchParams = createSearchParameters();
  identifierValuePairs.forEach((idValPair) => {
    searchParams.set(idValPair.identifier, idValPair.value);
  });
  replacePageManipHistory(searchParams, shouldReplacePageState);
}

export function removeFilterData(identifier) {
  const searchParams = createFilterParameters();
  searchParams.delete(identifier);
  replaceHistory(searchParams);
}

export function removeFilterDataValue(identifier, value) {
  const searchParams = createFilterParameters();
  const val = searchParams.get(identifier);
  if (val) {
    val.splice(val.indexOf(value), 1);
    if (val.length === 0) {
      removeFilterData(identifier);
    } else {
      updateFilter(identifier, val);
    }
  }
}

export function removeAllFilterData(dataObj) {
  const searchParams = createFilterParameters();

  for (const key in dataObj) {
    searchParams.delete(key);
  }

  replaceHistory(searchParams);
}

function replacePageManipHistory(searchParams, shouldReplaceState) {
  let search = '?';
  const pushState = true;
  searchParams.forEach((value, key) => {
    if (search !== '?') {
      search += '&';
    }
    const encodedValue = encodeURIComponent(value);
    if (key === 'searchTerm') {
      search += `${key}=${encodedValue.replace(/%2B+/g, '+')}`;
    } else {
      search += `${key}=${encodedValue}`;
    }
    //   }
  });

  const splitHash = window.location.href.split('#');
  const hashString = splitHash.length > 1 ? `#${splitHash[1]}` : '';
  const nextUrl = window.location.pathname + search + hashString;

  if (typeof history !== 'undefined' && !shouldReplaceState) {
    history.pushState(null, null, nextUrl);
  } else if (typeof history !== 'undefined') {
    history.replaceState(null, null, nextUrl);
  }
}

function replaceHistory(filterParams, resetPageNumber) {
  let search = '?';
  const searchParams = createSearchParameters();
  if (resetPageNumber) {
    searchParams.set('pageNumber', 0);
  }
  if (filterParams && filterParams.size > 0) {
    let filterValues = '';
    filterParams.forEach((value, key) => {
      if (filterValues.length !== 0) {
        filterValues += ';';
      }
      filterValues += `${key}:${value}`;
    });
    searchParams.set('filterFacets', filterValues);
  } else {
    searchParams.delete('filterFacets');
  }

  searchParams.forEach((value, key) => {
    if (search !== '?') {
      search += '&';
    }
    const encodedValue = encodeURIComponent(value);
    if (key === 'searchTerm') {
      search += `${key}=${encodedValue.replace(/%2B+/g, '+')}`;
    } else {
      search += `${key}=${encodedValue}`;
    }
  });

  if (typeof history !== 'undefined')
    history.pushState(null, null, window.location.pathname + search);
}

export function getFilterInformation(identifier) {
  const filterInfo = createFilterParameters();
  return filterInfo.get(identifier);
}

export function createSearchParameters() {
  const currentSearch = window.location ? window.location.search : '';
  const searchComponents = currentSearch.split('#')[0].split('&');
  const searchParams = new Map();
  searchComponents.forEach((comp) => {
    const keyVal = comp.split('=');
    if (keyVal.length === 2) {
      const key = keyVal[0].startsWith('?') ? keyVal[0].substring(1) : keyVal[0];
      let val = decodeAndReplaceHTML(keyVal[1]);
      val = decodeHTML(val); // Decode HTML Code Names to characters
      searchParams.set(key, val);
    }
  });

  return searchParams;
}

export function createFilterParameters(providedSearchParams) {
  // &filterFacets=facet:val,val,val;facet2:val,val
  const searchParams = providedSearchParams || createSearchParameters();
  const filterInfo = searchParams.get('filterFacets');
  const filterInfoMap = new Map();
  if (filterInfo) {
    const filterFacets = filterInfo.split(';');
    filterFacets.forEach((facetInfo) => {
      const facets = facetInfo.split(':');
      if (facets.length === 2) {
        filterInfoMap.set(facets[0], facets[1].split(','));
      }
    });
  }

  return filterInfoMap;
}

export function updateSearchTerm(newSearchTerm) {
  const searchParams = createSearchParameters();
  const currentSearchTerm = searchParams.get('searchTerm');

  let formattedNewSearchTerm = encodeURIComponent(newSearchTerm);
  formattedNewSearchTerm = formattedNewSearchTerm.replace(/%20+/g, '+');

  const newSearchURL = window.location.search.replace(currentSearchTerm, formattedNewSearchTerm);
  window.history.replaceState(null, null, window.location.pathname + newSearchURL);
}
