import React, { useMemo } from 'react'
import { useSelector } from 'react-redux';
import { getFeatureFlagByNameSelector, piqProductsSelector } from '../reduxStore/selectors';
import { deepCompareWithStringify } from '../utility/utils';
import { sponsoredProductsIntendedEvent, sponsoredProductsShownEvent } from '../utility/analytics-events';

export const usePiqProducts = () => {
  const rawPiqProducts = useSelector(piqProductsSelector, deepCompareWithStringify);
  const sponsoredListingDisabled = useSelector(getFeatureFlagByNameSelector('sponsoredListingDisabled'));
  const sponsoredAnalyticsTrackingEnabled = useSelector(getFeatureFlagByNameSelector('sponsoredAnalyticsTrackingEnabled'));

  const piqProducts = useMemo(() => {
    if (!rawPiqProducts) {
      return []
    }

    if (sponsoredAnalyticsTrackingEnabled) {
      sponsoredProductsIntendedEvent(!!rawPiqProducts.length)
      sponsoredProductsShownEvent(rawPiqProducts.length && !sponsoredListingDisabled)
    }

    if (sponsoredListingDisabled) {
      return []
    }

    return rawPiqProducts
  }, [rawPiqProducts,sponsoredListingDisabled])

  return {
    piqProducts
  }
}