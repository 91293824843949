import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class FilterIcon extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = '' + additionalClasses;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = '' + additionalPathClasses;

    return (
      <svg
        aria-hidden="true"
        width="14"
        height="12"
        viewBox="0 0 14 12"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M3.61414 2.25008C3.42912 2.96892 2.77658 3.50008 1.99998 3.50008C1.07951 3.50008 0.333313 2.75389 0.333313 1.83341C0.333313 0.91294 1.07951 0.166748 1.99998 0.166748C2.77665 0.166748 3.42924 0.698003 3.61419 1.41695L13.1858 1.41675C13.3778 1.41674 13.5334 1.60324 13.5334 1.8333C13.5334 2.06337 13.3778 2.24987 13.1858 2.24988L3.61414 2.25008ZM1.99998 2.60841C2.428 2.60841 2.77498 2.26144 2.77498 1.83341C2.77498 1.40539 2.428 1.05841 1.99998 1.05841C1.57196 1.05841 1.22498 1.40539 1.22498 1.83341C1.22498 2.26144 1.57196 2.60841 1.99998 2.60841Z"
          fill="#604858"
          className={pathClasses}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.70005 6.35841C8.53612 7.10667 7.86947 7.66675 7.072 7.66675C6.2954 7.66675 5.64286 7.13559 5.45784 6.41675L0.779148 6.4168C0.532921 6.4168 0.333313 6.2172 0.333313 5.97097C0.333313 5.72474 0.532917 5.52513 0.779144 5.52513L5.474 5.52508C5.67851 4.83602 6.31656 4.33342 7.072 4.33342C7.82746 4.33342 8.46552 4.83604 8.67002 5.52513L13.1214 5.52508C13.349 5.52507 13.5334 5.71161 13.5334 5.94171C13.5334 6.17182 13.349 6.35836 13.1215 6.35836L8.70005 6.35841ZM7.847 6.00008C7.847 6.4281 7.50002 6.77508 7.072 6.77508C6.64398 6.77508 6.297 6.4281 6.297 6.00008C6.297 5.57206 6.64398 5.22508 7.072 5.22508C7.50002 5.22508 7.847 5.57206 7.847 6.00008Z"
          fill="#604858"
          className={pathClasses}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M13.5303 10.1667C13.5303 11.0872 12.7841 11.8334 11.8637 11.8334C11.0871 11.8334 10.4346 11.3023 10.2495 10.5835L0.796389 10.5836C0.540638 10.5835 0.333313 10.3839 0.333313 10.1377C0.333313 9.89149 0.540642 9.69188 0.796393 9.69188L10.2657 9.69179C10.4701 9.00271 11.1082 8.50008 11.8637 8.50008C12.7841 8.50008 13.5303 9.24627 13.5303 10.1667ZM12.6387 10.1667C12.6387 10.5948 12.2917 10.9417 11.8637 10.9417C11.4356 10.9417 11.0887 10.5948 11.0887 10.1667C11.0887 9.73873 11.4356 9.39175 11.8637 9.39175C12.2917 9.39175 12.6387 9.73873 12.6387 10.1667Z"
          fill="#604858"
          className={pathClasses}
        />
      </svg>
    );
  }
}

FilterIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

FilterIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
