import React from 'react';
import PropTypes from 'prop-types';

const PLFilterIcon = ({ classes, pathClasses }) => {
  const additionalClasses = classes.join(' ').trim();
  const joinedClasses = `${additionalClasses}`;
  const additionalPathClasses = pathClasses.join(' ').trim();
  const joinedPathClasses = `${additionalPathClasses}`;

  return (
    <svg aria-hidden="true" width="16" height="14" viewBox="0 0 16 14" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M16 3.00001H0V0.333344H16V3.00001ZM14 8.33334H2V5.66668H14V8.33334ZM10.6667 13.6667H5.33333V11H10.6667V13.6667Z"
        fill="#002A19"
      />
    </svg>
  );
};

export default PLFilterIcon;

PLFilterIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

PLFilterIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
