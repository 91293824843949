import React from 'react';
import PropTypes from 'prop-types';

import Link from '../Link/Link';

import AlertWarningIcon from '../SVGIcons/AlertWarningIcon';
import AlertErrorIcon from '../SVGIcons/AlertErrorIcon';
import AlertInfoIcon from '../SVGIcons/AlertInfoIcon';
import AlertSuccessIcon from '../SVGIcons/AlertSuccessIcon';

const validTypes = ['warn', 'alert', 'info', 'success'];

const AlertMessage = (props) => {
  const { type, title, message, link, linkTitle, customClass, inlineIcon } = props;

  const icon = {
    warn: <AlertWarningIcon />,
    alert: <AlertErrorIcon />,
    info: <AlertInfoIcon />,
    success: <AlertSuccessIcon />,
  };

  // Default alertType to info and check if the passed in type is one of the valid ones if so use it
  let alertType = 'info';
  if (validTypes.includes(type)) {
    alertType = type;
  }

  return (
    <div
      className={`dsg-flex flex-grow-none react-rs-alert-message react-rs-alert-type-${alertType} ${customClass}`}>
      {inlineIcon === false && icon[alertType]}
      <p>
        <strong>
          {inlineIcon === true && icon[alertType]}
          {title}
        </strong>
        <br />
        {message} {link && <Link href={link} text={linkTitle} inline />}
      </p>
    </div>
  );
};

export default AlertMessage;

AlertMessage.propTypes = {
  title: PropTypes.string.isRequired,
  message: PropTypes.string.isRequired,
  type: PropTypes.oneOf(validTypes).isRequired,
  link: PropTypes.string,
  linkTitle: PropTypes.string,
  customClass: PropTypes.string,
  inlineIcon: PropTypes.bool,
};

AlertMessage.defaultProps = {
  link: false,
  linkTitle: false,
  customClass: '',
  type: 'info',
  inlineIcon: false,
};
