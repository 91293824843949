import React from 'react';
import PropTypes from 'prop-types';

const AddToCartIcon = (props) => {
  const additionalClasses = props.classes.join(' ').trim();
  const classes = `${additionalClasses}`;
  const additionalPathClasses = props.pathClasses.join(' ').trim();
  const pathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      className={classes}
      xmlns="http://www.w3.org/2000/svg">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.666748 1.33331V2.66665H2.00008L4.40008 7.72665L3.50008 9.35331C3.01341 10.2466 3.65341 11.3333 4.66675 11.3333H12.6667V9.99998H4.66675L5.40008 8.66665H10.3667C10.8667 8.66665 11.3067 8.39331 11.5334 7.97998L13.9201 3.65331C14.1667 3.21331 13.8467 2.66665 13.3401 2.66665H3.47341L2.84675 1.33331H0.666748ZM4.66675 12C3.93341 12 3.34008 12.6 3.34008 13.3333C3.34008 14.0666 3.93341 14.6666 4.66675 14.6666C5.40008 14.6666 6.00008 14.0666 6.00008 13.3333C6.00008 12.6 5.40008 12 4.66675 12ZM10.0067 13.3333C10.0067 12.6 10.6001 12 11.3334 12C12.0667 12 12.6667 12.6 12.6667 13.3333C12.6667 14.0666 12.0667 14.6666 11.3334 14.6666C10.6001 14.6666 10.0067 14.0666 10.0067 13.3333Z"
        fill="#BB5811"
        className={pathClasses}
      />
    </svg>
  );
};

export default AddToCartIcon;

AddToCartIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

AddToCartIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
