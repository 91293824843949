import React, { memo } from 'react';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import PageTitle from './PageTitle/PageTitle';
import SuperFamilyCategoryCarousel from './SuperFamilyCategoryCarousel/SuperFamilyCategoryCarousel';
import NoProductsResults from './NoProductResults/NoProductResults';

/**
 * Component that will contain Header Section that is Breadcrumb, Title and carousel for top categories as images.
 */

type Props = {
  totalCount: number;
  selectedFiltersCheck: Boolean;
  isSearching: Boolean;
};

const HeaderSection = ({ totalCount, selectedFiltersCheck, isSearching }: Props) => {
  return (
    <>
      <Breadcrumb />
      <PageTitle />
      {totalCount === 0 && selectedFiltersCheck && !isSearching ? <NoProductsResults /> : null}
      <SuperFamilyCategoryCarousel />
    </>
  );
};

export default memo(HeaderSection);
