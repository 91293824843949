import { ProductQuickViewParams, ProductVO, SelectedFilters } from '../../../../types';

/* eslint-disable import/prefer-default-export */
export const getCatentryId = (isFamilyPage: boolean, product: ProductVO) => {
  let catentryId;
  if (isFamilyPage && product.parentCatentryId) {
    catentryId = product.parentCatentryId;
  } else if (product.parentCatentryId) {
    catentryId = product.parentCatentryId;
  } else {
    catentryId = product.catentryId;
  }
  return catentryId;
};

export const audienceSelector = () => {
  let loyalty;
  let isGold;
  if (window._dsgTag) {
    loyalty = window._dsgTag.CustomerIDs.Loyalty;
    isGold = window._dsgTag.CustomerIDs.isGold;
  }

  if (loyalty && isGold) {
    return 3;
  } else if (loyalty && !isGold) {
    return 2;
  } else {
    return 1;
  }
};

export const getExclusiveProduct = (attributes) => {
  if (attributes && Array.isArray(attributes)) {
    let exclusiveProduct = attributes.find(
      (attribute) =>
        attribute['5632'] &&
        ['Nike APE Exclusive', 'Gold APE Exclusive'].includes(attribute['5632']),
    );
    return exclusiveProduct ? exclusiveProduct['5632'] : exclusiveProduct;
  }
};
type passableFilterList = { [key: number]: string };
const passableFilterList: passableFilterList = {
  299: 'size',
  5212: `mensshoesize`,
  5213: `womensshoesize`,
  5214: `kidsshoesize`,
  304: 'hand',
  1918: 'length',
  5471: 'shoewidth',
  5461: 'shoesbyagegroup',
  5475: 'sizerange',
  5330: 'shopbyage',
  1895: 'throwinghand',
  4757: 'flex',
  4756: 'loft',
  2147: 'rodlength',
  4263: 'framesize',
  3997: 'power',
  5434: 'sportsbrasize',
  5336: 'shoesize',
  3945: 'glovehand',
  405: 'socksize',
  1925: 'inseam',
  3556: 'caliber-gauge',
  5173: 'skatesize',
  3713: 'handsize',
};
export const generatePdpURL = (
  product,
  categoryIdentifier,
  snbAudience,
  catgroupId,
  selectedFilters: SelectedFilters,
  selectedSwatchVal,
  productName,
) => {
  let appendUrl = false;
  let generateSeoUrl = '';
  if (product.dsgSeoUrl) {
    generateSeoUrl = product.dsgSeoUrl;
  } else if (product.assetSeoUrl) {
    generateSeoUrl = product.assetSeoUrl;
  } else if (productName && product.thumbnail) {
    generateSeoUrl = `/p/${productName}-${product.thumbnail}/${product.thumbnail}`;
  }
  if (categoryIdentifier && categoryIdentifier.lastIndexOf('SKU-', 0) === 0) {
    generateSeoUrl = `${generateSeoUrl}?categoryId=${catgroupId}`;
    appendUrl = true;
  } else if (categoryIdentifier && categoryIdentifier.indexOf('Clearance') === 0) {
    generateSeoUrl = `${generateSeoUrl}?Clearance=true`;
    appendUrl = true;
  } else if (snbAudience && snbAudience === 'anti_clearance_phase1') {
    generateSeoUrl = `${generateSeoUrl}?Clearance=false`;
    appendUrl = true;
  }
  if (
    selectedSwatchVal &&
    selectedSwatchVal.swatchColor &&
    selectedSwatchVal.thumbnail &&
    selectedSwatchVal.thumbnail === product.thumbnail
  ) {
    if (!appendUrl) {
      generateSeoUrl = `${generateSeoUrl}?color=${encodeURIComponent(
        selectedSwatchVal.swatchColor,
      )}`;
    } else {
      generateSeoUrl = `${generateSeoUrl}&color=${encodeURIComponent(
        selectedSwatchVal.swatchColor,
      )}`;
    }
    appendUrl = true;
  }
  if (selectedFilters) {
    for (const [key, value] of Object.entries(selectedFilters)) {
      if (key === 'facetStore') {
        if (!appendUrl) {
          generateSeoUrl = `${generateSeoUrl}?`;
        } else {
          generateSeoUrl = `${generateSeoUrl}&`;
        }
        const bopisIndex = value.indexOf('BOPIS');
        const isaIndex = value.indexOf('ISA');
        if (bopisIndex >= 0 || isaIndex >= 0) {
          generateSeoUrl += `bopisFilter=`;
          generateSeoUrl += bopisIndex >= 0;
          generateSeoUrl += `&isaFilter=`;
          generateSeoUrl += isaIndex >= 0;
        }
        appendUrl = true;
      } else if (Object.keys(passableFilterList).includes(key)) {
        if (!appendUrl) {
          generateSeoUrl = `${generateSeoUrl}?`;
        } else {
          generateSeoUrl = `${generateSeoUrl}&`;
        }
        generateSeoUrl += `${passableFilterList[parseInt(key)]}=${value}`;
        appendUrl = true;
      }
    }
  }
  return generateSeoUrl;
};
const piqEnabledStores = ['dsg'];
export const piqEnabledStore = (storeIdentifierName: string | undefined) => {
  if (storeIdentifierName && piqEnabledStores.includes(storeIdentifierName)) {
    return true;
  } else {
    return false;
  }
};

  export const openQuickView = (productQuickViewParams:ProductQuickViewParams) => {
    const {ecode, isFamilyPage, categoryIdentifier, catgroupId, swatchColor, template} = productQuickViewParams;
    const referrer = isFamilyPage ? 'PLP' : 'SRLP';
    const optionalParams = {
      clearance: categoryIdentifier && categoryIdentifier.indexOf('Clearance') === 0,
      skuCategory: categoryIdentifier && categoryIdentifier.lastIndexOf('SKU-', 0) === 0 && catgroupId,
      preview: false,
      skuPartNumber: '',
      defaultColor: swatchColor,
      template,
    };

    if (window && window.quickview && window.quickview.openProductQuickView) {
      window.quickview.openProductQuickView(
        ecode,
        referrer,
        optionalParams
      );
    }
  };