import React from 'react';
import { Provider } from 'react-redux';
import { render, hydrate } from 'react-dom';
import lazy from 'react-lazy-ssr';

import { analyticMiddleWare, rootReducer } from './reduxStore/store';
import { createAnalyticData } from './reduxStore/slices/common';
import { getSearchSNBAudience, getPreview, setDsgVhInitialState } from './utility/utils';
import './polyfills.js';
import { configureStore } from '@reduxjs/toolkit';
import ProductGridTemplate from './components/ProductGridTemplate';

const app = document.getElementById('search-page');

if (window.__STATE__) {
  // Hydrate from server side rendered state (PLP)

  const initialState = window.__STATE__;
  delete window.__STATE__;

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analyticMiddleWare),
    devTools: process.env.NODE_ENV !== 'production',
  });

  store.dispatch(createAnalyticData(initialState));

  lazy.preloadAll().then(() => {
    hydrate(
      <Provider store={store}>
        <ProductGridTemplate />
      </Provider>,
      app,
    );
  });
} else {
  // Client Side Render for search page (SRLP)

  let initialState = setDsgVhInitialState();
  initialState.input.snbAudience = getSearchSNBAudience();
  initialState.input.preview = getPreview();

  const store = configureStore({
    reducer: rootReducer,
    preloadedState: initialState,
    middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(analyticMiddleWare),
    devTools: process.env.NODE_ENV !== 'production',
  });

  if (app) {
    app.innerHTML = '';
  }
  render(
    <Provider store={store}>
      <ProductGridTemplate />
    </Provider>,
    app,
  );
}
