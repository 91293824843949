export const promotionAppKeys = {
  prod: 'c0fcfbe0-9983-11e7-b506-c779759784e8',
  nonprod: 'fec9b1d0-47c5-11e7-949f-bd36d1ca45b0',
};

export const promotionEnvValues = {
  prod: 'prdlive',
  nonprod: 'prdstage',
};

export const validDsgVhValues = [
  '111',
  '112',
  '121',
  '125',
  '131',
  '132',
  '141',
  '142',
  '151',
  '152',
  '161',
  '162',
  '171',
  '172',
  '181',
  '182'
];

export const envData = {
  dsg: {
    storeId: 15108,
    storeIdentifier: 'dsg',
    catalogId: 12301,
    channelId: -1,
    domainName: 'dickssportinggoods.com',
    microsite: false,
    defaultTitle: (categoryName) => {
      return `${categoryName} | DICK'S Sporting Goods`;
    },
    defaultDescription: (categoryName) => {
      return `Shop ${categoryName} at DICK'S Sporting Goods. If you find a lower price on ${categoryName} somewhere else, we'll match it with our Best Price Guarantee.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: "Time Out. We can't find that page.",
      p:
        "The page you're looking for isn't available. Try a different page or use the Go Back button below.",
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Time Out. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  gg: {
    storeId: 10701,
    storeIdentifier: 'gg',
    catalogId: 10051,
    channelId: -1,
    domainName: 'golfgalaxy.com',
    microsite: false,
    defaultTitle: (categoryName) => {
      return `${categoryName} | Golf Galaxy`;
    },
    defaultDescription: (categoryName) => {
      return `Shop ${categoryName} at Golf Galaxy. If you find a lower price on ${categoryName} somewhere else, we'll match it with our Best Price Guarantee.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  fns: {
    storeId: 11201,
    storeIdentifier: 'fns',
    catalogId: 11101,
    channelId: -1,
    domainName: 'fieldandstreamshop.com',
    microsite: false,
    defaultTitle: (categoryName) => {
      return `${categoryName} | Field & Stream`;
    },
    defaultDescription: (categoryName) => {
      return `Shop ${categoryName} at Field & Stream. If you find a lower price on ${categoryName} somewhere else, we'll match it with our Best Price Guarantee.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  calia: {
    storeId: 15108,
    storeIdentifier: 'calia',
    catalogId: 12301,
    channelId: -1,
    domainName: 'calia.com',
    microsite: true,
    defaultTitle: (categoryName) => {
      return `${categoryName} | CALIA`;
    },
    defaultDescription: (categoryName) => {
      return `Enjoy free shipping on ${categoryName} from CALIA. Stay on the path with ${categoryName} and other active apparel at great prices.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  vrst: {
    storeId: 15108,
    storeIdentifier: 'vrst',
    catalogId: 12301,
    channelId: -1,
    domainName: 'vrst.com',
    microsite: true,
    defaultTitle: (categoryName) => {
      return `${categoryName} | VRST`;
    },
    defaultDescription: (categoryName) => {
      return `Enjoy free shipping on ${categoryName} from VRST. Stay on the path with ${categoryName} and other active apparel at great prices.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  pl: {
    storeId: 16066,
    storeIdentifier: 'pl',
    catalogId: 20310,
    channelId: -1,
    domainName: 'publiclands.com',
    microsite: false,
    defaultTitle: (categoryName) => {
      return `${categoryName} | Public Lands`;
    },
    defaultDescription: (categoryName) => {
      return `Explore ${categoryName} at Public Lands. Shop high-quality products from trusted brands and get the perfect fit for your needs.`;
    },
    errorPage: {
      image: 'https://dks.scene7.com/is/image/dksfed/CBU_PL_404_OffMap',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://dks.scene7.com/is/image/dksfed/CBU_PL_404_OffMap',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  g3: {
    storeId: 15108,
    storeIdentifier: 'g3',
    catalogId: 12301,
    channelId: -1,
    domainName: 'goinggoinggone.com',
    microsite: true,
    defaultTitle: (categoryName) => {
      return `${categoryName} | Going Going Gone`;
    },
    defaultDescription: (categoryName) => {
      return `Shop ${categoryName} at Going Going Gone. If you find a lower price on ${categoryName} somewhere else, we'll match it with our Best Price Guarantee.`;
    },
    errorPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://s7d2.scene7.com/is/image/dksfed/DSG_CLP_404Error?$png$',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
  mj: {
    storeId: 16066,
    storeIdentifier: 'mj',
    catalogId: 20310,
    channelId: -1,
    domainName: 'moosejaw.com',
    microsite: false,
    defaultTitle: (categoryName) => {
      return `${categoryName} | Moosejaw`;
    },
    defaultDescription: (categoryName) => {
      return `Explore ${categoryName} at Moosejaw. Shop high-quality products from trusted brands and get the perfect fit for your needs.`;
    },
    errorPage: {
      image: 'https://dks.scene7.com/is/image/dksfed/CBU_PL_404_OffMap',
      h1: '404',
      strong: 'Oops. This page is currently unavailable.',
      p: 'Try a different page or use the go back button.',
    },
    badRequestPage: {
      image: 'https://dks.scene7.com/is/image/dksfed/CBU_PL_404_OffMap',
      h1: '400',
      strong: "Oops. We're having trouble processing your request.",
      p: 'The request you sent is invalid. Try a different page or use the Go Back button below.',
    },
  },
};
