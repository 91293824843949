import React from 'react';
import { useSelector } from 'react-redux';
import Link from '../../../common/Link/Link';

import { handleADA } from '../../../../utility/utils';
import {
  getFeatureFlagByNameSelector,
  snbAudienceSelector,
} from '../../../../reduxStore/selectors/index';
import { getCookie } from '../../../../utility/cookie-utils';
import useZipCode from '../../../../hooks/useZipCode';

type StoreDisplayProps = {
  selectedStoreName: string,
  toggleMyStoreModal: () => null,
  facetStore?: string,
};

const StoreDisplay = ({
  selectedStoreName,
  toggleMyStoreModal,
  facetStore = '',
}: StoreDisplayProps) => {
  const snbAudience = useSelector(snbAudienceSelector);
  const SDDEnabled = useSelector(getFeatureFlagByNameSelector('SDDEnabled'));

  const sddZip = useZipCode()

  const storeVerbiage = () => {
    switch (facetStore) {
      case 'SDD':
        return 'Deliver To';
      case 'BOPIS':
        return 'Pickup At';
      default:
        return 'Ship To';
    }
  };

  const storeDeliverAriaContent = (sddZip) => {
    return {
      SDD: `Change same Day Delivery zip from ${sddZip}`,
      SHIP: `Change shipping zip from ${sddZip}`,
    }[facetStore];
  };

  const handleSameDayDeliveryService = () => {
    if (
      typeof window !== 'undefined' &&
      window.headerFunctions &&
      window.headerFunctions.sameDayDelivery
    ) {
      window.headerFunctions.sameDayDelivery();
    }
  };
  const storeLink = () => {
    switch (facetStore) {
      case 'SDD':
      case 'SHIP':
        return (
          <Link
            text={sddZip}
            classes={['dsg-selected-store']}
            tabIndex={0}
            ariaLabel={storeDeliverAriaContent(sddZip)}
            onClick={handleSameDayDeliveryService}
            onKeyPress={(e) => handleADA(e, 'button', handleSameDayDeliveryService)}
            inline={false}
            secondary
          />
        );
      case 'BOPIS':
        return (
          <Link
            text={selectedStoreName.toLowerCase()}
            classes={['dsg-selected-store']}
            tabIndex={0}
            ariaLabel={
              selectedStoreName && selectedStoreName != 'Select a nearby store'
                ? `Change selected store from ${selectedStoreName}`
                : `No active store selected. Click to Select a store`
            }
            onClick={toggleMyStoreModal}
            onKeyPress={(e) => handleADA(e, 'button', toggleMyStoreModal)}
            inline={false}
            secondary
          />
        );
      default:
        const storeZip = getCookie('setStoreCookie').slice(0, 5);
        return (
          <Link
            text={storeZip || 'Select a nearby store'}
            classes={['dsg-selected-store']}
            tabIndex={0}
            ariaLabel={
              selectedStoreName && selectedStoreName != 'Select a nearby store'
                ? `Change selected store zip from ${storeZip}`
                : `No active store selected. Click to Select a store`
            }
            onClick={toggleMyStoreModal}
            onKeyPress={(e) => handleADA(e, 'button', toggleMyStoreModal)}
            inline={false}
            secondary
          />
        );
    }
  };

  return (
    <>
      {SDDEnabled ? (
        <div
          className={`dsg-flex flex-shrink justify-content-space-between ${
            snbAudience && snbAudience === 'improvedProductGrid'
              ? 'dsg-new-store-display align-items-center'
              : 'dsg-store-display align-items-center'
          }`}>
          <div className="dsg-store-display-label">
            <p className="dsg-store-display-shoppingat hmf-label-xs">{storeVerbiage()}</p>
            {storeLink()}
          </div>
        </div>
      ) : (
        <div
          className={`dsg-flex flex-shrink justify-content-space-between ${
            snbAudience && snbAudience === 'improvedProductGrid'
              ? 'dsg-new-store-display align-items-center'
              : 'dsg-store-display align-items-flex-end'
          }`}>
          <div className="dsg-store-display-label">
            <p className="dsg-store-display-shoppingat hmf-label-xs">My Store</p>

            <Link
              text={selectedStoreName.toLowerCase()}
              classes={['dsg-selected-store']}
              tabIndex={0}
              ariaLabel={
                selectedStoreName && selectedStoreName != 'Select a nearby store'
                  ? `Change selected store from ${selectedStoreName}`
                  : `No active store selected. Click to Select a store`
              }
              onClick={toggleMyStoreModal}
              onKeyPress={(e) => handleADA(e, 'button', toggleMyStoreModal)}
              inline={false}
              secondary
            />
          </div>
        </div>
      )}
    </>
  );
};

export default StoreDisplay;
