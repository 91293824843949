import React from 'react';
import PropTypes from 'prop-types';

export default class Link extends React.PureComponent {
  render() {
    const extraClasses =
      this.props.classes.length > 0 ? ` ${this.props.classes.join(' ').trim()}` : '';
    const classList = this.props.inline
      ? 'dsg-react-inline-hyperlink'
      : `dsg-react-hyperlink${extraClasses}`;

    const onClick = (event) => {
      event.preventDefault();

      if (typeof this.props.onClick === 'function') {
        this.props.onClick(event);
      }
    };

    return (
      <a
        className={`${classList} ${this.props.secondary ? 'secondary' : ''}`}
        onClick={this.props.href === '#' ? onClick : this.props.onClick}
        href={this.props.href}
        target={this.props.target}
        rel={this.props.rel}
        tabIndex={this.props.tabIndex}
        aria-label={this.props.ariaLabel}
        aria-labelledby={this.props.labelledby}
        data-em={this.props.dataEm}>
        {this.props.text}
      </a>
    );
  }
}

Link.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the link
  text: PropTypes.oneOfType([
    PropTypes.string, // The link text string
    PropTypes.object, // Link text object
  ]).isRequired,
  href: PropTypes.string,
  onClick: PropTypes.func,
  inline: PropTypes.bool,
  secondary: PropTypes.bool,
  tabIndex: PropTypes.number,
  target: PropTypes.string,
  rel: PropTypes.string,
  labelledby: PropTypes.string,
  dataEm: PropTypes.string,
};

Link.defaultProps = {
  classes: [],
  href: '#',
  inline: false,
  secondary: false,
  tabIndex: 0,
};
