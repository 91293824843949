import React from 'react';
import { useSelector } from 'react-redux';
import SuccessCircle from '../../../common/SVGIcons/QuickViewIcons/SuccessCircle';
import ErrorCircle from '../../../common/SVGIcons/QuickViewIcons/ErrorCircle';

const shippingMessage = {
  OutOfStock: 'Not Available to Ship',
  LowStock: 'Limited Stock to Ship',
  InStock: 'Shipping Available',
};
const pickUpMessage = {
  OutOfStock: 'Not Available to Pickup',
  LowStock: 'Limited Stock to Pickup',
  InStock: 'Free Pickup Available',
  AvailableNearBy: 'In Nearby Stores',
  ShipToStore: 'Free Ship to Store',
};
const icons = {
  OutOfStock: <ErrorCircle classes={['rs_circle_icon_hw']} />,
  LowStock: (
    <SuccessCircle classes={['rs_circle_icon_hw']} pathClasses={['rs_limited_stock_color']} />
  ),
  InStock: <SuccessCircle classes={['rs_circle_icon_hw']} />,
  AvailableNearBy: (
    <SuccessCircle classes={['rs_circle_icon_hw']} pathClasses={['rs_limited_stock_color']} />
  ),
  ShipToStore: <SuccessCircle classes={['rs_circle_icon_hw']} />,
};
const ProductItemAvailability = ({ itemAvailability, comingSoon }) => {
  // Get selected storeNumber from redux store
  const storeNumber = useSelector((state) => state.input && state.input.selectedStore);

  const { onlineInventoryMessage, inStoreInventoryMessage } = itemAvailability;
  const itemAvailabilityPickup = () => {
    return (
      <span>
        {icons[inStoreInventoryMessage]}
        {'  '}
        {pickUpMessage[inStoreInventoryMessage]}
      </span>
    );
  };
  const itemAvailabilityShipping = () => {
    return (
      <span>
        {icons[onlineInventoryMessage]}
        {'  '}
        {shippingMessage[onlineInventoryMessage]}
      </span>
    );
  };
  return (
    <div
      className="rs_product_IA dsg-flex flex-wrap justify-content-space-around align-items-center"
      tabIndex={0}>
      {onlineInventoryMessage && comingSoon && itemAvailabilityShipping()}
      {storeNumber &&
        storeNumber !== '0' &&
        comingSoon &&
        inStoreInventoryMessage &&
        itemAvailabilityPickup()}
    </div>
  );
};
export default ProductItemAvailability;
