import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

export default class SuccessCircle extends PureComponent {
  render() {
    const additionalClasses = this.props.classes.join(' ').trim();
    const classes = `${additionalClasses}`;
    const additionalPathClasses = this.props.pathClasses.join(' ').trim();
    const pathClasses = `${additionalPathClasses}`;

    return (
      <svg
        width="14"
        height="14"
        viewBox="0 0 14 14"
        fill="none"
        className={classes}
        xmlns="http://www.w3.org/2000/svg">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M1.1665 6.99935C1.1665 3.77935 3.77984 1.16602 6.99984 1.16602C10.2198 1.16602 12.8332 3.77935 12.8332 6.99935C12.8332 10.2193 10.2198 12.8327 6.99984 12.8327C3.77984 12.8327 1.1665 10.2193 1.1665 6.99935ZM2.9165 6.99935L5.83317 9.91602L11.0832 4.66602L10.2607 3.83768L5.83317 8.26518L3.739 6.17685L2.9165 6.99935Z"
          fill="#008D75"
          className={pathClasses}
        />
      </svg>
    );
  }
}
SuccessCircle.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

SuccessCircle.defaultProps = {
  classes: [],
  pathClasses: [],
};
