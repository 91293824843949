import React, { memo } from 'react';

const Badge = ({ badgeName, storeIdentifierName }) => {
  return (
    <div className="badge_container">
      {badgeName && (
        <img
          src={`https://images.dickssportinggoods.com/assets/badges/${storeIdentifierName}/plp/PLP-${badgeName}.svg`}
          alt={badgeName}
          height="24"
          width={badgeName === 'TopSeller' ? '87' : '116'}
          loading="lazy"
        />
      )}
    </div>
  );
};

export default memo(Badge);
