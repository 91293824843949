import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';

import ShippingMethodButton from './ShippingMethodButton';
import StoreDisplay from '../StoreDisplay/StoreDisplay';
import {
  getFeatureFlagByNameSelector,
  selectedFiltersSelector,
} from '../../../../reduxStore/selectors';

const ShippingMethodFilter = ({
  storeLabel,
  selectedStoreName,
  selectedShippingMethods,
  toggleMyStoreModal,
  storeFacet,
  breakpoint,
}) => {
  const selectedFilters = useSelector(selectedFiltersSelector);
  const SDDEnabled = useSelector(getFeatureFlagByNameSelector('SDDEnabled'));

  const updateFilterOrderEnabled = useSelector(
    getFeatureFlagByNameSelector('updateFilterOrderEnabled'),
  );

  const facetStoreMemo = useMemo(() => {
    if (!selectedFilters.facetStore) {
      return '';
    }

    return selectedFilters.facetStore[0];
  }, [selectedFilters]);

  if (breakpoint === 'mobile') return null;

  const SelectStoreDisplay = () => {
    return (
      <div className="dsg-flex align-items-center flex-grow-none flex-shrink flex-wrap dsg-rs-store-options">
        {SDDEnabled ? (
          <StoreDisplay
            selectedStoreName={selectedStoreName}
            toggleMyStoreModal={toggleMyStoreModal}
            facetStore={facetStoreMemo}
          />
        ) : (
          <StoreDisplay selectedStoreName={storeLabel} toggleMyStoreModal={toggleMyStoreModal} />
        )}
      </div>
    );
  };

  const ShippingMethod = ({ label, type }) => {
    return (
      <>
        <ShippingMethodButton
          label={label}
          type={type}
          selectedShippingMethods={selectedShippingMethods}
          selectedStoreName={selectedStoreName}
          storeFacet={storeFacet}
          allowDisable={false}
        />
        {facetStoreMemo === type && updateFilterOrderEnabled && <SelectStoreDisplay />}
      </>
    );
  };

  let orderShippingMethods = [];
  const updateFilterCopy = 'Pickup';
  if (updateFilterOrderEnabled && SDDEnabled) {
    orderShippingMethods = [
      { label: updateFilterCopy, type: 'BOPIS' },
      { label: 'Same Day Delivery', type: 'SDD' },
      { label: 'Shipping', type: 'SHIP' },
    ];
  } else if (updateFilterOrderEnabled && !SDDEnabled) {
    orderShippingMethods = [
      { label: updateFilterCopy, type: 'BOPIS' },
      { label: 'Shipping', type: 'SHIP' },
      { label: 'Shopping In-Store', type: 'ISA' },
    ];
  } else {
    orderShippingMethods = [
      { label: 'Ship', type: 'SHIP' },
      { label: updateFilterCopy, type: 'BOPIS' },
      {
        label: SDDEnabled ? 'Same Day Delivery' : 'Shopping In-Store',
        type: SDDEnabled ? 'SDD' : 'ISA',
      },
    ];
  }

  return (
    <div className="dsg-flex align-items-center flex-grow-none flex-shrink dsg-rs-shipping-method-filter-container">
      <div className="dsg-flex align-items-center flex-grow-none flex-shrink">
        <span className="hmf-mr-xs">SHIPPING</span>
        {!updateFilterOrderEnabled && <SelectStoreDisplay />}
        {orderShippingMethods.map((shippingMethod) => {
          const { label, type } = shippingMethod;
          return (
            <div
              key={label}
              className="dsg-flex align-items-center flex-grow-none flex-shrink hmf-mr-xs">
              <ShippingMethod label={label} type={type} />
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ShippingMethodFilter;
