import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { handleADA } from '../../../utility/utils';

const Checkbox = (props) => {
  const {
    facetName,
    facetCount,
    disabled,
    labelledby,
    checkboxId,
    onCheckboxToggle,
    selectedData,
    facetValue,
  } = props;

  const [isChecked, setIsChecked] = useState(false);

  useEffect(() => {
    if (selectedData && selectedData.indexOf(facetValue.value) > -1) {
      setIsChecked(true);
    } else {
      setIsChecked(false);
    }
  }, [selectedData, facetValue]);

  const onCheckHandler = (evt) => {
    if (disabled) {
      return;
    }
    setIsChecked(true);
    onCheckboxToggle(evt);
  };

  return (
    <div
      className={`dsg-size-wrapper justify-content-space-between align-items-center dsg-flex col-sd-50 ${
        isChecked ? 'rs-checked-size' : ' rs-unchecked-size'
      }`}
      tabIndex="0"
      data-testid="checkbox"
      onMouseDown={(e) => {
        e.preventDefault();
      }}
      onClick={onCheckHandler}
      onKeyPress={(e) => handleADA(e, 'button', onCheckHandler)}
      alt={typeof facetName === 'string' ? facetName : null}
      role="checkbox"
      aria-checked={isChecked}
      aria-label={facetName}
      title={facetName}>
      <input
        type="checkbox"
        id={checkboxId}
        title={facetName}
        checked={isChecked}
        onChange={onCheckHandler}
        tabIndex="-1"
        aria-hidden={true}
      />
      <span className="facet-size-checkbox-label">{facetName}</span>
      <span className="facet-size-checkbox-count">({facetCount})</span>
    </div>
  );
};

Checkbox.defaultProps = {
  size: 'normal',
  labelledby: null,
};

Checkbox.propTypes = {
  size: PropTypes.oneOf(['normal', 'large']), // Size of checkbox
  disabled: PropTypes.bool, // Disabled flag
  onCheckboxToggle: PropTypes.func.isRequired, // Function called when checkbox is toggled
  isChecked: PropTypes.bool, // Is checked or is not checked
  checkboxId: PropTypes.string.isRequired, // The id for the checkbox and the label to make the label for the checkbox
  labelledby: PropTypes.string,
};

export default Checkbox;
