import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { updateFilter } from '../../../../utility/history';
import { toggleOpenCloseFacet, handleADA } from '../../../../utility/utils';
import RemoveCircleIcon from '../../../common/SVGIcons/RemoveCircleIcon';

import FacetSizeCheckBox from '../../../common/FacetCheckBox/FacetSizeCheckBox';
import FacetSearchBar from '../FacetSearchBar/FacetSearchBar';
import CreateFacetContainer from './CreateFacetContainer';

const SizeSelectFacet = ({
  facet,
  selectedData,
  onFilterData,
  openFacet,
  storeName,
  onSelection,
}) => {
  const [facetExpanded, setFacetExpanded] = useState(openFacet);
  const [valueList, setValueList] = useState([]);
  const [value, setValue] = useState('');
  const [facetVal, setFacetVal] = useState(facet);
  const [showNotFound, setShowNotFound] = useState(false);
  const [expandValue, setExpandValue] = useState(false);
  const [isFilterExpanded, setIsFilterExpanded] = useState(false);

  const onFilterChange = (val, e) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = facet.attrIdentifier;
    const values = [];
    if (selectedData && selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'multi');
    updateFilter(identifier, values, 'multi');
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }

      onFilterChange(filterValue);
    };
  };
  const viewAllFilterHandler = (identifier) => {
    setIsFilterExpanded(true);
    expandList(facet.valueList, identifier);
  };
  const createFacetValues = (valueList, identifier, selectedData, showLimit) => {
    let sizeSelectValues = valueList;
    if (showLimit) {
      // Slice the array to a max of 11 items since we only display 10 before showing View All Sizes
      sizeSelectValues = sizeSelectValues.slice(0, 11);
    }

    return sizeSelectValues.map((value, i) => {
      if (i >= 10 && showLimit) {
        return (
          <div
            className="rs-view-all size"
            onMouseDown={(e) => {
              e.preventDefault();
            }}
            onClick={viewAllFilterHandler}
            onKeyPress={(e) => handleADA(e, 'button', viewAllFilterHandler)}
            key="view_all_sizes"
            tabIndex="0">
            View All Sizes +
          </div>
        );
      }

      const facetValue = valueList[i];
      const isChecked = selectedData && selectedData.indexOf(facetValue.value) > -1;
      const disabled = !isChecked && facetValue.count !== undefined && facetValue.count === 0;
      return (
        <FacetSizeCheckBox
          selectedData={selectedData}
          key={`${identifier}_${i}`}
          checkboxId={`checkbox_${identifier}_${i}`}
          onCheckboxToggle={createOnFilterChangeCallback(facetValue.value, disabled)}
          disabled={disabled}
          labelledby={`${identifier}_facet_value_${facetValue.value}`}
          facetName={facetValue.value}
          facetCount={facetValue.count}
          isCheckedValue={isChecked}
          facetValue={facetValue}
        />
      );
    });
  };

  const expandList = (valueList, identifier, showNotFound, value = '') => {
    const facetList = [];
    if (showNotFound) {
      facetList.push(
        <div key={`${value}`} className="rs-facet-error-search dsg-flex align-items-center">
          <RemoveCircleIcon pathClasses={['store-pickup-search-error']} />
          <span>{`No results found for '${value}'`}</span>
        </div>,
      );
      setValueList(facetList);
      setExpandValue(true);
      setShowNotFound(showNotFound);
    } else {
      setValueList(createFacetValues(valueList, identifier, selectedData, false));
      setExpandValue(true);
      setShowNotFound(showNotFound);
    }
  };

  const searchData = (evt, value) => {
    if (evt) {
      if (facet) {
        if (value) {
          setIsFilterExpanded(true);
          const lowercaseValue = value.toLowerCase();
          const filteredItems = facet.valueList.filter((values) => {
            return values.value.toLowerCase().indexOf(lowercaseValue) >= 0;
          });
          if (filteredItems.length === 0) {
            setShowNotFound(true);
          } else {
            setShowNotFound(false);
          }
          expandList(filteredItems, facet.attrIdentifier, filteredItems.length === 0, value);
        } else {
          expandList(facet.valueList, facet.attrIdentifier, false, value);
        }
      }
    }
  };

  useEffect(() => {
    if (isFilterExpanded) {
      expandList(facet.valueList, facet.attrIdentifier);
      return;
    }
    const createFacetList = createFacetValues(
      facet.valueList,
      facet.attrIdentifier,
      selectedData,
      true,
    );
    if (facet) {
      setValueList(createFacetList);
    }
  }, [selectedData]);

  const toggleFacet = (event, identifier) => {
    // Handle toggling open/close of filter facet
    toggleOpenCloseFacet(identifier);
    setFacetExpanded(!facetExpanded);
  };

  useEffect(() => {
    setFacetExpanded(openFacet);
  }, [openFacet]);

  return (
    <CreateFacetContainer
      facet={facet}
      selectedData={selectedData}
      openFacet={openFacet}
      storeName={storeName}
      facetExpanded={facetExpanded}
      valueList={valueList}
      toggleFacet={toggleFacet}
      showNotFound={showNotFound}
      type="size">
      {' '}
      <FacetSearchBar
        inputValueChange={(item, evt) => {
          setValue(searchData(evt, item));
        }}
        name="Sizes"
      />
    </CreateFacetContainer>
  );
};

export default SizeSelectFacet;

SizeSelectFacet.propTypes = {
  /** facet object */
  facet: PropTypes.object.isRequired,
  /** selected data array for particular facet */
  selectedData: PropTypes.array,
  /** facet open or close flag */
  openFacet: PropTypes.bool.isRequired,
  /** function to apply selected filter */
  onFilterData: PropTypes.func.isRequired,
};
