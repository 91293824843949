import React, { Suspense, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selectedStoreSelector } from '../../../reduxStore/selectors';
import { onSelectStore } from '../../../reduxStore/thunks';
import { StoreInfo } from '../../../types';
import { changePreferredStoreEvent } from '../../../utility/analytics-events';
import { getCookie, setAddress, setStoreCookie } from '../../../utility/cookie-utils';
import { decodeAndReplaceHTML } from '../../../utility/utils';
import Checkbox from '../../common/FacetCheckBox/FacetCheckBox';
import BopisIcon from '../../common/SVGIcons/BopisIcon';
import PLBopisIcon from '../../common/SVGIcons/PublicLands/PLBopicIcon';
import IsaIcon from '../../common/SVGIcons/IsaIcon';
import ShipIcon from '../../common/SVGIcons/ShipIcon';
import StoreDisplay from '../../GridRowOne/StorePickup/StoreDisplay/StoreDisplay';
// import StorePickupModal from '../../GridRowOne/StorePickup/StorePickupModal/StorePickupModal';
import { updateFilter } from '../../../utility/history';
import VehicleCarIcon from '../../common/SVGIcons/VehicleCarIcon';

const storeAriaLabel = {
  SHIP: 'Ship To Me',
  BOPIS: 'In Store Pickup',
  ISA: 'Buy at the Store',
};

const StoreSelect = ({
  options,
  selectedData,
  onFilterData,
  breakpoint,
  storeName,
  onSelection,
}) => {
  const dispatch = useDispatch();
  const selectedStore = useSelector(selectedStoreSelector);
  const [selectedStoreName, setSelectedStoreName] = useState('');
  const LabelRef = {
    SHIP: (
      <div className="store-select-option-wrapper">
        <div className="store-select-option">
          <span className="store-select-icon-wrapper">
            <ShipIcon />
          </span>{' '}
          Shipping
        </div>
        <div>Find items in stock to ship</div>
      </div>
    ),
    BOPIS: (
      <div className="store-select-option-wrapper">
        <div className="store-select-option">
          <span className="store-select-icon-wrapper">
            <VehicleCarIcon />
          </span>{' '}
          Picking Up
        </div>
        <div>One-hour curbside or in-store</div>
      </div>
    ),
    ISA: (
      <div className="store-select-option-wrapper">
        <div className="store-select-option">
          <span className="store-select-icon-wrapper">
            <BopisIcon />
          </span>{' '}
          Shopping In-Store
        </div>
        <div>Find items in stock at your store</div>
      </div>
    ),
  };

  useEffect(() => {
    const storeCookieVal = getCookie('setStoreCookie');
    const selectedStoreValue = decodeAndReplaceHTML(storeCookieVal).split('_')[1];

    if (selectedStoreName !== selectedStoreValue) {
      if (storeCookieVal) {
        setSelectedStoreName(selectedStoreValue);
      } else {
        setSelectedStoreName('');
      }
    }
  }, [selectedStore]);

  const handleToggleStorePickupModal = (event: React.MouseEvent) => {
    if (
      typeof window !== 'undefined' &&
      window.headerFunctions &&
      window.headerFunctions.storeSelection
    ) {
      window.headerFunctions.storeSelection({ callback: (store) => onStoreSelection(store) });
    }
  };

  const onStoreSelection = (store: StoreInfo) => {
    // Update cookie information first
    // set the cookie setStoreCookie
    // 15241_SOUTH%2520HILLS_638 - postalcode_name_clientkey
    setStoreCookie(store.zip, store.name, store.location);
    setAddress(store.zip);

    // Fire ChangePreferredStore Event
    changePreferredStoreEvent(store.location);

    // Set store selection info. The fetching of data will be handled by componentWillReceiveProps
    const selectedStoreInfo = store.location;
    dispatch(onSelectStore(selectedStoreInfo));
  };

  const selectedStoreNameLabel = selectedStoreName || 'Select a nearby store';

  const onFilterChange = (val) => {
    if (onSelection) {
      onSelection();
      return;
    }
    const identifier = options.attrIdentifier;
    const values = [];
    if (selectedData && selectedData.indexOf(val) > -1) {
      values.push(`-${val}`);
    } else {
      values.push(val);
    }
    onFilterData(identifier, values, 'multi');
    updateFilter(identifier, values, 'multi');
  };

  const createOnFilterChangeCallback = (filterValue, doNothing) => {
    return (event) => {
      event.preventDefault();
      if (doNothing) {
        return;
      }
      onFilterChange(filterValue);
    };
  };

  const createValues = (valueList, identifier, selectedData) => {
    return valueList.map((option, i) => {
      const isChecked = selectedData && selectedData.indexOf(option.value) > -1;
      const disabled = !isChecked && option.count !== undefined && option.count === 0;
      if (!selectedStoreName && option.value !== 'SHIP') {
        return;
      }

      return (
        <Checkbox
          key={`${identifier}_${i}`}
          checkboxId={`checkbox_${identifier}_${i}`}
          onCheckboxToggle={createOnFilterChangeCallback(option.value, disabled)}
          disabled={disabled}
          labelledby={`store_select_facet_value_${option.value.replace(/[^A-Z0-9]/gi, '_')}`}
          facetName={LabelRef[option.value]}
          facetCount={option.count}
          breakpoint={breakpoint}
          selectedData={selectedData}
          facetValue={option}
          isCheckedValue={isChecked}
          ariaLabel={storeAriaLabel[option.value]}
        />
      );
    });
  };

  const [valueList, setValueList] = useState(
    createValues(options.valueList, options.attrIdentifier, selectedData),
  );

  useEffect(() => {
    const createList = createValues(options.valueList, options.attrIdentifier, selectedData);
    if (options) {
      setValueList(createList);
    }
  }, [selectedData, selectedStoreNameLabel]);

  return (
    <div className="store-selection">
      <StoreDisplay
        selectedStoreName={selectedStoreNameLabel}
        toggleMyStoreModal={handleToggleStorePickupModal}
      />
      <div
        key={`div_shipping_value_list_${options.attrIdentifier}`}
        className={'shipping_value_list'}
        id={`shipping_value_list_${options.attrIdentifier}`}>
        {valueList}
      </div>
    </div>
  );
};

export default StoreSelect;
