import React from 'react';
import PropTypes from 'prop-types';

const PLChevronIcon = ({ classes, pathClasses }) => {
  const additionalClasses = classes.join(' ').trim();
  const joinedClasses = `${additionalClasses}`;
  const additionalPathClasses = pathClasses.join(' ').trim();
  const joinedPathClasses = `${additionalPathClasses}`;

  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 12 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={joinedClasses}>
      <g clipPath="url(#clip0)">
        <path
          d="M5.91481 10.75L6.13387 10.75C6.13387 10.75 8.10548 5.91452 12 5.91452L12 1.25C8.66531 1.25 6.03651 6.03663 6.03651 6.03663L5.96349 6.03663C5.96349 6.03663 3.33469 1.25 9.53674e-07 1.25L7.49781e-07 5.91452C3.94321 5.91452 5.91481 10.75 5.91481 10.75Z"
          fill="#002A19"
          className={joinedPathClasses}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(12) rotate(90)"
            className={joinedPathClasses}
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default PLChevronIcon;

PLChevronIcon.propTypes = {
  classes: PropTypes.arrayOf(PropTypes.string), // Additional class names to append onto the class list of the SVG
  pathClasses: PropTypes.arrayOf(PropTypes.string), // Additional path class names to append onto the class list of the SVG Path
};

PLChevronIcon.defaultProps = {
  classes: [],
  pathClasses: [],
};
